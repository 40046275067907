import { inject, Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { BidpoolDocumentApi } from "../api/bidpooldocument.api";
import { TAPIListResult } from "../contract/api.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { BidpoolDocument } from "../model/bidpool/bidool-document.model";
import { apiCallWrapper, inflateModel } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
export class BidpoolDocumentService {
  private notifications = inject(ToastrService);
  private documentApi = inject(BidpoolDocumentApi);

  /**
   * @description This method is used to grab all bidPool documents.
   * @param query
   * @returns
   */
  public async fetchDocumnets(
    query: Partial<IQueryFilter>,
  ): Promise<TAPIListResult<BidpoolDocument>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.documentApi.list(new IQueryFilter(query)), {
        notificationsService: this.notifications,
        action: "Fetching Bidpool Documents",
      }),
    );

    return inflateModel(BidpoolDocument, data);
  }

  /**
   * @description This method is used to Create bidPool document.
   * @param id
   * @param changes
   * @returns
   */
  public async create(body: Partial<BidpoolDocument>): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.create(body), {
        notificationsService: this.notifications,
        action: "Create bidpool document",
      }),
    );

    return;
  }

  /**
   * @description This method is used to Archive any particular doc.
   * @param id
   * @returns
   */
  public async delete(id: number): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.delete(id), {
        notificationsService: this.notifications,
        action: "Delete bidpool Documnet",
      }),
    );

    return;
  }
}
