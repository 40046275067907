import { Type } from "class-transformer";
import { User } from "../user/user.model";

export const chairContactDateFormat = {
  parse: {
    dateInput: "YYYYMMDD",
    yearMonthDayInput: "YYYYMMDD",
  },
  display: {
    dateInput: "MMMM YYYY DD",
    dayMonthYearLabel: "DD MMMM YYYY",
    monthYearLabel: "MMMM YYYY DD",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY DD",
  },
};

export class RCChairContact {
  id: number;
  rcApplicationId: number;
  dateEndorsement?: string;
  phone?: string;
  userId?: number;
  @Type(() => User)
  user: User;

  public patchValue(value: Partial<RCChairContact>) {
    const { dateEndorsement, userId, user } = value;
    if (dateEndorsement !== undefined) this.dateEndorsement = dateEndorsement;
  }
}
