/**
 * @description Converts the input date string to a local date format.
 *
 * @param {string | null | undefined} dateString - The date string to convert.
 * @returns {Date | string | null | undefined} - The converted local date string, or null/undefined if input is falsy.
 */

export function toLocalDate(dateString: Date): string;
export function toLocalDate<T = null | undefined>(dateString: T): T;
export function toLocalDate(dateString: string): string;
export function toLocalDate(dateString: string | Date): string {
  if ( !dateString ) return dateString;

  const date = new Date(dateString instanceof Date ? dateString.getTime() : dateString);
  date.setHours(12, 0, 0, 0);
  return date.toISOString().split('T')[0];
}
