import { Type } from "class-transformer";
import { RefKpi } from "../ref/ref-kpi.model";

export class RCKpi {
  id: number;
  rcApplicationId: number;
  refKpiId: number;
  @Type(() => RefKpi)
  refkpis: RefKpi;
  weight: number;

  public patchValue(value: Partial<RCKpi>) {
    const { weight } = value;
    if (weight !== undefined) this.weight = weight;
  }

  get label() {
    return this.refkpis.name;
  }
}
