import { Injectable, inject } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { CalenderApi } from "../api/calender.api";
import { TAPIListResult } from "../contract/api.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { Calender, EventCreateOrUpdate } from "../model/calender/calender.model";
import { apiCallWrapper, inflateModel } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
/**
 * @description This service is used to manage user API.
 */
export class CalenderService {
  private notifications = inject(ToastrService);
  private calenderApi = inject(CalenderApi);

  /**
   * @description This method is used to grab a list of all users.
   * @param query
   * @returns
   */
  public async listEvent(query: Partial<IQueryFilter>): Promise<TAPIListResult<Calender>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.calenderApi.list(new IQueryFilter(query)), {
        notificationsService: this.notifications,
        action: "Fetch events",
      }),
    );
    return inflateModel(Calender, data);
  }

  public async createEvent(data: EventCreateOrUpdate): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.calenderApi.create(data), {
        notificationsService: this.notifications,
        action: "Create event",
        showSuccessNotification: true,
        successTitle: "Created event",
      }),
    );

    return;
  }
}
