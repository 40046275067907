<div class="logpg">
  <app-public-header></app-public-header>

  <div *ngIf="!isRequestSubmitted; then form; else requestSubmitted"></div>

  <ng-template #form>
    <div class="forgetwrap">
      <div class="forinr">
        <h3 class="text-center">Forgot your password?</h3>
        <p class="text-center">No problem. Submit your email below to reset your password.</p>
        <form #form="ngForm" [formGroup]="forgotPasswordForm" novalidate (submit)="onSubmit()">
          <div class="mb-3">
            <input
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  (form.submitted || formControls.email.touched) && formControls.email.invalid,
                'is-valid': formControls.email.valid,
              }"
              id="email"
              type="email"
              matTooltip="Enter your email"
              placeholder="Enter your email"
              formControlName="email"
              aria-label="Enter email"
            />
            <div
              *ngIf="
                formControls.email.invalid &&
                ((formControls.email.dirty && formControls.email.touched) || form.submitted)
              "
              class="invalid-feedback"
            >
              Invalid email address
            </div>
          </div>
          <div class="mb-3">
            <button type="submit" class="btn-primary-new w-100">Submit</button>
          </div>
          <!-- <span>Go back to <a [routerLink]="'/login'">Login here</a></span> -->
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #requestSubmitted>
    <div class="welcome-wrap">
      <p>
        If the provided email address is linked to an account, you will receive an email with
        instructions to reset your password.
      </p>
    </div>
  </ng-template>
  <div class="text-center gbck">
    <span>Go back to <a [routerLink]="'/login'" class="text-grn">Login here</a></span>
  </div>
  <app-public-footer></app-public-footer>
</div>
