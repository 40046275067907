import { inject, Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { RoleApi } from "../api/role.api";
import { TAPIListResult } from "../contract/api.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { UserRole } from "../model/user/user-role.model";
import { apiCallWrapper, inflateModel } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
export class RoleService {
  private notifications = inject(ToastrService);
  private userRoleApi = inject(RoleApi);

  /**
   * @description This method is used to grab all  the user roles.
   * @param query
   * @returns
   */
  public async fetchUserRole(query: Partial<IQueryFilter>): Promise<TAPIListResult<UserRole>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.userRoleApi.list(new IQueryFilter(query)), {
        notificationsService: this.notifications,
        action: "Fetching User Roles",
      }),
    );

    return inflateModel(UserRole, data);
  }
}
