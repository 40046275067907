<app-authenticated-layout>
  <app-create-bidpool-button />
  <app-table
    tableTitle="Bidpool Applications"
    [tableColumns]="bidpoolApplicationsTableColumns"
    [fetchDataMethod]="bidpoolApplicationsFetchDataMethod"
    [rowClickMethod]="handleBidpoolRowClick"
    [usePagination]="true"
    [paginationSize]="5"
  />

  <app-create-rc-button />
  <app-table
    tableTitle="Regional Coordinator Applications"
    [tableColumns]="rcApplicationsTableColumns"
    [fetchDataMethod]="rcApplicationsFetchDataMethod"
    [rowClickMethod]="handleRCRowClick"
    [usePagination]="true"
    [paginationSize]="5"
  />
</app-authenticated-layout>
