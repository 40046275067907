import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../service/auth.service";

export const tokenGuard: CanActivateFn = (route, state) => {
  const toastService = inject(ToastrService);
  const authService = inject(AuthService);
  const router = inject(Router);

  const { tokenScope: scope, fallbackRoute } = route.data;
  const { token } = route.queryParams;

  const fallbackRoutes = router.createUrlTree([fallbackRoute]);

  if (!scope) {
    return fallbackRoutes;
  }

  if (!token) {
    toastService.error("Reset password link is invalid");
    return fallbackRoutes;
  }

  return new Promise((resolve) => {
    authService.validateToken(token, scope).subscribe({
      next: () => resolve(true),
      error: () => resolve(fallbackRoutes),
    });
  });
};
