import { RolesEnum } from "../contract/user.contract";

export type NavItem = {
  route: string;
  iconName: string;
  label: string;
  routerLinkActiveOptions: { exact: boolean };
  roles?: RolesEnum[];
};

export const navItems: NavItem[] = [
  {
    route: "/",
    iconName: "home",
    label: "Home",
    routerLinkActiveOptions: { exact: true },
  },
  {
    route: "/bidpool",
    iconName: "contract_edit",
    label: "Bidpool",
    routerLinkActiveOptions: { exact: false },
  },
  {
    route: "/regional-coordinator",
    iconName: "groups",
    label: "Regional Coordinator",
    routerLinkActiveOptions: { exact: false },
  },
  {
    route: "/documents",
    iconName: "folder_copy",
    label: "Documents Library",
    routerLinkActiveOptions: { exact: false },
  },
  {
    route: "/calendar",
    iconName: "calendar_month",
    label: "Calendar",
    routerLinkActiveOptions: { exact: false },
  },
  {
    route: "/profile",
    iconName: "account_circle",
    label: "Profile",
    routerLinkActiveOptions: { exact: false },
  },
  {
    route: "/user",
    iconName: "admin_panel_settings",
    label: "Users",
    routerLinkActiveOptions: { exact: false },
    roles: [RolesEnum.Admin],
  },
];
