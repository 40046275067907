import { Type } from "class-transformer";
import { RolesEnum } from "../../contract/user.contract";
import { BaseModel } from "../base.model";
import { UserPermission } from "./user-permission.model";
import { logger } from "src/app/util/logger.util";

const className = 'UserRoleModel';

export class UserRole extends BaseModel {
  id: number;
  name: string;
  description: string;
  @Type(() => UserPermission)
  permissions: UserPermission[];

  public hasPermission(permissionId: number): boolean {
    const signature = className + `.hasPermission: PermissionId[${permissionId}] `;
    if (!this.permissions) {
      logger.error(signature + `Malformed Permissions. Expected array got ${typeof this.permissions} instead`);
      return false;
    }

    return this.permissions.some((permission) => {
      return permission.id === permissionId;
    });
  }

  public isAdmin(): boolean {
    return this.id === RolesEnum.Admin;
  }
}
