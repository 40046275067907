import { Type } from "class-transformer";
import { RefWorkPlan } from "../ref/ref-workplan.model";

export class RCWorkplan {
  id: number;
  rcApplicationId: number;
  refAnnualWorkPlanId: number;
  @Type(() => RefWorkPlan)
  refAnnualWorkPlans: RefWorkPlan;
  value: boolean;

  get label() {
    return this.refAnnualWorkPlans.name;
  }

  get labelId() {
    return this.refAnnualWorkPlans.id;
  }
}
