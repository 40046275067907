import { PlatformLocation } from "@angular/common";
import { Injectable, inject } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private modalService = inject(NgbModal);
  private location = inject(PlatformLocation);

  constructor() {
    // closes modal when back button is clicked
    this.location.onPopState(() => {
      this.modalService.dismissAll();
    });
  }
}
