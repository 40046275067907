export enum RolesEnum {
  Admin = 1,
  RegionalContributor = 2,
  Contributor = 3,
  Reviewer1 = 4,
  Reviewer2 = 5,
  ApproverWG = 6,
  ApproverPSC = 7,
}

export enum PermissionsEnum {
  ScreenAdmin = 1,
  CreateApplication = 2,
  EditApplication = 3,
  SubmitApplication = 4,
  PutApplicationBackToDraft = 5,
  ApproveApplication = 6,
}

export interface UserPhoneUpdate {
  phone: string;
  userId: string;
  code?: number;
}
