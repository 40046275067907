import { inject, Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { RCDocumentApi } from "../api/rcdocument.api";
import { TAPIListResult } from "../contract/api.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { RCDocument } from "../model/regional-coordinator/rc-document.model";
import { apiCallWrapper, inflateModel } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
export class RCDocumentService {
  private notifications = inject(ToastrService);
  private documentApi = inject(RCDocumentApi);

  /**
   * @description This method is used to grab all regional coordinator documents.
   * @param query
   * @returns
   */
  public async fetchDocumnets(query: Partial<IQueryFilter>): Promise<TAPIListResult<RCDocument>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.documentApi.list(new IQueryFilter(query)), {
        notificationsService: this.notifications,
        action: "Fetching regional coordinator Documents",
      }),
    );

    return inflateModel(RCDocument, data);
  }

  /**
   * @description This method is used to Create regional coordinator document.
   * @param id
   * @param changes
   * @returns
   */
  public async create(body: Partial<RCDocument>): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.create(body), {
        notificationsService: this.notifications,
        action: "Create regional coordinator document",
      }),
    );

    return;
  }

  /**
   * @description This method is used to Archive any particular doc.
   * @param id
   * @returns
   */
  public async delete(id: number): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.delete(id), {
        notificationsService: this.notifications,
        action: "Delete regional coordinator Documnet",
      }),
    );

    return;
  }
}
