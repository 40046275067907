import { Type } from "class-transformer";
import { RefExpectedBenefit } from "../ref/ref-expected-benefit.model";

export class BidpoolExpectedBenefit {
  id: number;
  bidpoolApplicationId: number;
  refExpectedBenefitId: number;
  @Type(() => RefExpectedBenefit)
  refExpectedBenefit: RefExpectedBenefit;
  value: string;

  get label() {
    return this.refExpectedBenefit.name;
  }

  get description() {
    return this.refExpectedBenefit.description;
  }

  get isHighlighted() {
    return this.refExpectedBenefit.isPriorityField;
  }
}
