import { Injectable, inject } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom, Observable } from "rxjs";
import { API_ASSOCIATIONS } from "../api/api.constants";
import { RegionalCoordinatorApi } from "../api/regional-coordinator.api";
import { TAPIListResult } from "../contract/api.contract";
import {
  IUpdateRCApplicationWorkflowStepInput,
  RCApplicationUpdate,
} from "../contract/regional-coordinator.contract";
import { RCWorkflowStepsEnum } from "../contract/workflowStep.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { RCApplication } from "../model/regional-coordinator/rc-appication.model";
import { RCFeedback } from "../model/regional-coordinator/rc-feedback.model";
import { apiCallWrapper, apiSubscriptionWrapper, inflateModel } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
/**
 * @description This service is used to manage RC applications
 */
export class RegionalCoordinatorService {
  private toastrService = inject(ToastrService);
  private regionalCoordinatorApi = inject(RegionalCoordinatorApi);

  private kpiDetails: { label: string; items: string }[] = [
    {
      label: "Administration",
      items: `<ul>
                <li>Arrange/Manage four face-to-face meetings per annum for each group</li>
                <li>Prepare Meeting Agendas and circulate a minimum of 7 days prior to the meeting. Circulate request for agenda items at least a week prior to distributing Agenda papers.</li>
                <li>Prepare Meeting minutes and circulate accurate draft minutes within 7 days of the meeting.</li>
              </ul>`,
    },
    {
      label: "Support",
      items: `<ul>
                <li>Coordinate and execute identified action items within the specified time frame</li>
                <li>Communicate with and include regulatory authorities as appropriate e.g. Water Regulator and Environmental Regulator.</li>
                <li>Maintain regional skilling opportunities as a regular discussion topic at regional meetings.</li>
                <li>Identify, prioritise and recommend potential funding to support planned and future strategic actions</li>
                <li>Promotion/Communication
                  <ul>
                    <li>Identify, prioritise and recommend potential promotional opportunities;</li>
                    <li>Prepare and/or convene promotional opportunities with regional group oversight</li>
                  </ul>
                </li>
              </ul>`,
    },
    {
      label: "Project Management & Delivery",
      items: `<ul>
                <li>Develop and table detailed business cases within the agreed timeframe for each potential project requested by the group. Business case to identify:
                  <ul>
                    <li>Project need</li>
                    <li>Project benefits (to customer service & financial savings)</li>
                    <li>Estimated costs</li>
                    <li>Proposed split between members of costs</li>
                    <li>Proposed milestones & basic project plan where required</li>
                  </ul>
                </li>
                <li>When directed, apply for funding within the agreed timeframe to support planned and future strategic actions of the alliance. Funding applications to be developed iteratively with assistance of the committee.</li>
                <li>Contract Administration - projects funded by the group are managed in a timely fashion and within proposed budgets.</li>
              </ul>`,
    },
    {
      label: "Reporting",
      items: `<ul>
                      <li>Produce Coordinator’s report for quarterly meetings</li>
                      <li>Report on outcomes of each joint project detailing full costs and the estimated financial
                        savings. The report includes a technical summary and information for broader communication
                        focussing on outcomes and benefits. Report distributed for review within two months of practical
                        completion.</li>
                      <li>Summarise the financial situation concurrently with the Regional Coordinators Report. </li>
                      <li>Update regional profile as needed and submit at least quarterly.</li>
                      <li>Prepare and submit by May each year to LGAQ an Annual Report including acquittal that
                        summarises:</li><br>
                      <ul>
                        <li>activities from throughout the year including costs and actual and projected savings.</li>
                        <li>expected benefits and potential savings during the previous year</li>
                      </ul>
                    </ul>`,
    },
    {
      label: "Strategic support, facilitation and direction",
      items: `  <ul>
                      <li>Obtain group’s approval and submit Annual Work Plan to LGAQ by May each year.</li>
                      <li>Facilitate & support the groups strategic direction</li>
                      <li>Develop and Implement a Strategic Plan for the region</li>
                    </ul>`,
    },
  ];

  public async createRCApplication(): Promise<RCApplication> {
    return await lastValueFrom(
      apiCallWrapper(this.regionalCoordinatorApi.create(), {
        notificationsService: this.toastrService,
        action: "Create regional coordinator application",
        showSuccessNotification: true,
        successTitle: "Created regional coordinator application",
      }),
    );
  }

  public subscribeRCApplication(id: number): Observable<RCApplication> {
    return apiSubscriptionWrapper(this.regionalCoordinatorApi.socket(id), {
      notificationsService: this.toastrService,
      failTitle: "Failed to fetch regional coordinator application",
    });
  }

  public async updateRCApplication(id: number, changes: RCApplicationUpdate): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.regionalCoordinatorApi.update(id, changes), {
        notificationsService: this.toastrService,
        action: "Update regional coordinator application",
      }),
    );

    return;
  }

  public async updateWorkflow(
    id: number,
    options: IUpdateRCApplicationWorkflowStepInput,
  ): Promise<void> {
    const data = await lastValueFrom(
      apiCallWrapper(this.regionalCoordinatorApi.updateWorkflow(id, options), {
        notificationsService: this.toastrService,
        action: "Update Regional coordinator application step",
      }),
    );

    switch (data.newWorkflowStepId) {
      case RCWorkflowStepsEnum.R1:
        this.toastrService.success("Application sent to Chair");
        break;
      case RCWorkflowStepsEnum.R2:
        this.toastrService.success("Application sent to Banker");
        break;
      case RCWorkflowStepsEnum.AWG:
        this.toastrService.success("Application sent to Working Group");
        break;
      case RCWorkflowStepsEnum.Approved:
        this.toastrService.success("Application approved");
        break;
      case RCWorkflowStepsEnum.Completed:
        this.toastrService.success("Application completed");
        break;
    }
  }

  public async fetchRCApplication(id: number): Promise<RCApplication> {
    const data = await lastValueFrom(
      apiCallWrapper(this.regionalCoordinatorApi.get(id), {
        notificationsService: this.toastrService,
        action: "Fetch regional coordinator application",
      }),
    );

    return plainToInstance(RCApplication, data);
  }

  public async fetchRCApplications(
    query: Partial<IQueryFilter>,
  ): Promise<TAPIListResult<RCApplication>> {
    const data = await lastValueFrom(
      apiCallWrapper(
        this.regionalCoordinatorApi.list(
          new IQueryFilter({ ...API_ASSOCIATIONS.rcApplications, ...query }),
        ),
        {
          notificationsService: this.toastrService,
          action: "Fetch regional coorrdinator applications",
        },
      ),
    );

    return inflateModel(RCApplication, data);
  }

  getKpiDetails() {
    return this.kpiDetails;
  }

  public async updateRCFeedback(changes: {
    feedback: string | null | undefined;
    rcApplicationId: number;
  }): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.regionalCoordinatorApi.updateFeedback(changes), {
        notificationsService: this.toastrService,
        action: "Update regional coorrdinator comment",
      }),
    );

    return;
  }

  public async fetchRcFeedbacks(query: IQueryFilter): Promise<TAPIListResult<RCFeedback>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.regionalCoordinatorApi.listRCFeedback(query), {
        notificationsService: this.toastrService,
        action: "Fetch regional coorrdinator  feedback",
      }),
    );

    return inflateModel(RCFeedback, data);
  }
}
