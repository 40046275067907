<app-public-header></app-public-header>

<div class="welcome-wrap">
  <h3>Welcome!</h3>
  <p class="mb-3">Your phone number.</p>
  <form [formGroup]="form" novalidate>
    <div class="auth-code-wrap">
      <input
        class="form-control"
        [ngClass]="{
          'is-invalid': formControls.phone.touched && formControls.phone.invalid,
          'is-valid': formControls.phone.valid,
        }"
        id="phone"
        type="string"
        matTooltip="Enter your phone no."
        placeholder="Enter your phone no."
        formControlName="phone"
        aria-label="Enter phone"
      />
      <div
        *ngIf="
          formControls.phone.invalid && (formControls.phone.dirty || formControls.phone.touched)
        "
        class="invalid-feedback"
      >
        Phone number is required
      </div>
      <button type="button" class="max-w-full btn-primary-new w-100 mt-3" (click)="onSubmit()">
        Get Code
      </button>
    </div>
  </form>
</div>

<app-public-footer></app-public-footer>
