import { CommonModule, DatePipe, ViewportScroller } from "@angular/common";
import {
  afterRender,
  Component,
  inject,
  Input,
  numberAttribute,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { plainToInstance } from "class-transformer";
import moment from "moment";
import { ToastrService } from "ngx-toastr";
import {
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  startWith,
  Subscription,
  switchMap,
} from "rxjs";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { DatePickerComponent } from "src/app/component/date-picker/date-picker.component";
import { TableColumn, TableComponent } from "src/app/component/table/table.component";
import { TAPIListResult } from "src/app/contract/api.contract";
import { RCApplicationUpdate } from "src/app/contract/regional-coordinator.contract";
import { RolesEnum } from "src/app/contract/user.contract";
import { RCWorkflowStepsEnum } from "src/app/contract/workflowStep.contract";
import { RCFormSpec } from "src/app/interface/rc-form.interrface";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { RCApplication } from "src/app/model/regional-coordinator/rc-appication.model";
import {
  chairContactDateFormat,
  RCChairContact,
} from "src/app/model/regional-coordinator/rc-chair-contact.model";
import { RCComingYear } from "src/app/model/regional-coordinator/rc-coming-year.model";
import { RCDocument } from "src/app/model/regional-coordinator/rc-document.model";
import { RCFeedback } from "src/app/model/regional-coordinator/rc-feedback.model";
import { RCKpi } from "src/app/model/regional-coordinator/rc-kpi.model";
import { RCPreviousYear } from "src/app/model/regional-coordinator/rc-previous-year.model";
import { RegionalCoordinatorUser } from "src/app/model/regional-coordinator/rc-user.model";
import { User } from "src/app/model/user/user.model";
import { FileUploadService } from "src/app/service/fileUpload.service";
import { RCDocumentService } from "src/app/service/rcDocument.service";
import { RegionalCoordinatorService } from "src/app/service/regional-coordinator.service";
import { AccessName, UserService } from "src/app/service/user.service";
import { logger } from "src/app/util/logger.util";
import { RegionalCoordinatorFeedbackComponent } from "../regional-coordinator-feedback/regional-coordinator-feedback.component";

@Component({
  selector: "app-rc-single",
  standalone: true,
  imports: [
    AuthenticatedLayoutComponent,
    DatePickerComponent,
    TableComponent,

    MatCheckboxModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatDialogModule,
    MatPaginatorModule,

    DatePipe,
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    RegionalCoordinatorFeedbackComponent,
  ],
  providers: [DatePipe],
  templateUrl: "./rc-single.component.html",
  styleUrl: "./rc-single.component.css",
})
export class RcSingleComponent implements OnInit {
  private debounceInMs = 3000;
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private formBuilder = inject(FormBuilder);
  private userService = inject(UserService);
  private rcServive = inject(RegionalCoordinatorService);
  private modalService = inject(NgbModal);
  private toastrService = inject(ToastrService);
  private fileUploadService = inject(FileUploadService);
  private rcDocumentService = inject(RCDocumentService);
  private dialog = inject(MatDialog);
  private viewportScroller = inject(ViewportScroller);

  protected mode: "view" | "edit";
  regionalCoordinatorApplication: RCApplication | null = null;
  protected saving = false;
  subscriptions: Subscription[] = [];
  protected canEdit = false;
  protected canChangeWorkflowStep = false;
  @Input({ transform: numberAttribute }) rcId = 0;
  protected rolesEnum = RolesEnum;
  protected participantOptions: Observable<User[]> = new Observable();
  protected participantAutocompleteControl = this.formBuilder.control("");
  protected chairContactOptions: Observable<User[]> = new Observable();
  protected chairContactAutocompleteControl = this.formBuilder.control("");
  protected formErrors: string[] = [];
  protected s3Prefix = "https://s3-ap-southeast-2.amazonaws.com/files-qwrap-dev/";
  protected cfDistribution = "https://files-qwrap-dev/";
  protected rcDocuments: TAPIListResult<RCDocument> = { rows: [], count: 0 };
  kpiDetails: { label: string; items: string }[] = [];
  protected rcWorkflowStepEnum = RCWorkflowStepsEnum;
  private viewInitialised = false;
  protected datePipe = inject(DatePipe);
  protected feedback = this.formBuilder.control("");
  protected contactAutocompleteControl = this.formBuilder.control("");
  protected contactOptions: Observable<User[]> = new Observable();
  @ViewChild(RegionalCoordinatorFeedbackComponent)
  feedbackComponent!: RegionalCoordinatorFeedbackComponent;
  documentPaginationIndex: number = 0;

  protected regionalCoordinatorForm = this.formBuilder.group({
    introduction: this.formBuilder.control(""),
    strategicObject: this.formBuilder.control(""),
    regionalMaturity: this.formBuilder.control(""),
    roleOfCoordinator: this.formBuilder.control(""),
    amountRequested: this.formBuilder.control(0),
    chairContacts: this.formBuilder.array([]),
    coordinatorPosition: this.formBuilder.group({}),
    milestones: this.formBuilder.array([]),
    rcKpis: this.formBuilder.array([]),
    rcProject: this.formBuilder.group({
      title: [""],
      objectives: [""],
      strategicAlignment: [""],
      councilsInvolved: [""],
      bidpoolCost: [0],
      complete: [0],
    }),
    rcAnnualWorkPlan: this.formBuilder.array([]),
  });

  private formSpec: RCFormSpec = {
    introduction: {
      update: async (value: string) => {
        await this.updateRCApplication(this.rcId, {
          introduction: value,
        });
      },
    },
    strategicObject: {
      update: async (value: string) => {
        await this.updateRCApplication(this.rcId, {
          strategicObject: value,
        });
      },
    },
    roleOfCoordinator: {
      update: async (value: string) => {
        await this.updateRCApplication(this.rcId, {
          roleOfCoordinator: value,
        });
      },
    },
    regionalMaturity: {
      update: async (value: string) => {
        await this.updateRCApplication(this.rcId, {
          regionalMaturity: value,
        });
      },
    },
    amountRequested: {
      formatValueChange: this.formatToNumber,
      patchFormValue: this.formatToNumber,
      update: async (value: number) => {
        await this.updateRCApplication(this.rcId, {
          amountRequested: value,
        });
      },
    },
    chairContacts: {
      dateEndorsement: {
        update: async (value: moment.Moment, id: number) => {
          const newDate = value.format(chairContactDateFormat.parse.dateInput);
          await this.updateRCApplication(this.rcId, {
            chairContacts: [
              {
                id,
                rcApplicationId: this.rcId,
                dateEndorsement: newDate,
              },
            ],
          });
        },
      },
      phone: {
        formatValueChange: this.formatToPhoneNumber,
        patchFormValue: this.formatToPhoneNumber,
        update: async (value: string, id: number) => {
          await this.updateRCApplication(this.rcId, {
            chairContacts: [
              {
                id,
                rcApplicationId: this.rcId,
                phone: value,
              },
            ],
          });
        },
      },
    },
    coordinatorPosition: {
      name: {
        update: async (value: string, id: number) => {
          await this.updateRCApplication(this.rcId, {
            coordinatorPosition: {
              id,
              rcApplicationId: this.rcId,
              name: value,
            },
          });
        },
      },
      email: {
        update: async (value: string, id: number) => {
          await this.updateRCApplication(this.rcId, {
            coordinatorPosition: {
              id,
              rcApplicationId: this.rcId,
              email: value,
            },
          });
        },
      },
      phone: {
        formatValueChange: this.formatToPhoneNumber,
        patchFormValue: this.formatToPhoneNumber,
        update: async (value: string, id: number) => {
          await this.updateRCApplication(this.rcId, {
            coordinatorPosition: {
              id,
              rcApplicationId: this.rcId,
              phone: value,
            },
          });
        },
      },
      startDate: {
        update: async (value: string, id: number) => {
          await this.updateRCApplication(this.rcId, {
            coordinatorPosition: {
              id,
              rcApplicationId: this.rcId,
              startDate: value,
            },
          });
        },
      },
      positionDescription: {
        update: async (value: boolean, id: number) => {
          await this.updateRCApplication(this.rcId, {
            coordinatorPosition: {
              id,
              rcApplicationId: this.rcId,
              positionDescription: value,
            },
          });
        },
      },
    },
    rcKpis: {
      weight: {
        formatValueChange: this.formatToPercentageNumber,
        patchFormValue: this.formatToPercentageNumber,
        update: async (value: number, id: number) => {
          await this.updateRCApplication(this.rcId, {
            rcKpis: [
              {
                id,
                rcApplicationId: this.rcId,
                weight: value,
              },
            ],
          });
          this.highlightInvalidPaymentFields();
        },
      },
    },
    rcAnnualWorkPlan: {
      update: async (value: boolean, id: number) => {
        await this.updateRCApplication(this.rcId, {
          rcAnnualWorkPlan: [{ id, value: value }],
        });
      },
    },
  };

  public documentForm = this.formBuilder.group({
    description: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    documentFile: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    type: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    rcApplicationId: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
  });
  isUploading: boolean;
  isPrevious: boolean;

  constructor() {
    afterRender({ read: this.initStickyTopAnchorScrolling.bind(this) });
  }

  private initStickyTopAnchorScrolling() {
    if (!this.viewInitialised && document.getElementById("sticky-top")) {
      this.viewportScroller.setOffset([
        0,
        document.getElementById("sticky-top")!.getBoundingClientRect().height,
      ]);
      this.subscriptions.push(
        this.route.fragment.subscribe((fragment) => {
          if (fragment) {
            this.viewportScroller.scrollToAnchor(fragment);
          } else {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }),
      );
      this.viewInitialised = true;
    }
  }

  ngOnInit(): void {
    this.kpiDetails = this.rcServive.getKpiDetails();
    if (!isNaN(this.rcId)) {
      this.fetchRCApplication();
      this.fetchRCDocumnets();
    }
    this.initParticipantsAutocompleteOptions();
    this.initChairContactAutocompleteOptions();
  }

  /**
   * This method subscribes to this bidpool application changes, calls the initForm method on initialisation and patchForm on subsequent changes
   */
  private fetchRCApplication() {
    this.subscriptions.push(
      this.rcServive.subscribeRCApplication(this.rcId).subscribe({
        next: (value) => {
          if (!this.regionalCoordinatorApplication) {
            this.regionalCoordinatorApplication = plainToInstance(RCApplication, value);
            this.initAccess();
            this.initMode();
            this.initForm();
          } else {
            this.regionalCoordinatorApplication.updatedAt = value.updatedAt;
            this.regionalCoordinatorApplication.rcProjectPreviousYear = value.rcProjectPreviousYear;
            this.regionalCoordinatorApplication.rcProjectComingYear = value.rcProjectComingYear;

            this.patchForm(value);
          }
        },
        error: (error) => {
          logger.debug("Silently catch error", error);
        },
      }),
    );
  }

  getUniqueChairContacts(chairContacts: any[]): any[] {
    const uniqueIds = new Set();
    return chairContacts.filter((chairContact) => {
      if (uniqueIds.has(chairContact.user.id)) {
        return false;
      }
      uniqueIds.add(chairContact.user.id);
      return true;
    });
  }

  /**
   * Method called the first time the form is initialised.
   * Initialises the dynamic form controls, sets the initial values of the form.
   * Initialises subscriptions watching over form changes to trigger the updates API calls
   * By "dynamic", some form controls are dynamic eg: expected benefits, contributions, etc..
   * @param value contains all data from the BidpoolApplication, with all associations
   */
  private initForm() {
    const thisRCApplication = this.regionalCoordinatorApplication!;

    // Initialise dateEndorsement, title, brief, description form controls
    for (let i of [
      "introduction",
      "strategicObject",
      "roleOfCoordinator",
      "amountRequested",
      "regionalMaturity",
    ] as const) {
      const control = this.formBuilder.control("", [Validators.required]);
      this.regionalCoordinatorForm.setControl(i, control);
      this.subscriptions.push(
        control.valueChanges.pipe(debounceTime(this.debounceInMs)).subscribe({
          next: async (value) => {
            if (value !== null) {
              await this.formSpec[i].update(value);
            }
          },
        }),
      );
    }

    // Initialise chair contacts in form controls
    for (let i = 0; i < thisRCApplication.chairContacts.length; i++) {
      this.addChairContactFormControls(thisRCApplication.chairContacts[i].id);
    }

    if (thisRCApplication.coordinatorPosition) {
      this.addCoordinatorPositionFormControls(thisRCApplication.coordinatorPosition.id);
    }

    for (let i = 0; i < thisRCApplication.rcKpis.length; i++) {
      const group = this.formBuilder.group({});
      for (let j in this.formSpec.rcKpis) {
        const control = this.formBuilder.control("", [Validators.required]);
        group.addControl(j, control);
        this.subscriptions.push(
          control.valueChanges
            .pipe(
              map((value) => {
                const formattedValue = this.formSpec.rcKpis[j].formatValueChange
                  ? this.formSpec.rcKpis[j].formatValueChange(value)
                  : value;
                if (this.formSpec.rcKpis[j].patchFormValue) {
                  control.patchValue(this.formSpec.rcKpis[j].patchFormValue(formattedValue), {
                    emitEvent: false,
                  });
                }
                return formattedValue;
              }),
            )
            .pipe(debounceTime(this.debounceInMs))
            .subscribe({
              next: async (value) => {
                if (value !== null) {
                  await this.formSpec.rcKpis[j].update(value, thisRCApplication.rcKpis[i].id);
                }
              },
            }),
        );
      }
      this.rcKpis.push(group);
    }

    // Initialise strategic priorities and expected benefits in form controls
    for (let i of ["rcAnnualWorkPlan"] as const) {
      for (let j = 0; j < thisRCApplication[i].length; j++) {
        const control = this.formBuilder.control("", [Validators.required]);
        this.regionalCoordinatorForm.controls[i].push(control);
        this.subscriptions.push(
          control.valueChanges.pipe(debounceTime(this.debounceInMs)).subscribe({
            next: async (value) => {
              if (value !== null) {
                await this.formSpec[i].update(value, this.regionalCoordinatorApplication![i][j].id);
              }
            },
          }),
        );
      }
    }

    // Initialise form values
    const formValue = {
      introduction: thisRCApplication.introduction,
      strategicObject: thisRCApplication.strategicObject,
      roleOfCoordinator: thisRCApplication.roleOfCoordinator,
      regionalMaturity: thisRCApplication.regionalMaturity,
      amountRequested: thisRCApplication.amountRequested,
      rcAnnualWorkPlan: thisRCApplication.rcAnnualWorkPlan.map((e) => e.value),
      chairContacts: thisRCApplication.chairContacts.map((e) => ({
        user: e.user,
        dateEndorsement: e.dateEndorsement
          ? moment(e.dateEndorsement, chairContactDateFormat.parse.dateInput)
          : null,
        phone: e.phone,
      })),
      coordinatorPosition: thisRCApplication.coordinatorPosition,
      rcKpis: thisRCApplication.rcKpis.map((e) => ({
        weight: e.weight,
      })),
    };

    this.regionalCoordinatorForm.patchValue(formValue, { emitEvent: false });
  }

  private initMode() {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe({
        next: (params) => {
          const queryParamMode = params.get("mode");
          switch (queryParamMode) {
            case "view":
              this.mode = "view";
              break;
            case "edit":
              if (this.canEdit) {
                this.mode = "edit";
              } else {
                this.enterViewMode();
              }
              break;
            default:
              this.enterViewMode();
              break;
          }
        },
      }),
    );
  }

  protected enterViewMode() {
    this.router.navigate([], { relativeTo: this.route, queryParams: { mode: "view" } });
    window.scrollTo({ left: 0, top: 0 });
  }

  protected enterEditMode() {
    this.router.navigate([], { relativeTo: this.route, queryParams: { mode: "edit" } });
  }

  private initParticipantsAutocompleteOptions() {
    this.participantOptions = this.participantAutocompleteControl.valueChanges.pipe(
      startWith(""),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(
        async (name) =>
          (
            await this.userService.fetchUsers(
              [RolesEnum.Reviewer2, RolesEnum.ApproverWG],
              name || "",
            )
          ).rows,
      ),
    );
  }

  private initChairContactAutocompleteOptions() {
    this.chairContactOptions = this.chairContactAutocompleteControl.valueChanges.pipe(
      startWith(""),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(
        async (name) => (await this.userService.fetchUsers([RolesEnum.Reviewer1], name || "")).rows,
      ),
    );
  }

  private initAccess() {
    const thisRcApplication = this.regionalCoordinatorApplication!;
    this.canEdit = this.userService.hasAccess({
      accessName: AccessName.EditRCApplication,
      regionalCoordinatorApplication: thisRcApplication,
    });
    this.canChangeWorkflowStep = this.userService.hasAccess({
      accessName: AccessName.RCActionButtons,
      regionalCoordinatorApplication: thisRcApplication,
    });
  }

  protected displayContact(user: User): string {
    return user.fullName;
  }

  protected isUserAlreadySelected(user: User): boolean {
    return (
      !!this.regionalCoordinatorApplication!.users.find((e) => e.id === user.id) ||
      !!this.regionalCoordinatorApplication!.chairContacts.find((e) => e.user.id === user.id)
    );
  }

  protected async addRow({
    fieldName,
    event,
  }:
    | {
        fieldName: "milestones" | "rcProjectPreviousYear" | "rcProjectComingYear";
        event?: null;
      }
    | { fieldName: "participants" | "chairContacts"; event: MatAutocompleteSelectedEvent }) {
    switch (fieldName) {
      case "participants":
        await this.updateRCApplication(this.rcId, {
          users: [{ ...event.option.value, RCUser: { type: "participant" }, action: "add" }],
        });
        this.participantAutocompleteControl.patchValue("");
        break;
      case "chairContacts":
        await this.updateRCApplication(this.rcId, {
          [fieldName]: [
            {
              rcApplicationId: this.rcId,
              userId: event.option.value.id,
              id: -1,
              action: "add",
              user: event.option.value,
            },
          ],
        });
        this.chairContactAutocompleteControl.patchValue("");
        // this.fetchRCApplication();
        break;
      case "rcProjectPreviousYear":
        const data = this.regionalCoordinatorForm.value.rcProject;
        await this.updateRCApplication(this.rcId, {
          [fieldName]: [
            {
              rcApplicationId: this.rcId,
              id: -1,
              action: "add",
              title: data?.title,
              objectives: data?.objectives,
              strategicAlignment: data?.strategicAlignment,
              bidpoolCost: data?.bidpoolCost,
              complete: data?.complete,
              councilsInvolved: data?.councilsInvolved,
            },
          ],
        });
        this.fetchRCApplication();
        this.dialog.closeAll();
        break;
      case "rcProjectComingYear":
        const comingYear = this.regionalCoordinatorForm.value.rcProject;
        await this.updateRCApplication(this.rcId, {
          [fieldName]: [
            {
              rcApplicationId: this.rcId,
              id: -1,
              action: "add",
              title: comingYear?.title,
              objectives: comingYear?.objectives,
              strategicAlignment: comingYear?.strategicAlignment,
              bidpoolCost: comingYear?.bidpoolCost,
              councilsInvolved: comingYear?.councilsInvolved,
            },
          ],
        });
        this.fetchRCApplication();
        this.dialog.closeAll();
        break;
      default:
        break;
    }
  }

  protected async deleteRow(
    fieldName:
      | "participants"
      | "chairContacts"
      | "milestones"
      | "rcProjectPreviousYear"
      | "rcProjectComingYear",
    rowIndex: number,
  ) {
    switch (fieldName) {
      case "participants": {
        const deleteUser = this.regionalCoordinatorApplication![fieldName][rowIndex];
        await this.updateRCApplication(this.rcId, {
          users: [{ ...(deleteUser as any), action: "delete" }],
        });
        break;
      }
      case "chairContacts": {
        const deleteId = this.regionalCoordinatorApplication![fieldName][rowIndex].id;
        await this.updateRCApplication(this.rcId, {
          [fieldName]: [{ rcApplicationId: this.rcId, id: deleteId, action: "delete" }],
        });
        // this.fetchRCApplication();
        break;
      }
      case "rcProjectPreviousYear": {
        await this.updateRCApplication(this.rcId, {
          [fieldName]: [{ rcApplicationId: this.rcId, id: rowIndex, action: "delete" }],
        });
        this.fetchRCApplication();
        break;
      }
      case "rcProjectComingYear": {
        await this.updateRCApplication(this.rcId, {
          [fieldName]: [{ rcApplicationId: this.rcId, id: rowIndex, action: "delete" }],
        });
        this.fetchRCApplication();
        break;
      }
      default:
        break;
    }
  }

  get chairContacts() {
    return this.regionalCoordinatorForm.get("chairContacts") as FormArray;
  }

  get coordinatorPosition() {
    return this.regionalCoordinatorForm.get("coordinatorPosition") as FormGroup;
  }

  get regionalCoordinatorMilestone() {
    return this.regionalCoordinatorForm.get("milestones") as FormArray;
  }

  get rcKpis() {
    return this.regionalCoordinatorForm.get("rcKpis") as FormArray;
  }

  getKpiListItemsHtml(label: string): string {
    const kpi = this.kpiDetails.find((k) => k.label === label);
    return kpi ? kpi.items : "";
  }

  private addChairContactFormControls(chairContactId: number) {
    const group = this.formBuilder.group({});
    for (let j in this.formSpec.chairContacts) {
      const isEmailField = j === "email";
      const control = this.formBuilder.control("", [
        Validators.required,
        ...(isEmailField ? [Validators.email] : []),
      ]);

      group.addControl(j, control);
      this.subscriptions.push(
        control.valueChanges
          .pipe(
            map((value) => {
              const formattedValue = this.formSpec.chairContacts[j].formatValueChange
                ? this.formSpec.chairContacts[j].formatValueChange(value)
                : value;
              if (this.formSpec.chairContacts[j].patchFormValue) {
                control.patchValue(this.formSpec.chairContacts[j].patchFormValue(formattedValue), {
                  emitEvent: false,
                });
              }
              return formattedValue;
            }),
          )
          .pipe(debounceTime(this.debounceInMs))
          .subscribe({
            next: async (value) => {
              if (value !== null) {
                await this.formSpec.chairContacts[j].update(value, chairContactId);
              }
            },
          }),
      );
    }
    this.chairContacts.push(group);
  }

  private addCoordinatorPositionFormControls(positionId: number) {
    const group = this.formBuilder.group({});

    for (let key in this.formSpec.coordinatorPosition) {
      const isEmailField = key === "email";
      const control = this.formBuilder.control("", [...(isEmailField ? [Validators.email] : [])]);
      group.addControl(key, control);

      this.subscriptions.push(
        control.valueChanges
          .pipe(
            map((value) => {
              const formattedValue = this.formSpec.coordinatorPosition[key].formatValueChange
                ? this.formSpec.coordinatorPosition[key].formatValueChange(value)
                : value;

              if (this.formSpec.coordinatorPosition[key].patchFormValue) {
                control.patchValue(
                  this.formSpec.coordinatorPosition[key].patchFormValue(formattedValue),
                  {
                    emitEvent: false,
                  },
                );
              }
              return formattedValue;
            }),
          )
          .pipe(debounceTime(this.debounceInMs))
          .subscribe({
            next: async (value) => {
              if (value !== null) {
                await this.formSpec.coordinatorPosition[key].update(value, positionId);
              }
            },
          }),
      );
    }

    this.regionalCoordinatorForm.setControl("coordinatorPosition", group);
  }

  get rcAnnualWorkPlan() {
    return this.regionalCoordinatorForm.controls.rcAnnualWorkPlan;
  }

  /**
   * Method called when a change notification is received by WS.
   * Updates the value in the form control accordingly, and maintains the bidpoolApplication instance values
   * @param value contains only the updated data
   */
  private patchForm(value: RCApplicationUpdate) {
    // Note: the backend could send the entire BidpoolApplication and we could call bidpoolForm.patchValue. This occurs data loss
    // if a user is editing a field and a change notification is received. So patching each individual field instead
    const thisRCApplication = this.regionalCoordinatorApplication!;

    const {
      introduction,
      strategicObject,
      roleOfCoordinator,
      amountRequested,
      users,
      chairContacts,
      coordinatorPosition,
      rcKpis,
      rcAnnualWorkPlan,
      regionalMaturity,
    } = value;

    if (introduction !== undefined) {
      this.regionalCoordinatorForm.controls.introduction.setValue(introduction, {
        emitEvent: false,
      });
      thisRCApplication.introduction = introduction;
    }

    if (strategicObject !== undefined) {
      this.regionalCoordinatorForm.controls.strategicObject.setValue(strategicObject, {
        emitEvent: false,
      });
      thisRCApplication.strategicObject = strategicObject;
    }

    if (roleOfCoordinator !== undefined) {
      this.regionalCoordinatorForm.controls.roleOfCoordinator.setValue(roleOfCoordinator, {
        emitEvent: false,
      });
      thisRCApplication.roleOfCoordinator = roleOfCoordinator;
    }

    if (regionalMaturity !== undefined) {
      this.regionalCoordinatorForm.controls.regionalMaturity.setValue(regionalMaturity, {
        emitEvent: false,
      });
      thisRCApplication.regionalMaturity = regionalMaturity;
    }

    if (amountRequested !== undefined) {
      this.regionalCoordinatorForm.controls.amountRequested.setValue(amountRequested, {
        emitEvent: false,
      });
      thisRCApplication.amountRequested = amountRequested;
    }

    if (rcAnnualWorkPlan) {
      for (let i = 0; i < (rcAnnualWorkPlan || []).length; i++) {
        const indexToReplace = thisRCApplication.rcAnnualWorkPlan.findIndex(
          (e) => e.id === rcAnnualWorkPlan[i].id,
        );
        if (indexToReplace > -1) {
          this.rcAnnualWorkPlan.controls[indexToReplace].patchValue(rcAnnualWorkPlan[i].value, {
            emitEvent: false,
          });
          thisRCApplication.rcAnnualWorkPlan[indexToReplace].value = rcAnnualWorkPlan[i].value;
        }
      }
    }

    if (users) {
      for (let i = 0; i < (users || []).length; i++) {
        switch (users[i].action) {
          case "delete": {
            const rcUserIndex = thisRCApplication.users.findIndex(
              (e) => e.id === users[i].id && e.RCUser.type === users[i].RCUser.type,
            );
            thisRCApplication.users.splice(rcUserIndex, 1);
            break;
          }
          case "add":
            thisRCApplication.users.push(plainToInstance(RegionalCoordinatorUser, users[i]));
            break;
        }
      }
    }

    if (chairContacts) {
      for (let i = 0; i < (chairContacts || []).length; i++) {
        switch (chairContacts[i].action) {
          case "delete":
            const deletedMilestoneIndex = thisRCApplication.chairContacts.findIndex(
              (e) => e.id === chairContacts[i].id,
            );
            if (deletedMilestoneIndex > -1) {
              thisRCApplication.chairContacts.splice(deletedMilestoneIndex, 1);
              this.chairContacts.removeAt(deletedMilestoneIndex, { emitEvent: false });
            }
            break;
          case "add":
            thisRCApplication.chairContacts.push(
              plainToInstance(RCChairContact, {
                ...chairContacts[i],
                rcApplicationId: this.rcId,
              }),
            );
            this.addChairContactFormControls(chairContacts[i].id);
            break;
          case undefined:
            const indexToReplace = thisRCApplication.chairContacts.findIndex(
              (e) => e.id === chairContacts[i].id,
            );
            if (indexToReplace > -1) {
              for (let j in this.formSpec.chairContacts) {
                if (chairContacts[i][j] !== undefined) {
                  this.regionalCoordinatorForm.controls.chairContacts.controls[
                    indexToReplace
                  ].patchValue(
                    {
                      [j]: this.formSpec.chairContacts[j].patchFormValue
                        ? this.formSpec.chairContacts[j].patchFormValue(chairContacts[i][j])
                        : chairContacts[i][j],
                    },
                    { emitEvent: false },
                  );
                }
              }
              thisRCApplication.chairContacts[indexToReplace].patchValue(chairContacts[i]);
            }
            break;
        }
      }
    }

    if (coordinatorPosition) {
      for (let key in this.formSpec.coordinatorPosition) {
        if (coordinatorPosition[key] !== undefined) {
          this.regionalCoordinatorForm.controls.coordinatorPosition.patchValue(
            {
              [key]: this.formSpec.coordinatorPosition[key].patchFormValue
                ? this.formSpec.coordinatorPosition[key].patchFormValue(coordinatorPosition[key])
                : coordinatorPosition[key],
            },
            { emitEvent: false },
          );
        }
      }
    }

    if (rcKpis) {
      for (let i = 0; i < (rcKpis || []).length; i++) {
        const indexToReplace = thisRCApplication.rcKpis.findIndex((e) => e.id === rcKpis[i].id);
        if (indexToReplace > -1) {
          for (let j in this.formSpec.rcKpis) {
            if (rcKpis[i][j] !== undefined) {
              this.regionalCoordinatorForm.controls.rcKpis.controls[indexToReplace].patchValue(
                {
                  [j]: this.formSpec.rcKpis[j].patchFormValue
                    ? this.formSpec.rcKpis[j].patchFormValue(rcKpis[i][j])
                    : rcKpis[i][j],
                },
                { emitEvent: false },
              );
            }
          }
          thisRCApplication.rcKpis[indexToReplace].patchValue(rcKpis[i]);
        }
      }
    }
  }

  public get rcPreviousYearTable(): {
    tableColumns: TableColumn<RCPreviousYear>[];
    tableData: RCPreviousYear[];
  } {
    return {
      tableColumns: [
        { columnDef: "title", columnLabel: "Title" },
        { columnDef: "objectives", columnLabel: "Objectives" },
        { columnDef: "strategicAlignment", columnLabel: "Strategic alignment" },
        { columnDef: "councilsInvolved", columnLabel: "Councils involved" },
        { columnDef: "bidpoolCost", columnLabel: "Bidpool cost" },
        { columnDef: "complete", columnLabel: "% complete" },
        {
          columnDef: "action",
          columnLabel: "Action",
          render: () => "",
          actions: (element: RCPreviousYear) => [
            {
              type: "button",
              label: "",
              icon: "delete",
              action: () => this.deletePreviousProject(element.id, "prev"),
            },
          ],
        },
      ],
      tableData: this.regionalCoordinatorApplication!.rcProjectPreviousYear,
    };
  }

  public get rcComingYearTable(): {
    tableColumns: TableColumn<RCComingYear>[];
    tableData: RCComingYear[];
  } {
    return {
      tableColumns: [
        { columnDef: "title", columnLabel: "Title" },
        { columnDef: "objectives", columnLabel: "Objectives" },
        { columnDef: "strategicAlignment", columnLabel: "Strategic alignment" },
        { columnDef: "councilsInvolved", columnLabel: "Councils involved" },
        { columnDef: "bidpoolCost", columnLabel: "Bidpool cost" },
        {
          columnDef: "action",
          columnLabel: "Action",
          render: () => "",
          actions: (element: RCPreviousYear) => [
            {
              type: "button",
              label: "",
              icon: "delete",
              action: () => this.deletePreviousProject(element.id, "next"),
            },
          ],
        },
      ],
      tableData: this.regionalCoordinatorApplication!.rcProjectComingYear,
    };
  }

  onTableAction({
    action,
    element,
  }: {
    action: (element: RCComingYear | RCPreviousYear) => void;
    element: RCComingYear | RCPreviousYear;
  }) {
    action(element);
  }

  protected deletePreviousProject(id: number | undefined | string, type: string) {
    if (id) {
      type == "prev"
        ? this.deleteRow("rcProjectPreviousYear", +id)
        : this.deleteRow("rcProjectComingYear", +id);
    }
  }

  openProject(event: TemplateRef<unknown>, type: string) {
    const rcProjectGroup = this.regionalCoordinatorForm.get("rcProject") as FormGroup;
    Object.keys(rcProjectGroup.controls).forEach((key) => {
      const control = rcProjectGroup.get(key);
      if (control) {
        control.setValidators([Validators.required]);
        control.updateValueAndValidity();
      }
    });
    if (type === "prev") {
      this.isPrevious = true;
      this.regionalCoordinatorForm.get("rcProject.complete")?.setValidators([Validators.required]);
    } else {
      this.isPrevious = false;
      this.regionalCoordinatorForm.get("rcProject.complete")?.clearValidators();
    }
    this.regionalCoordinatorForm
      .get("rcProject.complete")
      ?.updateValueAndValidity({ onlySelf: true });

    const dialogRef = this.dialog.open(event, {
      width: "500px",
    });

    dialogRef.afterClosed().subscribe(() => {
      this.regionalCoordinatorForm.get("rcProject")?.reset();

      Object.keys(rcProjectGroup.controls).forEach((key) => {
        const control = rcProjectGroup.get(key);
        if (control) {
          control.clearValidators();
          control.clearAsyncValidators();
          control.updateValueAndValidity({ onlySelf: true });

          control.markAsPristine();
          control.markAsUntouched();
        }
      });
      this.regionalCoordinatorForm.get("rcProject")?.clearValidators();
      this.regionalCoordinatorForm.get("rcProject")?.clearAsyncValidators();
      this.regionalCoordinatorForm.get("rcProject")?.updateValueAndValidity();
      this.regionalCoordinatorForm.updateValueAndValidity();
    });
  }

  addProject() {
    if (this.isPrevious) {
      if (this.regionalCoordinatorForm.get("rcProject")?.valid) {
        this.addRow({ fieldName: "rcProjectPreviousYear" });
      }
    } else {
      if (this.regionalCoordinatorForm.get("rcProject")?.valid) {
        this.addRow({ fieldName: "rcProjectComingYear" });
      }
    }
  }

  protected async onSubmit() {
    let formIsValid = true;
    this.formErrors = [];

    if (!this.regionalCoordinatorApplication!.participants.length) {
      formIsValid = false;
      this.formErrors.push("Participants must be added");
    }

    if (!this.isPaymentValid()) {
      this.highlightInvalidPaymentFields();
    }

    if (!this.regionalCoordinatorForm.valid) {
      formIsValid = false;
      this.regionalCoordinatorForm.markAllAsTouched();
      this.formErrors.push("The form has missing fields");
    }

    if (formIsValid) {
      await this.progressToNextWorkflowStep();
    }
  }

  protected async progressToNextWorkflowStep() {
    await this.rcServive.updateWorkflow(this.rcId, { target: "next" });
    this.regionalCoordinatorApplication = await this.rcServive.fetchRCApplication(this.rcId);
    this.enterViewMode();
    this.initAccess();
  }

  protected async putBackToDraft() {
    await this.rcServive.updateWorkflow(this.rcId, { target: "draft", comment: "" });
    this.regionalCoordinatorApplication = await this.rcServive.fetchRCApplication(this.rcId);
    this.enterViewMode();
    this.initAccess();
  }

  private async updateRCApplication(rcId: number, changes: RCApplicationUpdate) {
    this.saving = true;
    await this.rcServive.updateRCApplication(rcId, changes);
    setTimeout(() => {
      this.saving = false;
    }, 1000);
  }

  private formatToNumber(e: string) {
    return Number((e || "").toString().replace(/\D/g, "").replace(/^0+/, ""));
  }

  private formatToPhoneNumber(e: string) {
    return (e || "").toString().replace(/\D/g, "");
  }

  openDocumentModel(content: TemplateRef<unknown>) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "share-modal",
    });
  }

  persistS3Image(event, type) {
    this.isUploading = true;
    let theFile = event.srcElement.files[0];
    const allowedFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
      "application/pdf", // PDF
      "image/jpeg", // JPG
      "image/png", // PNG
      "text/plain", // TXT
      "text/csv", // CSV
    ];
    if (theFile && allowedFileTypes.includes(theFile.type)) {
      this.documentForm.patchValue({
        type: theFile.type,
      });

      this.fileUploadService.uploadRCDocument(theFile, (err, data) => {
        let url = data.Location.replace(this.s3Prefix, this.cfDistribution);
        this.documentForm.patchValue({
          documentFile: url,
        });
        this.isUploading = false;
      });
    } else {
      alert(
        "Invalid file type. Please select a valid file (XLSX, DOCX, PPTX, PDF, JPG, PNG, TXT, CSV).",
      );
      this.isUploading = false;
      event.target.value = "";
    }
  }

  getDownloadLink(fileUrl: string | undefined) {
    if (fileUrl) {
      const key = fileUrl.replace("https://files-qwrap-dev.s3.ap-southeast-2.amazonaws.com/", "");
      this.fileUploadService.getDownloadSignedUrl(key).subscribe(
        (response) => {
          window.open(response.url, "_blank");
        },
        (error) => {
          console.error("Error fetching signed URL", error);
        },
      );
    }
  }

  async uploadDocument() {
    this.documentForm.patchValue({
      rcApplicationId: this.rcId,
    });
    if (!this.documentForm.valid) {
      this.toastrService.warning("Invalid Document");
      return;
    }
    try {
      await this.rcDocumentService.create(this.documentForm.value);
      this.fetchRCDocumnets();
      this.documentForm.reset();
      this.close();
    } catch (error) {
      this.documentForm.reset();
      logger.error("Error updating user", error);
    }
  }

  fetchRCDocumnets() {
    const query: IQueryFilter = new IQueryFilter({
      filter: { rcApplicationId: this.rcId },
      limit: 10,
    });
    (query.skip = this.documentPaginationIndex * query.limit),
      this.rcDocumentService
        .fetchDocumnets(query)
        .then((res) => {
          this.rcDocuments = res;
        })
        .catch((error) => {
          logger.debug("Silently catch error", error);
        });
  }

  handlePaginationEvent(event: PageEvent) {
    this.documentPaginationIndex = event.pageIndex;
    this.fetchRCDocumnets();
  }

  deleteDocument(documentId: number) {
    this.rcDocumentService
      .delete(documentId)
      .then(() => {
        this.fetchRCDocumnets();
      })
      .catch((error) => {
        logger.debug("Silently catch error", error);
      });
  }

  close() {
    this.modalService.dismissAll();
  }

  getFileTypeIcon(documentType: string | undefined): string {
    if (!documentType) {
      return "insert_drive_file";
    }

    if (documentType.startsWith("image/")) {
      return "image";
    } else if (documentType === "application/pdf") {
      return "picture_as_pdf";
    } else {
      return "insert_drive_file";
    }
  }

  public get contactTable() {
    return {
      tableColumns: [
        { columnDef: "col", columnLabel: "Contact information" },
        { columnDef: "info" },
      ],
      tableData: [
        {
          col: "Name of regional alliance",
          info: this.regionalCoordinatorApplication!.regionalAlliance,
        },
        { col: "Contact person", info: this.regionalCoordinatorApplication!.contact?.fullName },
        { col: "Phone number", info: this.regionalCoordinatorApplication!.contact?.phone },
        { col: "Email", info: this.regionalCoordinatorApplication!.contact?.email },
      ],
    };
  }

  public get participantsTable() {
    return {
      tableColumns: [
        { columnDef: "role", columnLabel: "Participants", render: (e: User) => e.roles[0].name },
        { columnDef: "fullName" },
        { columnDef: "email" },
      ],
      tableData: this.regionalCoordinatorApplication!.participants,
    };
  }

  public get chairContactTable() {
    return {
      tableColumns: [
        {
          columnDef: "name",
          columnLabel: "Name of regional alliance",
          render: (e: RCChairContact) => e.user.regionalAlliance,
        },
        {
          columnDef: "contactPerson",
          columnLabel: "Contact person",
          render: (e: RCChairContact) => e.user.fullName,
        },
        { columnDef: "phone", columnLabel: "Phone" },
        { columnDef: "email", columnLabel: "Email", render: (e: RCChairContact) => e.user.email },
        { columnDef: "dateEndorsement", columnLabel: "Date of endorsement" },
      ],
      tableData: this.regionalCoordinatorApplication!.chairContacts,
    };
  }

  public get coordinatorPositionTable() {
    return {
      tableColumns: [
        { columnDef: "col", columnLabel: "Coordinator position" },
        { columnDef: "info" },
      ],
      tableData: [
        {
          col: "Name of coordinator",
          info: this.regionalCoordinatorApplication!.coordinatorPosition.name,
        },
        { col: "Phone", info: this.regionalCoordinatorApplication!.coordinatorPosition?.phone },
        { col: "Email", info: this.regionalCoordinatorApplication!.coordinatorPosition?.email },
        {
          col: "Start date",
          info: this.regionalCoordinatorApplication!.coordinatorPosition?.startDate,
        },
      ],
    };
  }

  public get projectInfoTable() {
    return {
      tableColumns: [
        { columnDef: "col", columnLabel: "Project information" },
        { columnDef: "info" },
      ],
      tableData: [
        { col: "Introduction", info: this.regionalCoordinatorApplication!.introduction },
        { col: "Strategic objectives", info: this.regionalCoordinatorApplication!.strategicObject },
        { col: "Regional maturity", info: this.regionalCoordinatorApplication!.regionalMaturity },
        {
          col: "Role of the coordinator and KPIs",
          info: this.regionalCoordinatorApplication!.roleOfCoordinator,
        },
        { col: "Amount Requested", info: this.regionalCoordinatorApplication!.amountRequested },
      ],
    };
  }

  public get kpiTable() {
    return {
      tableColumns: [
        { columnDef: "kpi", columnLabel: "KPI", render: (e: RCKpi) => e.label },
        { columnDef: "weight", columnLabel: "Weight" },
      ],
      tableData: this.regionalCoordinatorApplication!.rcKpis,
    };
  }

  public get rcPreviousProjectTable() {
    return {
      tableColumns: [
        { columnLabel: "Title", columnDef: "title" },
        { columnLabel: "Objectives", columnDef: "objectives" },
        { columnLabel: "Strategic Alignment", columnDef: "strategicAlignment" },
        { columnLabel: "Councils Involved", columnDef: "councilsInvolved" },
        { columnLabel: "Bidpool Cost", columnDef: "bidpoolCost" },
        { columnLabel: "% Complete", columnDef: "complete" },
      ],
      tableData: this.regionalCoordinatorApplication!.rcProjectPreviousYear,
    };
  }

  public get rcComingProjectTable() {
    return {
      tableColumns: [
        { columnLabel: "Title", columnDef: "title" },
        { columnLabel: "Objectives", columnDef: "objectives" },
        { columnLabel: "Strategic Alignment", columnDef: "strategicAlignment" },
        { columnLabel: "Councils Involved", columnDef: "councilsInvolved" },
        { columnLabel: "Bidpool Cost", columnDef: "bidpoolCost" },
      ],
      tableData: this.regionalCoordinatorApplication!.rcProjectComingYear,
    };
  }

  private formatToPercentageNumber(e: string) {
    return Math.min(
      100,
      Math.max(0, Number((e || "").toString().replace(/\D/g, "").replace(/^0+/, ""))),
    );
  }

  isPaymentValid(): boolean {
    const totalPct = this.rcKpis.controls.reduce(
      (acc, kpi) => acc + (kpi.get("weight")?.value || 0),
      0,
    );
    return totalPct === 100;
  }

  highlightInvalidPaymentFields() {
    this.rcKpis.controls.forEach((kpi) => {
      const paymentControl = kpi.get("weight");
      if (paymentControl) {
        const totalPct = this.rcKpis.controls.reduce(
          (acc, milestone) => acc + (milestone.get("weight")?.value || 0),
          0,
        );
        if (totalPct !== 100) {
          paymentControl.setErrors({ invalidPercentage: true });
        } else {
          paymentControl.setErrors(null);
        }
      }
    });
  }

  async saveFeedback() {
    this.saving = true;
    await this.rcServive.updateRCFeedback({
      feedback: this.feedback.value,
      rcApplicationId: this.rcId,
    });
    setTimeout(() => {
      this.feedback.patchValue("");
      this.feedbackComponent.refreshTableData();
      this.saving = false;
    }, 1000);
  }

  getDocument(text: string) {
    return decodeURIComponent(text);
  }
}
