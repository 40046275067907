export const milestoneDateFormat = {
  parse: {
    dateInput: "YYYYMM",
    yearMonthDayInput: "YYYYMMDD",
  },
  display: {
    dateInput: "MMMM YYYY",
    dayMonthYearLabel: "DD MMMM YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

export class BidpoolMilestone {
  id: number;
  bidpoolApplicationId: number;
  date?: string;
  description?: string;
  paymentPct?: number;

  public patchValue(value: Partial<BidpoolMilestone>) {
    const { date, description, paymentPct } = value;

    if (date !== undefined) this.date = date;
    if (description !== undefined) this.description = description;
    if (paymentPct !== undefined) this.paymentPct = paymentPct;
  }
}
