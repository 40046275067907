import { User } from "../user/user.model";

export class BidpoolUser extends User {
  BidpoolUser: {
    type: "creator" | "contact" | "participant";
  };

  get type() {
    return this.BidpoolUser.type;
  }
}
