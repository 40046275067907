import { AbstractControl } from "@angular/forms";

export const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const GenericEmailRegex = /(admin|info|enquiries|reception|surgery|manager)@/;
export const PasswordReg = /^(?=.*[A-Z])(?=.*\W).{8,}$/;
export const ISODateRegEx =
  /\b[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{2,3})?Z\b/;

export function emailValidator(control: AbstractControl): { [key: string]: any } | null {
  if (isEmptyInputValue(control.value)) {
    // if nothing has been passed then let other validator catch it
    return null;
  }
  const isValidEmail = EmailRegex.test(control.value.trim());
  return isValidEmail ? null : { invalidEmail: true };
}

export function genericEmailValidator(control: AbstractControl): { [key: string]: any } | null {
  if (isEmptyInputValue(control.value)) {
    // if nothing has been passed then let other validator catch it
    return null;
  }
  const isValidEmail = !GenericEmailRegex.test(control.value.trim());
  return isValidEmail ? null : { invalidEmail: true };
}

export function passwordValidator(control: AbstractControl): { [key: string]: any } | null {
  if (isEmptyInputValue(control.value)) {
    // if nothing has been passed then let other validator catch it
    return null;
  }
  const isValidPassword = PasswordReg.test(control.value.trim());
  return isValidPassword ? null : { invalidPassword: true };
}

export function trimRequiredValidator(control: AbstractControl): { [key: string]: any } | null {
  const isValid = !!(
    control.value &&
    typeof control.value === "string" &&
    control.value.trim().length > 0
  );
  return isValid ? null : { required: { value: control.value } };
}

function isEmptyInputValue(value: any): boolean {
  return (
    value == null || value.length === 0 || (typeof value === "string" && value.trim().length === 0)
  );
}

export function validateConfirmPassword(control: AbstractControl): boolean {
  const passwordControl = control.root.get("password");
  if (!passwordControl) {
    return false;
  }
  if (passwordControl.value !== control.value) {
    return false;
  }
  return true;
}

export function sixDigitCodeValidator(control: AbstractControl): { [key: string]: any } | null {
  const value = control.value;
  if (!/^\d{6}$/.test(value)) {
    return { invalidCode: true };
  }
  return null;
}
