import { Injectable, inject } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { ToastrService } from "ngx-toastr";
import { Observable, lastValueFrom } from "rxjs";
import { API_ASSOCIATIONS } from "../api/api.constants";
import { BidpoolApi } from "../api/bidpool.api";
import { TAPIListResult } from "../contract/api.contract";
import {
  BidpoolApplicationUpdate,
  IUpdateBidpoolApplicationWorkflowStepInput,
} from "../contract/bidpool.contract";
import { WorkflowStepsEnum } from "../contract/workflowStep.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { BidpoolActivityLogs } from "../model/bidpool/bidpool-action-logs.model";
import { BidpoolApplication } from "../model/bidpool/bidpool-application.model";
import { BidpoolFeedback } from "../model/bidpool/bidpool-feedback.model";
import { apiCallWrapper, apiSubscriptionWrapper, inflateModel } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
/**
 * @description This service is used to manage bidpool applications
 */
export class BidpoolService {
  private toastrService = inject(ToastrService);
  private bidpoolApi = inject(BidpoolApi);

  public async createBidpoolApplication(): Promise<BidpoolApplication> {
    return await lastValueFrom(
      apiCallWrapper(this.bidpoolApi.create(), {
        notificationsService: this.toastrService,
        action: "Create bid pool application",
        showSuccessNotification: true,
        successTitle: "Created bid pool application",
      }),
    );
  }

  public async fetchBidpoolApplication(id: number): Promise<BidpoolApplication> {
    const data = await lastValueFrom(
      apiCallWrapper(this.bidpoolApi.get(id), {
        notificationsService: this.toastrService,
        action: "Fetch bid pool application",
      }),
    );

    return plainToInstance(BidpoolApplication, data);
  }

  public async fetchBidpoolApplications(
    query: Partial<IQueryFilter>,
  ): Promise<TAPIListResult<BidpoolApplication>> {
    const data = await lastValueFrom(
      apiCallWrapper(
        this.bidpoolApi.list(
          new IQueryFilter({ ...API_ASSOCIATIONS.bidpoolApplications, ...query }),
        ),
        {
          notificationsService: this.toastrService,
          action: "Fetch bid pool applications",
        },
      ),
    );

    return inflateModel(BidpoolApplication, data);
  }

  public async updateBidpoolApplication(
    id: number,
    changes: BidpoolApplicationUpdate,
  ): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.bidpoolApi.update(id, changes), {
        notificationsService: this.toastrService,
        action: "Update bid pool application",
      }),
    );

    return;
  }

  public subscribeBidpoolApplication(id: number): Observable<BidpoolApplication> {
    return apiSubscriptionWrapper(this.bidpoolApi.socket(id), {
      notificationsService: this.toastrService,
      failTitle: "Failed to fetch bidpool application",
    });
  }

  public async updateWorkflow(
    id: number,
    options: IUpdateBidpoolApplicationWorkflowStepInput,
  ): Promise<void> {
    const data = await lastValueFrom(
      apiCallWrapper(this.bidpoolApi.updateWorkflow(id, options), {
        notificationsService: this.toastrService,
        action: "Update bidpool step",
      }),
    );

    switch (data.newWorkflowStepId) {
      case WorkflowStepsEnum.Draft:
        this.toastrService.success("Application sent back to Draft");
        break;
      case WorkflowStepsEnum.R1:
        this.toastrService.success("Application sent to Chair");
        break;
      case WorkflowStepsEnum.R2:
        this.toastrService.success("Application sent to Banker");
        break;
      case WorkflowStepsEnum.AWG:
        this.toastrService.success("Application sent to Working Group");
        break;
      case WorkflowStepsEnum.APSC:
        this.toastrService.success("Application sent to Partner Steering Committee");
        break;
      case WorkflowStepsEnum.Approved:
        this.toastrService.success("Application approved");
        break;
    }
  }

  public async fetchActivityLogs(
    query: IQueryFilter,
  ): Promise<TAPIListResult<BidpoolActivityLogs>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.bidpoolApi.listActivityLogs(query), {
        notificationsService: this.toastrService,
        action: "Fetch bid pool  activity logs",
      }),
    );

    return inflateModel(BidpoolActivityLogs, data);
  }

  public async updateBidpoolFeedback(changes: {
    feedback: string | null | undefined;
    bidpoolApplicationId: number;
  }): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.bidpoolApi.updateFeedback(changes), {
        notificationsService: this.toastrService,
        action: "Update bid pool comment",
      }),
    );

    return;
  }

  public async fetchBidpoolFeedbacks(
    query: IQueryFilter,
  ): Promise<TAPIListResult<BidpoolFeedback>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.bidpoolApi.listBidPoolFeedback(query), {
        notificationsService: this.toastrService,
        action: "Fetch bid pool  feedback",
      }),
    );

    return inflateModel(BidpoolFeedback, data);
  }
}
