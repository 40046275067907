<h2>Edit Role</h2>
<form>
  <mat-form-field>
    <mat-label>Roles Selected</mat-label>
    <input matInput readonly [value]="getSelectedRolesCount() + ' items selected'" />
  </mat-form-field>

  <div>
    <ng-container *ngFor="let role of rolesListing.rows">
      <mat-checkbox
        [id]="role.id.toString()"
        (change)="toggleRoleSelection(role, $event)"
        [checked]="selectedRoles.has(role.id)"
      >
        {{ role.name }}
      </mat-checkbox>
    </ng-container>
  </div>
</form>
