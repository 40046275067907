<app-authenticated-layout>
  <div class="mb-3">
    <div class="clmainhdr">
      <div class="clheader">
        <div class="newclpr d-flex align-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 2V6"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 2V6"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 10H21"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <h4>Calendar</h4>
        </div>
        <div class="clndrbtnsc">
          <button
            class="tdbtn"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            (viewDateChange)="onViewDateChange()"
          >
            Today
          </button>
          <div class="arrbtn">
            <button
              mwlCalendarPreviousView
              [view]="view"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay(); onViewDateChange()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path
                  d="M7 13L1 7L7 1"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              mwlCalendarNextView
              [view]="view"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay(); onViewDateChange()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <h5>{{ viewDate | calendarDate: view + "ViewTitle" : "en" }}</h5>
      </div>

      <div class="d-flex align-items-center">
        <div class="refresh-wrap d-flex justify-content-xs-center justify-content-end">
          <div class="text-side">
            <input type="text" matTooltip="Search..." placeholder="Search..." #searchInput />
          </div>
          <div class="refresh-btn">
            <button type="button" class="d-flex text-white pe-none">
              <img src="../../../assets/images/searchicon.svg" alt="" />
            </button>
          </div>
        </div>
        <div class="newinputs calneins ms-3">
          <div class="">
            <mat-form-field appearance="fill">
              <mat-select [(value)]="view" (selectionChange)="setView($event.value)">
                <mat-option [value]="CalendarView.Month">Month</mat-option>
                <mat-option [value]="CalendarView.Week">Week</mat-option>
                <mat-option [value]="CalendarView.Day">Day</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="lgbar">
          <div class="inrdvb">
            <img src="../../../assets/images/QWRAP_Logo-Long.svg" alt="" />
            <span class="inrnmbx">J</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3 bg-light-gr">
      <div class="my-3">
        <button class="Schedule-newbtn" (click)="openEventModel(event)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M16 6.66675V25.3334"
              stroke="#0091AD"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.66699 16H25.3337"
              stroke="#0091AD"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Schedule
        </button>
      </div>
      <div class="date-picker mb-3">
        <ngb-datepicker
          [(ngModel)]="viewDate"
          (ngModelChange)="onCalenderViewDateChange()"
        ></ngb-datepicker>
      </div>
      <div class="meetwith d-none">
        <h5 class="mb-2">Meet with</h5>
        <div class="mtinr d-flex align-items-center gap-3">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.5 11C10.7091 11 12.5 9.20914 12.5 7C12.5 4.79086 10.7091 3 8.5 3C6.29086 3 4.5 4.79086 4.5 7C4.5 9.20914 6.29086 11 8.5 11Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20 8V14"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M23 11H17"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <mat-form-field appearance="fill">
            <input
              matInput
              matTooltip="Search for the people"
              placeholder="Search for the people"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="CalendarView.Month"
          [viewDate]="viewDate"
          [activeDayIsOpen]="activeDayIsOpen"
          [events]="calendarEvents"
          [cellTemplate]="customCellTemplate"
          [activeDayIsOpen]="false"
        >
        </mwl-calendar-month-view>

        <ng-template #customCellTemplate let-day="day">
          <div class="calendar-cell" (click)="hourSegmentClicked(day)">
            <div class="day-number">{{ day.date | date: "d" }}</div>
            <div *ngFor="let event of day.events" class="event-item">
              <a (click)="openEventDetails(event); $event.stopPropagation()">{{ event.title }}</a>
            </div>
          </div>
        </ng-template>

        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          (hourSegmentClicked)="hourSegmentClicked($event)"
          [events]="calendarEvents"
          (eventClicked)="openEventDetails($event.event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          (hourSegmentClicked)="hourSegmentClicked($event)"
          (eventClicked)="openEventDetails($event.event)"
          [events]="calendarEvents"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
</app-authenticated-layout>

<ng-template #event>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>Add event</h1>
    <button mat-dialog-close class="fs-6 me-4">
      <mat-icon fontIcon="close"></mat-icon>
    </button>
  </div>
  <form [formGroup]="eventForm" class="newinputs newformmodal">
    <mat-dialog-content>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label>Add title</mat-label>
            <input matInput matTooltip="title" placeholder="title" formControlName="title" />
            <mat-error
              *ngIf="eventFormControl.title.hasError('required') && eventFormControl.title.touched"
            >
              title is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label>Date</mat-label>
            <input
              matInput
              mwlFlatpickr
              formControlName="startDate"
              [convertModelValue]="false"
              [altInput]="true"
              altFormat="d/m/Y"
              dateFormat="Y-m-d"
              matTooltip="Select Date"
              placeholder="Select Date"
            />
            <mat-error
              *ngIf="
                eventFormControl.startDate.hasError('required') &&
                eventFormControl.startDate.touched
              "
            >
              date is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label>Time</mat-label>
            <input
              matInput
              mwlFlatpickr
              [noCalendar]="true"
              [enableTime]="true"
              [dateFormat]="'H:i'"
              matTooltip="Select Time"
              placeholder="Select Time"
              formControlName="startTime"
            />
            <mat-error
              *ngIf="
                eventFormControl.startTime.hasError('required') &&
                eventFormControl.startTime.touched
              "
            >
              time is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label>Location</mat-label>
            <textarea
              matInput
              matTooltip="location"
              placeholder="location"
              formControlName="location"
              rows="2"
            ></textarea>
            <mat-error
              *ngIf="
                eventFormControl.location.hasError('required') && eventFormControl.location.touched
              "
            >
              Location is required.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>
            <textarea
              matInput
              matTooltip="description"
              placeholder="description"
              formControlName="description"
              rows="2"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="ps-4 mb-3">
      <button class="secondbtn me-3" mat-dialog-close>Cancel</button>
      <button class="createbtn" (click)="addEvent()">Add</button>
    </mat-dialog-actions>
  </form>
</ng-template>

<ng-template #eventDetails>
  <div class="d-flex justify-content-between">
    <h1 mat-dialog-title>Event Detail</h1>
    <button mat-dialog-close class="fs-6 me-4">
      <mat-icon fontIcon="close"></mat-icon>
    </button>
  </div>
  <mat-dialog-content *ngIf="selectedEvent">
    <p>Title: {{ selectedEvent.title }}</p>
    <p>Date: {{ selectedEvent.start | date: "dd MMM yyyy" }}</p>
    <p>Time: {{ selectedEvent.time | date: "H:mm" }}</p>
    <p>Description: {{ selectedEvent.description }}</p>
    <p>Location: {{ selectedEvent.location }}</p>
  </mat-dialog-content>
</ng-template>
