import { Injectable, inject } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { plainToClass, plainToInstance } from "class-transformer";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";
import { API_ASSOCIATIONS } from "../api/api.constants";
import { UserApi } from "../api/user.api";
import { TAPIListResult } from "../contract/api.contract";
import { PermissionsEnum, RolesEnum, UserPhoneUpdate } from "../contract/user.contract";
import { RCWorkflowStepsEnum, WorkflowStepsEnum } from "../contract/workflowStep.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { BidpoolApplication } from "../model/bidpool/bidpool-application.model";
import { RCApplication } from "../model/regional-coordinator/rc-appication.model";
import { User } from "../model/user/user.model";
import { apiCallWrapper, inflateModel } from "../util/api.util";

export enum AccessName {
  CreateBidpoolApplication = "CreateBidpoolApplication",
  EditBidpoolApplication = "EditBidpoolApplication",
  RWBidpoolPaymentInformation = "RWBidpoolPaymentInformation",
  BidpoolApplicationActionButtons = "BidpoolApplicationActionButtons",
  CreateRegionalCoordinatorApplication = "CreateRegionalCoordinatorApplication",
  EditRCApplication = "EditRCapplication",
  RCActionButtons = "RCActionButtons",
}

export type passWordUpdate = {
  password: string | undefined;
  oldPassword: string | undefined;
  userId: number | string | undefined;
};

type HasAccessArgs =
  | { accessName: AccessName.CreateBidpoolApplication }
  | { accessName: AccessName.EditBidpoolApplication; bidpoolApplication: BidpoolApplication }
  | { accessName: AccessName.RWBidpoolPaymentInformation; bidpoolApplication: BidpoolApplication }
  | {
      accessName: AccessName.BidpoolApplicationActionButtons;
      bidpoolApplication: BidpoolApplication;
    }
  | { accessName: AccessName.CreateRegionalCoordinatorApplication }
  | { accessName: AccessName.EditRCApplication; regionalCoordinatorApplication: RCApplication }
  | {
      accessName: AccessName.RCActionButtons;
      regionalCoordinatorApplication: RCApplication;
    };

@Injectable({
  providedIn: "root",
})
/**
 * @description This service is used to manage user API.
 */
export class UserService {
  private notifications = inject(ToastrService);
  private userApi = inject(UserApi);
  public currentUserSubject = new BehaviorSubject<User | null>(null);

  public clearUser(): void {
    this.currentUserSubject.next(null);
  }

  /**
   * @description This method is used to get the user by id and set it in the currentUserSubject.
   * @param id
   * @returns
   */
  public async setUser(id: string): Promise<boolean> {
    try {
      const query: IQueryFilter = new IQueryFilter({
        include: [{ name: "roles", include: [{ name: "permissions" }] }],
      });

      const data = await lastValueFrom(
        apiCallWrapper(this.userApi.get(id), {
          notificationsService: this.notifications,
          action: "Obtaining user details",
        }),
      );

      if (data) {
        let user: User = plainToClass(User, data);

        //TODO: tried to put this in a SentryService but it didn't work
        this.currentUserSubject.next(user);
        Sentry.setUser({
          id: user.id,
          email: user.email,
        });
      }

      return true;
    } catch (error) {
      return false;
    }
  }

  public async fetchUsers(roles, searchValue: string | User = "") {
    const query: IQueryFilter = new IQueryFilter({
      limit: 100,
      include: [{ name: "roles", required: true }],
    });
    if (searchValue) {
      let searchString = "";
      if (typeof searchValue === "string") {
        searchString = searchValue;
      }
      query.filter = { search: searchString };
    }
    query.filter.id = roles;
    const data = await lastValueFrom(
      apiCallWrapper(this.userApi.list(query), {
        notificationsService: this.notifications,
        action: "Obtaining user details",
      }),
    );

    return inflateModel(User, data);
  }

  public hasAccess(access: HasAccessArgs): boolean {
    if (!this.currentUser) return false;

    switch (access.accessName) {
      case AccessName.CreateBidpoolApplication:
        return this.currentUser.hasAllPermissions([PermissionsEnum.CreateApplication]);
      case AccessName.CreateRegionalCoordinatorApplication:
        return this.currentUser.hasAllPermissions([PermissionsEnum.CreateApplication]);
      case AccessName.EditBidpoolApplication:
        return (
          access.bidpoolApplication.workflowStepId === WorkflowStepsEnum.Draft &&
          this.currentUser.hasAllPermissions([PermissionsEnum.EditApplication]) &&
          !!access.bidpoolApplication.users.find((user) => user.id === this.currentUser?.id)
        );
      case AccessName.EditRCApplication:
        return (
          access.regionalCoordinatorApplication.workflowStepId === RCWorkflowStepsEnum.Draft &&
          this.currentUser.hasAllPermissions([PermissionsEnum.EditApplication]) &&
          !!access.regionalCoordinatorApplication.users.find(
            (user) => user.id === this.currentUser?.id,
          )
        );
      case AccessName.RWBidpoolPaymentInformation:
        return (
          access.bidpoolApplication.allianceId === null ||
          (this.currentUser.alliance &&
            this.currentUser.alliance.length > 0 &&
            access.bidpoolApplication.allianceId === this.currentUser.alliance[0].id) ||
          false
        );
      case AccessName.BidpoolApplicationActionButtons:
        return (
          !!access.bidpoolApplication.users.find((user) => user.id === this.currentUser?.id) &&
          ((access.bidpoolApplication.workflowStepId === WorkflowStepsEnum.R1 &&
            this.currentUser.hasRole(RolesEnum.Reviewer1)) ||
            (access.bidpoolApplication.workflowStepId === WorkflowStepsEnum.R2 &&
              this.currentUser.hasRole(RolesEnum.Reviewer2)) ||
            (access.bidpoolApplication.workflowStepId === WorkflowStepsEnum.AWG &&
              this.currentUser.hasRole(RolesEnum.ApproverWG)) ||
            (access.bidpoolApplication.workflowStepId === WorkflowStepsEnum.APSC &&
              this.currentUser.hasRole(RolesEnum.ApproverPSC)) ||
            (access.bidpoolApplication.workflowStepId === WorkflowStepsEnum.Contributor &&
              this.currentUser.hasRole(RolesEnum.Contributor)) ||
            (access.bidpoolApplication.workflowStepId === WorkflowStepsEnum.Approved &&
              this.currentUser.hasRole(RolesEnum.ApproverWG)))
        );
      case AccessName.RCActionButtons:
        return (
          (!!access.regionalCoordinatorApplication.users.find(
            (user) => user.id === this.currentUser?.id,
          ) ||
            !!access.regionalCoordinatorApplication.chairContacts.find(
              (chairContact) => chairContact.user.id === this.currentUser?.id,
            )) &&
          ((access.regionalCoordinatorApplication.workflowStepId === RCWorkflowStepsEnum.R1 &&
            this.currentUser.hasRole(RolesEnum.Reviewer1)) ||
            (access.regionalCoordinatorApplication.workflowStepId === RCWorkflowStepsEnum.R2 &&
              this.currentUser.hasRole(RolesEnum.Reviewer2)) ||
            (access.regionalCoordinatorApplication.workflowStepId === RCWorkflowStepsEnum.AWG &&
              this.currentUser.hasRole(RolesEnum.ApproverWG)) ||
            access.regionalCoordinatorApplication.workflowStepId === RCWorkflowStepsEnum.Approved)
        );
    }
  }

  public get currentUser(): User | null {
    return this.currentUserSubject.value;
  }

  public get defaultLandingPage(): string {
    if (!this.currentUser || !this.currentUser.roles.length) {
      return "/login";
    }
    return "/";
  }

  /**
   * @description This is used to get the user by id
   * @param id
   * @returns
   */
  public async fetchSingleUser(id: string | number): Promise<User> {
    const data = await lastValueFrom(
      apiCallWrapper(this.userApi.get(id), {
        notificationsService: this.notifications,
        action: "Fetching User Details",
      }),
    );

    return plainToInstance(User, data);
  }

  /**
   * @description This method is used to grab a list of all users.
   * @param query
   * @returns
   */
  public async list(query: Partial<IQueryFilter>): Promise<TAPIListResult<User>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.userApi.list(new IQueryFilter(query)), {
        notificationsService: this.notifications,
        action: "Fetch user",
      }),
    );

    return inflateModel(User, data);
  }

  /**
   * @description This method is used to Create any user.
   * @param id
   * @param changes
   * @returns
   */
  public async createUser(body: Partial<User>): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.userApi.create(body), {
        notificationsService: this.notifications,
        action: "Create User",
      }),
    );

    return;
  }

  /**
   * @description This method is used to Update any particular user.
   * @param id
   * @param changes
   * @returns
   */
  public async update(id: string | number, changes: Partial<User>): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.userApi.update(id, changes), {
        notificationsService: this.notifications,
        action: "Update User",
      }),
    );

    return;
  }

  /**
   * @description This method is used to Update any particular user's password.
   * @param changes
   * @returns
   */
  public async updatePassword(changes: Partial<passWordUpdate>): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.userApi.updatePassword(changes), {
        notificationsService: this.notifications,
        action: "Update Password",
      }),
    );

    return;
  }

  /**
   * @description This method is used to validate and update any particular user's phone number.
   * @param changes
   * @returns
   */
  public updatePhoneNumber = (opts: UserPhoneUpdate) => {
    return this.userApi.updatePhoneNumber(opts);
  };

  /**
   * @description This method is used to Archive any particular user.
   * @param id
   * @returns
   */
  public async delete(id: number): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.userApi.delete(id), {
        notificationsService: this.notifications,
        action: "Archive User",
      }),
    );

    return;
  }
}
