export class RCComingYear {
  id: number;
  title: string | null;
  objectives: string | null;
  strategicAlignment: string | null;
  councilsInvolved: string | null;
  rcApplicationId: number | null;
  bidpoolCost: number | null;

  public patchValue(value: Partial<RCComingYear>) {
    const { objectives, strategicAlignment, councilsInvolved, bidpoolCost } = value;

    if (objectives !== undefined) this.objectives = objectives;
    if (strategicAlignment !== undefined) this.strategicAlignment = strategicAlignment;
    if (councilsInvolved !== undefined) this.councilsInvolved = councilsInvolved;
    if (bidpoolCost !== undefined) this.bidpoolCost = bidpoolCost;
  }
}
