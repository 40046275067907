import { CommonModule } from "@angular/common";
import { Component, inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIcon } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { toInteger } from "lodash-es";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ToastrService } from "ngx-toastr";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { PublicFooterComponent } from "src/app/component/public-footer/public-footer.component";
import { TAPIListResult } from "src/app/contract/api.contract";
import { RolesEnum, UserPhoneUpdate } from "src/app/contract/user.contract";
import { RefAlliance } from "src/app/model/ref/ref-alliance.model";
import { UserRole } from "src/app/model/user/user-role.model";
import { User } from "src/app/model/user/user.model";
import { AllianceService } from "src/app/service/alliance.service";
import { LoadingService } from "src/app/service/loading.service";
import { passWordUpdate, UserService } from "src/app/service/user.service";
import { logger } from "src/app/util/logger.util";
import { passwordValidator } from "src/app/util/validator.util";

export type UserCreateOrUpdate = Partial<User> & {
  role?: {
    id: number;
  }[];
};

@Component({
  selector: "app-profile",
  standalone: true,
  imports: [
    AuthenticatedLayoutComponent,
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatIcon,
    MatButtonModule,
    FormsModule,
    PublicFooterComponent,
    MatGridListModule,
    MatTooltipModule,
  ],
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  private userService = inject(UserService);
  private loadingService = inject(LoadingService);
  private allianceService = inject(AllianceService);
  private modalService = inject(NgbModal);
  private formBuilder = inject(FormBuilder);
  private toastrService = inject(ToastrService);
  public formInvalid = false;
  public user: User = new User();
  public allianceListing: TAPIListResult<RefAlliance> = { count: 0, rows: [] };
  public showVerificationSection: boolean = false;
  public router = inject(Router);
  code: string[] = ["", "", "", "", "", ""];
  searchControl = new FormControl();

  @ViewChild("confirmation", { read: TemplateRef })
  private confirmModel: TemplateRef<unknown>;

  protected userForm = this.formBuilder.group({
    firstName: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),
    lastName: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),
    role: this.formBuilder.array([]),
    email: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required],
    }),
    mobile: new FormControl(""),
    organisationName: new FormControl(""),
    allianceId: this.formBuilder.control([] as string[] | null),
  });

  protected passwordForm = this.formBuilder.group(
    {
      oldPassword: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      newPassword: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, passwordValidator],
      }),
      cnfPassword: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      validator: this.passwordMatchValidator,
    },
  );

  protected phoneForm = this.formBuilder.group({
    phone: ["", Validators.required],
  });
  /**
   * @description
   * On initialization, fetches user data, loads necessary lists (organizations, alliances, roles, regions),
   * and pre-fills the form if user data is available.
   */
  async ngOnInit(): Promise<void> {
    const currentUser = this.userService.currentUser;
    this.initializeData(currentUser).finally(() => {
      this.setValidation();

      // this.userForm.valueChanges.subscribe(() => {
      // this.setValidation();
      // });
    });
  }

  /*
   * Enables/Disables certain required fields for admin user editing
   */
  private setValidation() {
    const roleData = (this.userForm.value.role || []) as { id?: number }[];

    const isAdmin =
      roleData.length === 1 && "id" in roleData[0] && roleData[0].id === RolesEnum.Admin;

    const toggleFields = ["allianceId"] as const;

    if (isAdmin) {
      toggleFields.forEach((field) => {
        this.userForm.get(field)?.clearValidators();
        this.userForm.get(field)?.disable();
        this.userForm.get(field)?.updateValueAndValidity({ onlySelf: true });
      });

      return;
    }

    toggleFields.forEach((field) => {
      this.userForm.get(field)?.setValidators([Validators.required]);
      this.userForm.get(field)?.enable();
      this.userForm.get(field)?.updateValueAndValidity({ onlySelf: true });
    });
  }

  /**
   * @description Initializes data by loading user details and other necessary data.
   */
  private async initializeData(user: User | null): Promise<void> {
    if (user && user.id) {
      await this.loadUserDetails(user.id);
    }

    await Promise.all([this.loadAlliances()]);
  }

  /**
   * @description Loads user details by ID and populates the form.
   * @param id The ID of the user to load.
   */
  private async loadUserDetails(id: string | number): Promise<void> {
    try {
      this.user = await this.userService.fetchSingleUser(id);
      this.preFillForm();
    } catch (error) {
      logger.error("Error loading user details", error);
    }
  }

  /**
   * @description
   * Opens a modal with the specified content.
   * @param content The content to display in the modal.
   */
  open(content: TemplateRef<unknown>) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "share-modal",
    });
  }

  /**
   * @description
   * Validator to check if the new password and confirmation password match.
   * @param formGroup The form group containing the password fields.
   */
  private passwordMatchValidator(formGroup: FormGroup) {
    const newPasswordControl = formGroup.get("newPassword");
    const cnfPasswordControl = formGroup.get("cnfPassword");

    if (!newPasswordControl || !cnfPasswordControl) {
      return;
    }

    const newPassword = newPasswordControl.value;
    const cnfPassword = cnfPasswordControl.value;

    if (newPassword !== cnfPassword) {
      cnfPasswordControl.setErrors({ mismatch: true });
    } else {
      cnfPasswordControl.setErrors(null);
    }
  }

  /**
   * @description
   * Resets the password form and closes the modal.
   */
  checkClose() {
    this.passwordForm.reset();
    this.formInvalid = false;
    this.modalService.dismissAll();
  }

  /**
   * @description
   * Submits the password update form if valid and calls the service to update the password.
   */
  onPasswordUpdate() {
    const body: passWordUpdate = {
      userId: this.user?.id,
      oldPassword: this.passwordForm.value.oldPassword,
      password: this.passwordForm.value.newPassword,
    };
    if (this.passwordForm.valid) {
      this.updateUserPassword(body);
    } else {
      this.toastrService.warning("Form Invalid");
      this.validateAllFormFields(this.passwordForm);
    }
  }

  /**
   * @description
   * Marks all form fields as touched to trigger validation messages.
   * @param formGroup The form group to validate.
   */
  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control?.markAsTouched({ onlySelf: true });
      }
    });
  }

  /**
   * @description
   * Updates the user's password using the provided payload.
   * @param payload The data for updating the password.
   */
  private async updateUserPassword(payload: Partial<passWordUpdate>): Promise<void> {
    if (this.user?.id) {
      try {
        await this.userService.updatePassword(payload);
        this.modalService.dismissAll();
        this.passwordForm.reset();
      } catch (error) {
        logger.error("Error updating user", error);
      }
    }
  }

  /**
   * @description
   * Loads the list of alliances from the service.
   */
  private async loadAlliances(): Promise<void> {
    try {
      this.allianceListing = await this.allianceService.fetchAlliance({ limit: 20 });
    } catch (error) {
      logger.error("Error loading alliances", error);
    }
  }

  /**
   * @description
   * Prefills the user form with the current user's details.
   */
  private preFillForm(): void {
    if (this.user) {
      const { firstName, lastName, email, organisationName, alliance, phone, roles } = this.user;

      const allAlliance = alliance?.map((data) => data.id.toString());
      this.userForm.setValue({
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        email: email ?? "",
        organisationName,
        allianceId: allAlliance || null,
        mobile: phone ?? "",
        role: [],
      });

      const roleFormArray = this.userForm.get("role") as FormArray;
      if (roles) {
        roles.forEach((role) => {
          roleFormArray.push(this.formBuilder.control(role));
        });
      }
    }
  }

  /**
   * @description
   * Returns the names of user roles as a comma-separated string.
   * @returns A string of role names.
   */
  public getUserRoles(): string {
    if (this.user.roles) {
      return this.user.roles.map((role) => role.name).join(", ");
    } else {
      return "";
    }
  }

  /**
   * @description
   * Submits the user profile form to update the user details.
   */
  public onSubmit(): void {
    const formValues = this.userForm.value;
    const userPayload: UserCreateOrUpdate = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      organisationName: formValues.organisationName,
      allianceId: formValues.allianceId || null,
      phone: formValues.mobile ?? "",
      role: formValues.role as UserRole[], // Type assertion
    };

    if (this.user?.id) {
      if (this.userForm.valid) {
        this.updateUser(userPayload);
      } else {
        this.toastrService.warning("Form is Invalid");
      }
    }
  }

  /**
   * @description
   * Updates the existing user with the given payload.
   * @param payload The data to update the user.
   */
  private async updateUser(payload: Partial<User>): Promise<void> {
    if (this.user?.id) {
      try {
        await this.userService.update(this.user.id, payload);
        this.router.navigate(["/"]);
        this.toastrService.success("Profile Updated");
      } catch (error) {
        logger.error("Error updating user", error);
      }
    }
  }

  /**
   * @description This method updates the user's phone number and manages verification. It checks form validity, sends an update request, and handles the response by showing success or error messages. If verification is required, it updates the user form and manages UI changes accordingly.
   * @returns
   */

  onPhoneNoUpdate(isVarificationCode = false) {
    if (this.user) {
      const body: UserPhoneUpdate = {
        userId: this.user.id.toString(),
        phone: this.phoneForm.value.phone ?? "",
      };

      if (isVarificationCode) {
        const codeStr = this.code.join("");
        if (/^\d{6}$/.test(codeStr) && codeStr.length === 6) {
          body.code = +codeStr;
        } else {
          this.toastrService.warning("Verification code must be exactly 6 digits.");
          return;
        }
      }

      if (!this.phoneForm.valid) {
        this.toastrService.warning("Please enter phone number");
        return;
      }

      this.loadingService
        .blockWithLoadingOverlayRx(this.userService.updatePhoneNumber(body))
        .subscribe({
          next: () => {
            if (body.code) {
              this.showVerificationSection = false;
              this.modalService.open(this.confirmModel);

              this.userForm.get("mobile")?.setValue(this.phoneForm.value.phone ?? null);
              this.phoneForm.reset();
              this.code = [];
            } else if (this.phoneForm.valid) {
              this.toastrService.success("Validation Code sent to your phone number");
              this.showVerificationSection = true;
              this.modalService.dismissAll();
            }
          },
          error: (error) => {
            this.showVerificationSection = false;
            this.phoneForm.reset();
            this.code = [];
            const errorMsg = error?.error?.detail?.message || "Unknown Error";
            this.toastrService.error(errorMsg);
            throw error;
          },
        });
    }
  }

  onVerifyCancel() {
    this.showVerificationSection = false;
    this.phoneForm.reset();
    this.code = [];
  }

  validateNumericInput(event: KeyboardEvent) {
    const allowedKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace"];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  onInputChange(event: Event, index: number) {
    const input = event.target as HTMLInputElement;
    const container = document.querySelector(".code-input-container");
    const nextInput = container?.querySelectorAll("input")[index + 1] as HTMLInputElement;

    if (input.value && nextInput) {
      nextInput.focus();
    }
  }

  onKeyDown(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement;
    const container = document.querySelector(".code-input-container");
    const previousInput = container?.querySelectorAll("input")[index - 1] as HTMLInputElement;

    if (event.key === "Backspace") {
      input.value = "";

      if (previousInput) {
        previousInput.focus();
      }
    }
  }

  cancel() {
    this.router.navigate(["/"]);
  }

  get passwordFormControl() {
    return this.passwordForm.controls;
  }
}
