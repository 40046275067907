export const environment = {
  production: true,
  region: "ap-southeast-2",
  endpoint: "https://api.dev.qwrap.digitalp.com.au",
  logLevel: "debug",
  buildId: process.env.NG_APP_BUILD_ID || "local",
  defaultCollectionLimit: 10,
  enableServiceWorker: false,
  enableSentry: false,
  timeZone: "Australia/Brisbane",
  defaultAuthEmail: "",
  defaultAuthPassword: "",
};
