import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { finalize, interval, map, NEVER, NextObserver, Observable, of, switchMap } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import {
  BidpoolApplicationUpdate,
  IUpdateBidpoolApplicationWorkflowStepInput,
  IUpdateBidpoolApplicationWorkflowStepOutput,
} from "../contract/bidpool.contract";
import { HasId } from "../contract/common.contract";
import { IQueryFilter, QueryResult } from "../model/api/query-filter.model";
import { BidpoolActivityLogs } from "../model/bidpool/bidpool-action-logs.model";
import { BidpoolApplication } from "../model/bidpool/bidpool-application.model";
import { BidpoolFeedback } from "../model/bidpool/bidpool-feedback.model";
import { JwtService } from "../service/jwt.service";
import { createUrl, createWSUrl, queryToParams, SocketData } from "../util/api.util";
import { GenericApi } from "./generic.api";

// Convenience Type
type T = BidpoolApplication;
type I = BidpoolApplication;

@Injectable({
  providedIn: "root",
})
export class BidpoolApi extends GenericApi<T> {
  public path = "bidpool-application";
  public httpClient = inject(HttpClient);
  private jwtService = inject(JwtService);

  create = (): Observable<T & HasId> => {
    return this.httpClient.post<T & HasId>(createUrl(this.path), {});
  };

  get = (id: number | string): Observable<T & HasId> => {
    return this.httpClient.get<T & HasId>(createUrl(this.path, id));
  };

  list = (query: IQueryFilter): Observable<QueryResult<T & HasId>> => {
    return this.httpClient.get<QueryResult<T & HasId>>(createUrl(this.path), {
      params: queryToParams(query),
    });
  };

  update = (
    id: number | string,
    changes: Partial<BidpoolApplicationUpdate>,
  ): Observable<T & HasId> => {
    return this.httpClient.put<T & HasId>(createUrl(this.path, id), changes);
  };

  updateFeedback = (changes: {
    feedback: string | null | undefined;
    bidpoolApplicationId: number;
  }): Observable<T & HasId> => {
    return this.httpClient.post<T & HasId>(createUrl(this.path, "feedback"), changes);
  };

  socket = <T = BidpoolApplication>(id: number | string): Observable<SocketData<T>> => {
    let connectionClosed = false;

    const webSocketSubject = webSocket<SocketData<T>>({
      url: createWSUrl(this.path, "socket", id),
      protocol: this.jwtService.getJWTString(),
    });

    const keepalive = interval(30000).subscribe({
      next: () => {
        if (connectionClosed) {
          keepalive.unsubscribe();
          return;
        }

        webSocketSubject.next({
          event: "keepalive",
          data: "ping",
        });
      },
    });

    return webSocketSubject.asObservable().pipe(
      switchMap((data: SocketData<T>) => {
        if (data.event === "keepalive") {
          if (data.data === "ping") {
            webSocketSubject.next({
              event: "keepalive",
              data: "pong",
            });
          }

          return NEVER;
        }

        return of(data);
      }),
      finalize(() => {
        connectionClosed = true;
      }),
    );
  };

  updateWorkflow = (
    id: number | string,
    options: IUpdateBidpoolApplicationWorkflowStepInput,
  ): Observable<IUpdateBidpoolApplicationWorkflowStepOutput> => {
    return this.httpClient.put<IUpdateBidpoolApplicationWorkflowStepOutput>(
      createUrl(this.path, id, "update-step"),
      options,
    );
  };

  listActivityLogs = (
    query: IQueryFilter,
  ): Observable<QueryResult<BidpoolActivityLogs & HasId>> => {
    return this.httpClient.get<QueryResult<BidpoolActivityLogs & HasId>>(
      createUrl(this.path, "activity-log"),
      {
        params: queryToParams(query),
      },
    );
  };

  listBidPoolFeedback = (query: IQueryFilter): Observable<QueryResult<BidpoolFeedback & HasId>> => {
    return this.httpClient.get<QueryResult<BidpoolFeedback & HasId>>(
      createUrl(this.path, "feedback"),
      {
        params: queryToParams(query),
      },
    );
  };
}
