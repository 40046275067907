<app-public-header></app-public-header>

<div class="welcome-wrap">
  <div class="otvhd">
    <h3>Two-Factor Authentication</h3>
    <p>
      QWRAP requires you to protect your account with <br />
      Two-Factor Authentication
    </p>
  </div>
  <form #form="ngForm" [formGroup]="loginForm" novalidate class="newsowb">
    <div class="auth-code-wrap otfombg">
      <p class="otdtl">
        Your 6-digit OTP has been sent to your mobile phone. <br />Please enter it below to sign in.
      </p>
      <input
        class="form-control"
        [ngClass]="{
          'is-invalid': (form.submitted || formControls.code.touched) && formControls.code.invalid,
          'is-valid': formControls.code.valid,
        }"
        id="code"
        type="number"
        matTooltip="Enter your authentication code"
        placeholder="Enter your authentication code"
        formControlName="code"
        aria-label="Enter authentication code"
      />
      <div
        *ngIf="
          formControls.code.invalid &&
          ((formControls.code.dirty && formControls.code.touched) || form.submitted)
        "
        class="invalid-feedback"
      >
        Authentication code is required
      </div>
    </div>
    <button type="button" class="btn-primary-new mt-3 w-100" (click)="onSubmit()">
      Validate your OTP
    </button>
  </form>
</div>

<app-public-footer></app-public-footer>
