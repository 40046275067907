<app-authenticated-layout>
  <div class="maintbcon">
    <h2>Users</h2>
    <div class="maintbs">
      <div class="mb-3 d-flex align-items-center justify-content-between">
        <button
          class="createbtn d-flex align-items-center justify-center gap-1"
          (click)="router.navigate(['user/create'])"
        >
          <img src="../../../../assets/images/plus.svg" alt="" /> Create Member
        </button>
        <div class="refresh-wrap d-flex justify-content-xs-center justify-content-end mt-2">
          <div class="text-side">
            <input type="text" placeholder="Search..." #searchInput />
          </div>
          <div class="refresh-btn">
            <button type="button" class="d-flex text-white pe-none">
              <img src="../../../assets/images/searchicon.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <app-table
        [tableColumns]="userListingTableColumns"
        [fetchDataMethod]="userListFetchDataMethod"
        [usePagination]="true"
        (actionTriggered)="onTableAction($event)"
      />
    </div>
  </div>
</app-authenticated-layout>
