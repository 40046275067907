import { Component, inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { BidpoolService } from "src/app/service/bidpool.service";
import { LoadingService } from "src/app/service/loading.service";
import { AccessName, UserService } from "src/app/service/user.service";
import { CreateBidpoolChecklistComponent } from "../create-bidpool-checklist/create-bidpool-checklist.component";

@Component({
  selector: "app-create-bidpool-button",
  standalone: true,
  imports: [MatIconModule, MatDialogModule, CreateBidpoolChecklistComponent],
  templateUrl: "./create-bidpool-button.component.html",
})
export class CreateBidpoolButtonComponent implements OnInit, OnDestroy {
  private dialog = inject(MatDialog);
  private loadingService = inject(LoadingService);
  private router = inject(Router);
  private userService = inject(UserService);
  private bidpoolService = inject(BidpoolService);
  private subscriptions: Subscription[] = [];

  protected hasAccess = false;
  protected isChecklistCompleted = false;

  @ViewChild("createBidpoolApplicationModal") createBidpoolApplicationModalRef;

  ngOnInit(): void {
    this.hasAccess = this.userService.hasAccess({
      accessName: AccessName.CreateBidpoolApplication,
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((e) => e.unsubscribe());
    }
  }

  protected openModal() {
    const dialogRef = this.dialog.open(this.createBidpoolApplicationModalRef);
    this.subscriptions.push(
      dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
        if (result) {
          this.createBidpoolApplication();
        }
      }),
    );
  }

  protected updateIsChecklistCompleted(isChecklistCompleted: boolean): void {
    this.isChecklistCompleted = isChecklistCompleted;
  }

  private async createBidpoolApplication() {
    await this.loadingService.blockWithLoadingOverlayPromise(async () => {
      const createdBidpoolApplication = await this.bidpoolService.createBidpoolApplication();
      this.router.navigate(["/bidpool", createdBidpoolApplication.id], {
        queryParams: { mode: "edit" },
      });
    });
  }
}
