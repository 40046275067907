import { CommonModule } from "@angular/common";
import {
  Component,
  inject,
  TemplateRef,
  ViewChild,
  OnInit,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbDatepicker, NgbDateStruct, NgbModal, NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { CalendarEvent, CalendarView } from "angular-calendar";
import { FlatpickrModule } from "angularx-flatpickr";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { ToastrService } from "ngx-toastr";
import { debounceTime, distinctUntilChanged, fromEvent, map } from "rxjs";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { TAPIListResult } from "src/app/contract/api.contract";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { Calender } from "src/app/model/calender/calender.model";
import { CalenderService } from "src/app/service/calender.service";
import { logger } from "src/app/util/logger.util";
import { CalendarConfigModule } from "./calender.module";

@Component({
  selector: "app-calendar",
  standalone: true,
  imports: [
    AuthenticatedLayoutComponent,
    CommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepicker,
    CalendarConfigModule,
    FlatpickrModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatOptionModule,
    MatTooltipModule,
  ],
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
})
export class CalendarComponent implements OnInit, AfterViewInit {
  protected router = inject(Router);
  private formBuilder = inject(FormBuilder);
  protected calenderService = inject(CalenderService);
  protected activatedRoute = inject(ActivatedRoute);
  readonly dialog = inject(MatDialog);
  private toastrService = inject(ToastrService);

  view: CalendarView = CalendarView.Month;
  viewDate = new Date();
  CalendarView = CalendarView;
  activeDayIsOpen: boolean = true;
  calendarEvents: CalendarEvent[] = [];
  private debounceInMs = 1000;

  @ViewChild("event", { read: TemplateRef })
  private eventModel: TemplateRef<unknown>;
  eventListing: TAPIListResult<Calender> = { count: 0, rows: [] };
  query: IQueryFilter = new IQueryFilter({ limit: 100 });

  @ViewChild("searchInput") searchInput!: ElementRef;
  @ViewChild("eventDetails", { read: TemplateRef })
  private eventDetailModel: TemplateRef<unknown>;

  protected eventForm = this.formBuilder.group({
    title: ["", Validators.required],
    location: ["", Validators.required],
    description: ["", Validators.required],
    startDate: ["", Validators.required],
    startTime: ["12:00", Validators.required],
  });
  selectedEvent;

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((query) => {
      if (query.view) {
        this.view = query.view;
      }

      if (query.date) {
        this.viewDate = new Date(query.date);
      }

      this.listEvents();
    });
  }

  /**
   * @description Loads the list of user roles.
   */
  private async listEvents(): Promise<void> {
    try {
      const viewDateRange = this.getViewDateRange();
      this.query.filter.start = viewDateRange.start.getTime().toString();
      this.query.filter.end = viewDateRange.end.getTime().toString();
      this.eventListing = await this.calenderService.listEvent(this.query);
      this.calendarEvents = this.eventListing.rows.map((event) => {
        const eventDateTime = new Date(`${event.date}T${event.time}`);
        return {
          title: event.title,
          start: eventDateTime,
          time: eventDateTime,
          description: event.description,
          location: event.location,
        };
      });
    } catch (error) {
      logger.error("Error loading events", error);
    }
  }

  ngAfterViewInit() {
    this.setupSearchDebounce();
  }

  /**
   * @description Sets up debounce for the search input field
   */
  setupSearchDebounce() {
    fromEvent(this.searchInput.nativeElement, "input")
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(this.debounceInMs),
        distinctUntilChanged(),
      )
      .subscribe((searchTerm: string) => {
        this.onSearchClick(searchTerm);
      });
  }

  /**
   * @description Updates the search query with the entered term and refreshes the event list.
   * This method sets the `search` property in the `query.filter` object if a search term is provided,
   * and then calls `listEvents()` to update the displayed list of events.
   */
  onSearchClick(searchTerm: string) {
    if (searchTerm) {
      this.query.filter.search = searchTerm;
    } else {
      delete this.query.filter.search;
    }
    this.listEvents();
  }

  /**
   * @description Returns a date range which represents the start and end date of the selected view
   * @returns {{start:Date, end:Date}}
   */
  getViewDateRange(): { start: Date; end: Date } {
    let start: Date, end: Date;
    switch (this.view) {
      case CalendarView.Month:
        (start = startOfMonth(this.viewDate)), (end = endOfMonth(this.viewDate));
        break;
      case CalendarView.Week:
        (start = startOfWeek(this.viewDate)), (end = endOfWeek(this.viewDate));
        break;
      case CalendarView.Day:
        (start = startOfDay(this.viewDate)), (end = endOfDay(this.viewDate));
        break;
    }

    return {
      start,
      end,
    };
  }

  /**
   * @description Sets the current calendar view and triggers date change actions
   * @param {CalendarView} view - The calendar view to be set (Month, Week, Day)
   */
  setView(view: CalendarView) {
    this.view = view;
    this.onViewDateChange();
  }

  /**
   * @description Closes the open month view day, used for collapsing the day view in the calendar
   */
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  /**
   * @description Updates the view date in the router's query parameters when the view changes
   */
  onViewDateChange() {
    const destination = this.router.url.replace(/\?.*$/, "");
    this.router.navigate([destination], {
      queryParams: {
        view: this.view,
        date: this.viewDate,
      },
    });
  }

  /**
   * @description Similar to onViewDateChange, updates the view date in the router's query parameters
   */
  onCalenderViewDateChange() {
    const destination = this.router.url.replace(/\?.*$/, "");
    this.router.navigate([destination], {
      queryParams: {
        view: this.view,
        date: this.viewDate,
      },
    });
  }

  /**
   * @description Converts a Date object to an NgbDateStruct used in the datepicker
   * @param {Date} date - The date to be converted
   * @returns {NgbDateStruct} The NgbDateStruct representation of the date
   */
  dateToStruct(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  /**
   * @description Converts an NgbDateStruct to a Date object
   * @param {NgbDateStruct} struct - The NgbDateStruct to be converted
   * @returns {Date} The Date object representation of the struct
   */

  structToDate(struct: NgbDateStruct): Date {
    return new Date(struct.year, struct.month - 1, struct.day);
  }

  /**
   * @description Opens a modal dialog with the provided template for creating or editing events
   * @param {TemplateRef<unknown>} event - The template reference for the event modal
   */
  openEventModel(event: TemplateRef<unknown>) {
    this.dialog.open(event, {
      width: "700px",
    });
  }

  /**
   * @description Handles the click event on an hour segment in the calendar and opens the event modal
   * @param {any} evt - The event object containing details of the clicked hour segment
   */
  hourSegmentClicked(evt) {
    this.eventForm.patchValue({
      startDate: evt.date,
      startTime: "12:00",
    });
    this.openEventModel(this.eventModel);
  }

  openEventDetails(event: CalendarEvent<any>) {
    this.selectedEvent = event;
    this.dialog.open(this.eventDetailModel, { width: "700px" });
  }

  toLocalDate(date: string | Date | null | undefined): string {
    if (!date) return "";
    return new Date(date).toISOString();
  }

  /**
   * @description Adds a new event based on the form data, resets the form, and refreshes the event list
   */
  addEvent() {
    if (!this.eventForm.valid) {
      this.eventForm.markAllAsTouched();
      this.toastrService.warning("Please fill in all required fields");
      return;
    }
    const formValues = this.eventForm.value;
    const payload = {
      title: formValues.title ?? "",
      // Ensures the date the user intended to create the event is found, and timezone difference don't distort that data
      date: this.toLocalDate(formValues.startDate) ?? "",
      time: formValues.startTime?.toString() ?? "",
      location: formValues.location ?? "",
      description: formValues.description ?? "",
    };

    this.calenderService
      .createEvent(payload)
      .then(() => {
        this.listEvents();
        this.eventForm.reset();
        this.dialog.closeAll();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  get eventFormControl() {
    return this.eventForm.controls;
  }
}
