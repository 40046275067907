import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import {
  HasExternalId,
  HasExternalValue,
  HasId,
  HasUniqueServiceName,
} from "../contract/common.contract";
import { IQueryFilter, QueryResult } from "../model/api/query-filter.model";
import { createUrl, queryToParams } from "../util/api.util";

export type HasIdOrExternalValueOrExternalIdOrServiceName<I> =
  | HasId
  | HasExternalValue<I>
  | HasExternalId<I>
  | HasUniqueServiceName<I>;

@Injectable()
export abstract class GenericApi<T, I = string> {
  abstract path: string;
  public httpClient = inject(HttpClient);

  get = (id: number | string): Observable<T & HasIdOrExternalValueOrExternalIdOrServiceName<I>> => {
    return this.httpClient.get<T & HasIdOrExternalValueOrExternalIdOrServiceName<I>>(
      createUrl(this.path, id),
    );
  };

  list = (
    query: IQueryFilter,
  ): Observable<QueryResult<T & HasIdOrExternalValueOrExternalIdOrServiceName<I>>> => {
    return this.httpClient.get<QueryResult<T & HasIdOrExternalValueOrExternalIdOrServiceName<I>>>(
      createUrl(this.path),
      {
        params: queryToParams(query),
      },
    );
  };

  create = (model: Partial<T>): Observable<T & HasId> => {
    return this.httpClient.post<T & HasId>(createUrl(this.path), model);
  };

  update = (id: number | string, model: Partial<T>): Observable<T & HasId> => {
    return this.httpClient.put<T & HasId>(createUrl(this.path, id), model);
  };

  delete = (id: number | string): Observable<{ success: boolean; deleted: number }> => {
    return this.httpClient.delete<{ success: boolean; deleted: number }>(createUrl(this.path, id));
  };
}
