export enum WorkflowStepsEnum {
  Draft = 1,
  R1 = 2,
  R2 = 3,
  AWG = 4,
  APSC = 5,
  Approved = 6,
  Completed = 7,
  Contributor = 8,
}

export enum RCWorkflowStepsEnum {
  Draft = 9,
  R1 = 10,
  R2 = 11,
  AWG = 12,
  Approved = 13,
  Completed = 14,
}
