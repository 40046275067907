import { Component, inject } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { NavItem, navItems } from "src/app/constants/nav";
import { AuthService } from "src/app/service/auth.service";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "app-authenticated-layout",
  standalone: true,
  imports: [RouterLink, RouterLinkActive, MatIconModule, MatTooltipModule],
  templateUrl: "./authenticated-layout.component.html",
  styleUrl: "./authenticated-layout.component.scss",
})
export class AuthenticatedLayoutComponent {
  private authService = inject(AuthService);
  private router = inject(Router);
  protected userService = inject(UserService);

  protected userNavItems: NavItem[] = [];

  constructor() {
    this.userNavItems = navItems.filter((navItem) => {
      if (!this.userService.currentUser) {
        return false;
      }
      if (navItem.roles && navItem.roles.length) {
        return this.userService.currentUser.hasSomeRoles(navItem.roles);
      }
      return true;
    });
  }

  protected logout(): void {
    this.authService.logout();
    this.router.navigateByUrl("/login");
  }
}
