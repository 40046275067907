import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HasId } from "../contract/common.contract";
import { IQueryFilter, QueryResult } from "../model/api/query-filter.model";
import { BidpoolDocument } from "../model/bidpool/bidool-document.model";
import { createUrl, queryToParams } from "../util/api.util";
import { GenericApi } from "./generic.api";

// Convenience Type
type T = BidpoolDocument;

@Injectable({
  providedIn: "root",
})
export class BidpoolDocumentApi extends GenericApi<T> {
  path = "bidpool-document";

  public httpClient = inject(HttpClient);

  list = (query: IQueryFilter): Observable<QueryResult<T & HasId>> => {
    return this.httpClient.get<QueryResult<T & HasId>>(createUrl(this.path), {
      params: queryToParams(query),
    });
  };

  create = (model: Partial<T>): Observable<T & HasId> => {
    return this.httpClient.post<T & HasId>(createUrl(this.path), model);
  };
}
