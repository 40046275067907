import { Type } from "class-transformer";
import { User } from "../user/user.model";

export class BidpoolActivityLogs {
  id: number;
  createdAt: string;
  @Type(() => User)
  user: User;

  auditAction: { name: string };
  objectType: { name: string };
}
