import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PublicFooterComponent } from "src/app/component/public-footer/public-footer.component";
import { PublicHeaderComponent } from "src/app/component/public-header/public-header.component";
import { AuthService } from "src/app/service/auth.service";
import { LoadingService } from "src/app/service/loading.service";
import { emailValidator } from "src/app/util/validator.util";

@Component({
  selector: "app-forgot-password",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PublicHeaderComponent,
    PublicFooterComponent,
    MatTooltipModule,
  ],
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {
  private loadingService = inject(LoadingService);
  private authService = inject(AuthService);
  private formBuilder = inject(FormBuilder);
  private toastrService = inject(ToastrService);

  public forgotPasswordForm: FormGroup;
  public isRequestSubmitted: boolean = false;

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, emailValidator],
      }),
    });
  }

  onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      this.toastrService.warning("Please enter you email address");
      return;
    }

    this.loadingService
      .blockWithLoadingOverlayRx(this.authService.forgot(this.forgotPasswordForm.value))
      .subscribe({
        next: () => (this.isRequestSubmitted = true),
      });
  }

  get formControls() {
    return this.forgotPasswordForm.controls;
  }
}
