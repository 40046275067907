<app-authenticated-layout>
  <div class="newform-con newinputs">
    <div class="user-form-1">
      <div class="form-innerdatas paet-a">
        <h2>Personal</h2>
        <form [formGroup]="userForm">
          <div class="">
            <mat-form-field appearance="fill">
              <mat-label>First name</mat-label>
              <input matInput placeholder="firstname" formControlName="name" />
            </mat-form-field>
          </div>
          <div class="">
            <mat-form-field appearance="fill">
              <mat-label>Last name</mat-label>
              <input matInput placeholder="lastname" formControlName="lastName" />
            </mat-form-field>
          </div>

          <div class="">
            <mat-form-field appearance="fill">
              <mat-label>Organisation</mat-label>
              <input matInput placeholder="Organisation" formControlName="organisationName" />
            </mat-form-field>
          </div>

          <div class="position-relative">
            <mat-form-field appearance="fill">
              <mat-label for="role">Role</mat-label>
              <input matInput placeholder="role" id="role" [value]="getUserRoles()" readonly />
              <span class="edbtnnew"
                ><button type="button" (click)="toggleRoleEditing()" aria-label="Edit role">
                  <img src="../../../../assets/images/editIcon.svg" alt="" /></button
              ></span>
            </mat-form-field>
          </div>

          <div class="">
            <mat-form-field appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput placeholder="email" formControlName="email" />
            </mat-form-field>
          </div>

          <!-- user can not edit phone number if they want to edit their own phone number thay can do in profile -->
          <!-- <div class="col-6">
            <mat-form-field appearance="fill">
              <mat-label>Mobile Phone</mat-label>
              <input matInput placeholder="phone" formControlName="mobile" />
            </mat-form-field>
          </div> -->

          <div class="">
            <mat-form-field appearance="fill">
              <mat-label>Select Alliance</mat-label>
              <mat-select multiple formControlName="allianceId">
                <mat-option value="" disabled>Select alliance</mat-option>
                <mat-option
                  *ngFor="let alliance of allianceListing.rows"
                  [value]="alliance.id.toString()"
                >
                  {{ alliance.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="d-flex gap-3 align-items-center justify-content-between">
            <div class="d-flex gap-3 align-items-center">
              <button class="createbtn" *ngIf="showReset" (click)="open(password)">
                Reset password
              </button>
              <button class="savebtn" (click)="submit()" type="submit">Save</button>
              <button class="secondbtn" type="button" (click)="onCancel()">Cancel</button>
            </div>
            <button *ngIf="userId" class="btn btn-danger" type="button" (click)="archiveUser()">
              Archive
            </button>
          </div>
        </form>
      </div>
      <div class="form-innerdatas paet-b" *ngIf="isEditingRoles">
        <app-user-role
          (rolesChanged)="handleRoleChange($event)"
          [existRole]="userRoles"
        ></app-user-role>
      </div>
    </div>
  </div>
  <app-public-footer></app-public-footer>
</app-authenticated-layout>
<ng-template #password let-modal>
  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Edit Password</h1>
    <button
      mat-icon-button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      (click)="checkClose()"
    ></button>
  </div>
  <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <div class="modal-body newinputs newformmodal">
      <mat-form-field appearance="fill" class="">
        <mat-label>Old Password</mat-label>
        <input matInput type="password" id="oldPassword" formControlName="oldPassword" />
        <mat-error
          *ngIf="
            passwordFormControl.oldPassword.hasError('required') &&
            passwordFormControl.oldPassword.dirty &&
            passwordFormControl.oldPassword.touched
          "
        >
          Old Password is required.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="">
        <mat-label>New Password</mat-label>
        <input matInput type="password" id="newPassword" formControlName="newPassword" />
        <mat-error
          *ngIf="
            passwordFormControl.newPassword.hasError('required') &&
            passwordFormControl.newPassword.dirty &&
            passwordFormControl.newPassword.touched
          "
        >
          Password must be at least 8 characters long and contain at least one uppercase letter and
          one symbol.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="">
        <mat-label>Confirm Password</mat-label>
        <input matInput type="password" id="cnfPassword" formControlName="cnfPassword" />
        <mat-error
          *ngIf="
            passwordFormControl.cnfPassword.hasError('required') &&
            passwordFormControl.cnfPassword.dirty &&
            passwordFormControl.cnfPassword.touched
          "
        >
          The passwords don't match.
        </mat-error>
        <mat-error
          *ngIf="
            passwordForm.get('cnfPassword')?.hasError('mismatch') &&
            passwordForm.get('cnfPassword')?.touched
          "
        >
          Passwords do not match.
        </mat-error>
      </mat-form-field>
    </div>

    <div class="modal-footer">
      <button class="secondbtn" (click)="checkClose()">Cancel</button>
      <button class="createbtn" type="submit">Update</button>
    </div>
  </form>
</ng-template>
