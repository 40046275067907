import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PublicFooterComponent } from "src/app/component/public-footer/public-footer.component";
import { PublicHeaderComponent } from "src/app/component/public-header/public-header.component";
import { AuthService } from "src/app/service/auth.service";
import { LoadingService } from "src/app/service/loading.service";
import { passwordValidator, validateConfirmPassword } from "src/app/util/validator.util";

@Component({
  selector: "app-reset-password",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PublicHeaderComponent,
    PublicFooterComponent,
    MatTooltipModule
  ],
  templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent implements OnInit {
  private loadingService = inject(LoadingService);
  private formBuilder = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private authService = inject(AuthService);
  private toastrService = inject(ToastrService);

  public resetPasswordForm: FormGroup;
  private resetToken: string;
  public isSetPassword: boolean;

  ngOnInit(): void {
    const { token } = this.route.snapshot.queryParams;
    const { type } = this.route.snapshot.data;

    if (type == "set-password") {
      this.isSetPassword = true;
    }
    this.resetToken = token;

    this.resetPasswordForm = this.formBuilder.group({
      password: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, passwordValidator],
      }),
      confirmPassword: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  onSubmit() {
    if (!validateConfirmPassword(this.formControls.confirmPassword)) {
      this.toastrService.warning(
        "The passwords you entered are not identical. Please try again and enter your new password",
      );
      this.resetPasswordForm.reset();
      return;
    }

    if (this.resetPasswordForm.invalid) {
      this.toastrService.warning("Please enter a new password");
      return;
    }

    const callApi = this.isSetPassword
      ? this.authService.setPassword({
          password: this.resetPasswordForm.value.password,
          resetToken: this.resetToken,
        })
      : this.authService.reset({
          password: this.resetPasswordForm.value.password,
          resetToken: this.resetToken,
        });
    this.loadingService.blockWithLoadingOverlayRx(callApi).subscribe({
      next: (res) => {
        if (this.isSetPassword) {
          if ("id" in res) {
            this.router.navigate(["/verify-phone"], { queryParams: { userId: res.id } });
          }
        } else {
          this.router.navigate(["/login"]);
        }
      },
    });
  }

  get formControls() {
    return this.resetPasswordForm.controls;
  }
}
