import { EventEmitter, Injectable } from "@angular/core";
import { Observable, timer } from "rxjs";
import { finalize, switchMap, take } from "rxjs/operators";
import { quickGuid } from "../util/quick-guid";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  /* For the purpose of tracking threads which are locking the input and showing a loading screen */
  private threads: String[] = [];
  /* Global isLoading value emitted to show or hide the loading overlay */
  public isLoadingChange = new EventEmitter<boolean>();

  blockWithLoadingOverlayPromise = async (func: () => Promise<any>) => {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (this.threads.length === 0) {
          this.isLoadingChange.emit(true);
        }
        const threadId = quickGuid();
        this.threads.push(threadId);
        try {
          await func();
        } catch (error) {
          throw error;
        } finally {
          this.threads.splice(this.threads.indexOf(threadId), 1);
          if (this.threads.length === 0) {
            this.isLoadingChange.emit(false);
          }

          resolve({});
        }
      }, 0);
    });
  };

  blockWithLoadingOverlayRx = <T>(asyncEntity: Observable<T>): Observable<T> => {
    if (this.threads.length === 0) {
      this.isLoadingChange.emit(true);
    }
    const threadId = quickGuid();
    this.threads.push(threadId);

    return timer(0).pipe(
      switchMap(() =>
        asyncEntity.pipe(
          take(1),
          finalize(() => {
            const index = this.threads.indexOf(threadId);
            if (index !== -1) {
              this.threads.splice(index, 1);
            }
            if (this.threads.length === 0) {
              this.isLoadingChange.emit(false);
            }
          }),
        ),
      ),
    );
  };
}
