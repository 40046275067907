import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router, RouterModule } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PublicFooterComponent } from "src/app/component/public-footer/public-footer.component";
import { PublicHeaderComponent } from "src/app/component/public-header/public-header.component";
import { IJWTPayload, IJWTPayloadCode } from "src/app/contract/auth.contract";
import { AuthService } from "src/app/service/auth.service";
import { JwtService } from "src/app/service/jwt.service";
import { LoadingService } from "src/app/service/loading.service";
import { UserService } from "src/app/service/user.service";
import { logger } from "src/app/util/logger.util";
import { emailValidator } from "src/app/util/validator.util";

const className = "LoginComponent";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PublicHeaderComponent,
    PublicFooterComponent,
    MatTooltipModule,
  ],
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  private loadingService = inject(LoadingService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private userService = inject(UserService);
  private jwtService = inject(JwtService);
  private fb = inject(FormBuilder);
  private toastrService = inject(ToastrService);

  public loginForm: FormGroup;

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required, emailValidator],
      }),
      password: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
      rememberMe: new FormControl(false, { nonNullable: true }),
    });
  }

  onSubmit() {
    const signature = className + ".onSubmit: ";
    if (this.loginForm.invalid) {
      this.toastrService.warning("Please enter your credentials");
      return;
    }
    this.authService.setAuthValues({
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value,
    });
    this.loadingService
      .blockWithLoadingOverlayRx(this.authService.authenticate(this.loginForm.value))
      .subscribe({
        next: async data => {
          if ("status" in data && data.status) {
            this.on2faRequest();
          }

          if ('tokenType' in data) {
            this.onAuthSuccess(data);
          }
        },
        error: (error) => {
          this.loginForm.reset();
          const errorMsg = error?.error?.detail?.message || 'Unknown Error';
          this.toastrService.error(errorMsg);
          throw error;
        },
      });
  }

  private async on2faRequest() {
    this.router.navigate(["/verify-thats-you"]);
    this.toastrService.success("Verification Code sent to your phone number");
    return;
  }

  private async onAuthSuccess(data: IJWTPayload) {
    const signature = className + ".onAuthSuccess: ";

    if (!this.jwtService.saveJWTData(data) || !this.authService.isAuthenticated()) {
      this.jwtService.removeJWTData();

      throw "Error Saving JWT Payload";
    }

    const userId = this.jwtService.currentJwtPayload$.value?.user.id;
    if (!userId) throw new Error("User ID not found in JWT Payload");

    await this.userService.setUser(userId);
    this.toastrService.success("Login Successful");
    logger.silly(signature + `Navigating to Url[${this.userService.defaultLandingPage}]`);
    this.router.navigateByUrl(this.userService.defaultLandingPage);
  }

  get formControls() {
    return this.loginForm.controls;
  }
}
