<app-authenticated-layout>
  <div class="newform-con newinputs">
    <div class="user-form-2">
      <div class="form-innerdatas paet-a">
        <form [formGroup]="userForm">
          <div class="row">
            <div class="col-4">
              <mat-form-field appearance="fill">
                <mat-label>First name</mat-label>
                <input matInput placeholder="first name" formControlName="firstName" />
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field appearance="fill">
                <mat-label>Last name</mat-label>
                <input matInput placeholder="last name" formControlName="lastName" />
              </mat-form-field>
            </div>

            <div class="col-4">
              <mat-form-field appearance="fill">
                <mat-label>Organisation</mat-label>
                <input matInput placeholder="Organisation" formControlName="organisationName" />
              </mat-form-field>
            </div>
            <div class="col-4">
              <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Enter email" formControlName="email" />
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>Role</mat-label>
                <textarea matInput id="role" [value]="getUserRoles()" readonly></textarea>
              </mat-form-field>
            </div>
            <div class="col-4 position-relative">
              <mat-form-field appearance="fill">
                <mat-label>Mobile phone</mat-label>
                <input matInput placeholder="Enter phone number" formControlName="mobile" />
                <span class="edbtnnew">
                  <button type="button" aria-label="Edit role" (click)="open(phn)">
                    <img src="../../../../assets/images/editIcon.svg" alt="" />
                  </button>
                </span>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill">
                <mat-label>Alliance</mat-label>
                <mat-select multiple formControlName="allianceId">
                  <mat-option value="" disabled>Select alliance</mat-option>
                  <mat-option
                    *ngFor="let alliance of allianceListing.rows"
                    [value]="alliance.id.toString()"
                  >
                    {{ alliance.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="d-flex gap-3 align-items-center justify-content-center mt-3">
            <button class="createbtn" (click)="open(password)">Reset password</button>
            <button class="savebtn" type="submit" (click)="onSubmit()">Save</button>
            <button class="secondbtn" type="button" (click)="cancel()">Cancel</button>
          </div>
        </form>
      </div>
      <div class="form-innerdatas paet-b" *ngIf="showVerificationSection">
        <div class="twowrpauth my-5">
          <div class="inrblck">
            <h5 class="text-center">Two-Factor Authentication</h5>
            <div class="code-input-container">
              <mat-form-field
                appearance="outline"
                *ngFor="let num of [0, 1, 2, 3, 4, 5]; let i = index"
              >
                <input
                  type="text"
                  matInput
                  maxlength="1"
                  [(ngModel)]="code[num]"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  (keypress)="validateNumericInput($event)"
                  (keydown)="onKeyDown($event, i)"
                  (input)="onInputChange($event, i)"
                />
              </mat-form-field>
            </div>
            <p class="twopergh text-center">
              A message with a verification code has been sent to your devices. Please enter the
              code to continue.
            </p>
            <div class="d-flex align-items-center justify-content-center gap-3">
              <button type="button" class="secondbtn" (click)="onVerifyCancel()">Cancel</button>
              <button type="submit" class="savebtn" (click)="onPhoneNoUpdate(true)">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-public-footer></app-public-footer>
</app-authenticated-layout>
<!-- right modal -->
<ng-template #password let-modal>
  <div class="modal-header">
    <h1 class="modal-title fs-5">Edit Password.</h1>
    <button
      mat-icon-button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      (click)="checkClose()"
    ></button>
  </div>
  <form [formGroup]="passwordForm">
    <div class="modal-body newinputs newformmodal">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Old Password</mat-label>
          <input matInput type="password" formControlName="oldPassword" />
          <mat-error
            *ngIf="
              passwordFormControl.oldPassword.hasError('required') &&
              passwordFormControl.oldPassword.dirty &&
              passwordFormControl.oldPassword.touched
            "
          >
            Old Password is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>New Password</mat-label>
          <input matInput type="password" formControlName="newPassword" />
          <mat-error
            *ngIf="
              passwordFormControl.newPassword.hasError('required') &&
              passwordFormControl.newPassword.dirty &&
              passwordFormControl.newPassword.touched
            "
          >
            Password must be at least 8 characters long and contain at least one uppercase letter
            and one symbol.
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Confirm Password</mat-label>
          <input matInput type="password" formControlName="cnfPassword" />
          <mat-error
            *ngIf="
              passwordFormControl.cnfPassword.hasError('required') &&
              passwordFormControl.cnfPassword.dirty &&
              passwordFormControl.cnfPassword.touched
            "
          >
            The passwords don't match.
          </mat-error>
          <mat-error
            *ngIf="
              passwordForm.get('cnfPassword')?.hasError('mismatch') &&
              passwordForm.get('cnfPassword')?.touched
            "
          >
            Passwords do not match.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="modal-footer">
      <button class="secondbtn" (click)="checkClose()">Cancel</button>
      <button class="createbtn" type="submit" (click)="onPasswordUpdate()">Update</button>
    </div>
  </form>
</ng-template>

<ng-template #phn let-modal>
  <div class="modal-header">
    <h1 class="modal-title fs-5">Edit Phone Number</h1>
    <button
      mat-icon-button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      (click)="checkClose()"
    ></button>
  </div>
  <form [formGroup]="phoneForm">
    <div class="modal-body modnf newinputs">
      <mat-form-field appearance="fill">
        <mat-label>New Phone Number</mat-label>
        <input matInput type="tel" formControlName="phone" placeholder="Enter new phone number" />
      </mat-form-field>
    </div>
    <div class="modal-footer">
      <button class="secondbtn" (click)="checkClose()">Cancel</button>
      <button class="savebtn" type="submit" (click)="onPhoneNoUpdate()">Update</button>
    </div>
  </form>
</ng-template>

<ng-template #confirmation let-modal>
  <div class="modal-header border-0">
    <button
      mat-icon-button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      (click)="checkClose()"
    ></button>
  </div>
  <div class="modal-body modnf newinputs">
    <h4 class="text-center mb-5 mt-3">
      Your phone number has been <br />
      saved successfully
    </h4>
  </div>
</ng-template>
