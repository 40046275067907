import { CommonModule, CurrencyPipe, DatePipe, ViewportScroller } from "@angular/common";
import { AsyncPipe } from "@angular/common";
import {
  afterRender,
  Component,
  inject,
  Input,
  numberAttribute,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule, MatSuffix } from "@angular/material/input";
import { MatPaginatorModule, PageEvent } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { plainToInstance } from "class-transformer";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { Observable, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from "rxjs/operators";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { CreateBidpoolChecklistComponent } from "src/app/component/bidpool/create-bidpool-checklist/create-bidpool-checklist.component";
import { DatePickerComponent } from "src/app/component/date-picker/date-picker.component";
import { MonthYearPickerComponent } from "src/app/component/month-year-picker/month-year-picker.component";
import { TableComponent } from "src/app/component/table/table.component";
import { TAPIListResult } from "src/app/contract/api.contract";
import {
  BidpoolApplicationUpdate,
  BidpoolRequestedAmountRequiringAPSC,
} from "src/app/contract/bidpool.contract";
import { RolesEnum } from "src/app/contract/user.contract";
import { WorkflowStepsEnum } from "src/app/contract/workflowStep.contract";
import { BidpoolFormSpec } from "src/app/interface/bidpool-form.interface";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { BidpoolDocument } from "src/app/model/bidpool/bidool-document.model";
import { BidpoolApplication } from "src/app/model/bidpool/bidpool-application.model";
import {
  BidpoolContribution,
  BPRequestContributorName,
  OtherOrgsContributorName,
} from "src/app/model/bidpool/bidpool-contribution.model";
import { BidpoolFeedback } from "src/app/model/bidpool/bidpool-feedback.model";
import {
  BidpoolMilestoneReport,
  MyErrorStateMatcher,
} from "src/app/model/bidpool/bidpool-milestone-reporting.model";
import {
  BidpoolMilestone,
  milestoneDateFormat,
} from "src/app/model/bidpool/bidpool-milestone.model";
import { BidpoolUser } from "src/app/model/bidpool/bidpool-user.model";
import { User } from "src/app/model/user/user.model";
import { BidpoolMilestoneReportingService } from "src/app/service/bidpool-milestone-reporting.service";
import { BidpoolService } from "src/app/service/bidpool.service";
import { BidpoolDocumentService } from "src/app/service/bidpooldocument.service";
import { FileUploadService } from "src/app/service/fileUpload.service";
import { LoadingService } from "src/app/service/loading.service";
import { AccessName, UserService } from "src/app/service/user.service";
import { toLocalDate } from "src/app/util/date.util";
import { logger } from "src/app/util/logger.util";
import { BidpoolFeedbackComponent } from "../bidpool-feedback/bidpool-feedback.component";

@Component({
  selector: "app-bidpool-single",
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    DatePipe,
    AsyncPipe,
    CurrencyPipe,

    MatCheckboxModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    MatSelectModule,
    MatPaginatorModule,

    AuthenticatedLayoutComponent,
    CreateBidpoolChecklistComponent,
    TableComponent,
    DatePickerComponent,
    MonthYearPickerComponent,
    MatDialogModule,
    MatTooltipModule,
    BidpoolFeedbackComponent,
  ],
  providers: [CurrencyPipe, DatePipe],
  templateUrl: "./bidpool-single.component.html",
  styleUrl: "./bidpool-single.component.css",
})
export class BidpoolSingleComponent implements OnInit, OnDestroy {
  private debounceInMs = 3000;
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private viewportScroller = inject(ViewportScroller);
  private formBuilder = inject(FormBuilder);
  private currencyPipe = inject(CurrencyPipe);
  private loadingService = inject(LoadingService);
  private userService = inject(UserService);
  private bidpoolService = inject(BidpoolService);
  private fileUploadService = inject(FileUploadService);
  private bidPoolDocumentService = inject(BidpoolDocumentService);
  readonly dialog = inject(MatDialog);
  private toastrService = inject(ToastrService);
  private milestoneReportingService = inject(BidpoolMilestoneReportingService);
  protected datePipe = inject(DatePipe);

  private viewInitialised = false;
  protected mode: "view" | "edit";
  protected saving = false;
  protected canChangeWorkflowStep = false;
  protected canEdit = false;
  protected canAccessPaymentInfo = false;
  protected formErrors: string[] = [];
  protected modalService = inject(NgbModal);
  protected bidPoolDocuments: TAPIListResult<BidpoolDocument> = { rows: [], count: 0 };
  protected s3Prefix = "https://s3-ap-southeast-2.amazonaws.com/files-qwrap-dev/";
  protected cfDistribution = "https://files-qwrap-dev/";
  milesReports: TAPIListResult<BidpoolMilestoneReport> = { rows: [], count: 0 };
  currentMileStoneReport: BidpoolMilestoneReport | null;

  @ViewChild("mileModel", { read: TemplateRef })
  private milestoneReportModel: TemplateRef<unknown>;

  @ViewChild(BidpoolFeedbackComponent) feedbackComponent!: BidpoolFeedbackComponent;

  matcher = new MyErrorStateMatcher();
  bidpoolFeedback: TAPIListResult<BidpoolFeedback> = { rows: [], count: 0 };

  protected bidpoolForm = this.formBuilder.group({
    dateEndorsement: this.formBuilder.control(""),
    title: this.formBuilder.control(""),
    brief: this.formBuilder.control(""),
    feedBack: this.formBuilder.control(""),
    description: this.formBuilder.control(""),
    strategicPriorities: this.formBuilder.array([]),
    expectedBenefits: this.formBuilder.array([]),
    milestones: this.formBuilder.array([]),
    contributions: this.formBuilder.array([]),
  });
  private formSpec: BidpoolFormSpec = {
    dateEndorsement: {
      update: async (value: moment.Moment) => {
        const newDate = value.toISOString();
        await this.updateBidpool(this.bidpoolId, {
          dateEndorsement: newDate,
        });
      },
    },
    title: {
      update: async (value: string) => {
        await this.updateBidpool(this.bidpoolId, {
          title: value,
        });
      },
    },
    brief: {
      update: async (value: string) => {
        await this.updateBidpool(this.bidpoolId, {
          brief: value,
        });
      },
    },
    description: {
      update: async (value: string) => {
        await this.updateBidpool(this.bidpoolId, {
          description: value,
        });
      },
    },
    strategicPriorities: {
      update: async (value: string, id: number) => {
        await this.updateBidpool(this.bidpoolId, {
          strategicPriorities: [{ id, value: value }],
        });
      },
    },
    expectedBenefits: {
      update: async (value: string, id: number) => {
        await this.updateBidpool(this.bidpoolId, {
          expectedBenefits: [{ id, value: value }],
        });
      },
    },
    milestones: {
      date: {
        update: async (value: moment.Moment, id: number) => {
          const newDate = value.format(milestoneDateFormat.parse.dateInput);
          await this.updateBidpool(this.bidpoolId, {
            milestones: [
              {
                id,
                bidpoolApplicationId: this.bidpoolId,
                date: newDate,
              },
            ],
          });
        },
      },
      description: {
        update: async (value: string, id: number) => {
          await this.updateBidpool(this.bidpoolId, {
            milestones: [
              {
                id,
                bidpoolApplicationId: this.bidpoolId,
                description: value,
              },
            ],
          });
        },
      },
      paymentPct: {
        formatValueChange: this.formatToPercentageNumber,
        patchFormValue: this.formatToPercentageNumber,
        update: async (value: number, id: number) => {
          await this.updateBidpool(this.bidpoolId, {
            milestones: [
              {
                id,
                bidpoolApplicationId: this.bidpoolId,
                paymentPct: value,
              },
            ],
          });
          this.highlightInvalidPaymentFields();
        },
      },
    },
    contributions: {
      contributor: {
        update: async (value: string, id: number) => {
          await this.updateBidpool(this.bidpoolId, {
            contributions: [
              {
                id,
                bidpoolApplicationId: this.bidpoolId,
                contributor: value,
              },
            ],
          });
        },
      },
      cash: {
        formatValueChange: this.formatToNumber,
        patchFormValue: this.formatToCurrency.bind(this),
        update: async (value: number, id: number) => {
          await this.updateBidpool(this.bidpoolId, {
            contributions: [
              {
                id,
                bidpoolApplicationId: this.bidpoolId,
                cash: value,
              },
            ],
          });
        },
      },
      inkind: {
        formatValueChange: this.formatToNumber,
        patchFormValue: this.formatToCurrency.bind(this),
        update: async (value: number, id: number) => {
          await this.updateBidpool(this.bidpoolId, {
            contributions: [
              {
                id,
                bidpoolApplicationId: this.bidpoolId,
                inkind: value,
              },
            ],
          });
        },
      },
    },
  };
  protected rolesEnum = RolesEnum;
  protected workflowStepEnum = WorkflowStepsEnum;
  protected bidpoolRequestedAmountRequiringAPSC = BidpoolRequestedAmountRequiringAPSC;
  protected contactAutocompleteControl = this.formBuilder.control("");
  protected feedback = this.formBuilder.control("");
  protected contactOptions: Observable<User[]> = new Observable();
  protected participantAutocompleteControl = this.formBuilder.control("");
  protected participantOptions: Observable<User[]> = new Observable();
  protected contributionOtherOrgIndex: number;
  protected contributionBPRequuestIndex: number;

  @Input({ transform: numberAttribute }) bidpoolId = 0;

  bidpoolApplication: BidpoolApplication | null = null;
  subscriptions: Subscription[] = [];
  isUploading: boolean;
  milestonePaginationIndex: number = 0;
  documentPaginationIndex: number = 0;

  constructor() {
    afterRender({ read: this.initStickyTopAnchorScrolling.bind(this) });
  }

  public documentForm = this.formBuilder.group({
    description: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    documentFile: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    bidpoolId: new FormControl(0, { nonNullable: true, validators: [Validators.required] }),
  });

  public milesReportForm = this.formBuilder.group({
    name: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    date: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    amount: new FormControl("$0", {
      nonNullable: true,
      validators: [Validators.required, this.amountValidator],
    }),
    status: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    file: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    bidpoolApplicationId: new FormControl(0, {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  amountValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value || value === "$") {
      return { required: true };
    }
    return null;
  }

  ngOnInit(): void {
    this.milesReportForm.get("amount")?.valueChanges.subscribe((value: string | null) => {
      if (value !== null) {
        // Remove any non-numeric characters except for $
        const numericValue = value.replace(/[^0-9]/g, "");

        // Update the form control with $ prefix and the numeric value
        this.milesReportForm.get("amount")?.setValue(`$${numericValue}`, { emitEvent: false });
      }
    });
    if (!isNaN(this.bidpoolId)) {
      // TODO if NaN show error page or something
      this.fetchBidpoolApplication();
      this.fetchBidPoolDocumnets();
      this.fetchMilestoneReports();
    }

    this.initContactsAutocompleteOptions();
    this.initParticipantsAutocompleteOptions();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((e) => e.unsubscribe());
    }
  }

  async saveFeedback() {
    try {
      this.saving = true;
      await this.bidpoolService.updateBidpoolFeedback({
        feedback: this.feedback.value,
        bidpoolApplicationId: this.bidpoolId,
      });
      setTimeout(() => {
        this.feedback.patchValue("");
        this.feedbackComponent.refreshTableData();
        this.saving = false;
      });
    } catch (error) {
      logger.error("Error updating feedback", error);
    }
  }

  protected enterViewMode() {
    this.router.navigate([], { relativeTo: this.route, queryParams: { mode: "view" } });
    window.scrollTo({ left: 0, top: 0 });
  }

  protected enterEditMode() {
    this.router.navigate([], { relativeTo: this.route, queryParams: { mode: "edit" } });
  }

  protected displayContact(user: User): string {
    return user.fullName;
  }

  protected isUserAlreadySelected(user: User): boolean {
    return !!this.bidpoolApplication!.users.find((e) => e.id === user.id);
  }

  protected async addRow({
    fieldName,
    event,
  }:
    | { fieldName: "milestones" | "contributions"; event?: null }
    | { fieldName: "contacts" | "participants"; event: MatAutocompleteSelectedEvent }) {
    switch (fieldName) {
      case "contacts":
        await this.updateBidpool(this.bidpoolId, {
          users: [{ ...event.option.value, BidpoolUser: { type: "contact" }, action: "add" }],
        });
        this.contactAutocompleteControl.patchValue("");
        break;
      case "participants":
        await this.updateBidpool(this.bidpoolId, {
          users: [{ ...event.option.value, BidpoolUser: { type: "participant" }, action: "add" }],
        });
        this.participantAutocompleteControl.patchValue("");
        break;
      case "milestones":
      case "contributions":
        await this.updateBidpool(this.bidpoolId, {
          [fieldName]: [{ bidpoolApplicationId: this.bidpoolId, id: -1, action: "add" }],
        });
        break;
      default:
        break;
    }
  }

  protected async deleteRow(
    fieldName: "contacts" | "participants" | "milestones" | "contributions",
    rowIndex: number,
  ) {
    switch (fieldName) {
      case "contacts":
      case "participants": {
        const deleteUser = this.bidpoolApplication![fieldName][rowIndex];
        await this.updateBidpool(this.bidpoolId, {
          users: [{ ...(deleteUser as any), action: "delete" }],
        });
        break;
      }
      case "milestones":
      case "contributions": {
        const deleteId = this.bidpoolApplication![fieldName][rowIndex].id;
        await this.updateBidpool(this.bidpoolId, {
          [fieldName]: [{ bidpoolApplicationId: this.bidpoolId, id: deleteId, action: "delete" }],
        });
        break;
      }
      default:
        break;
    }
  }

  isPaymentValid(): boolean {
    const totalPct = this.milestones.controls.reduce(
      (acc, milestone) => acc + (milestone.get("paymentPct")?.value || 0),
      0,
    );
    return totalPct === 100;
  }

  highlightInvalidPaymentFields() {
    this.milestones.controls.forEach((milestone) => {
      const paymentControl = milestone.get("paymentPct");
      if (paymentControl) {
        const totalPct = this.milestones.controls.reduce(
          (acc, milestone) => acc + (milestone.get("paymentPct")?.value || 0),
          0,
        );
        if (totalPct !== 100) {
          paymentControl.setErrors({ invalidPercentage: true });
        } else {
          paymentControl.setErrors(null);
        }
      }
    });
  }

  protected async onSubmit(type: string) {
    let formIsValid = true;
    this.formErrors = [];

    if (!this.bidpoolApplication!.contacts.length) {
      formIsValid = false;
      this.formErrors.push("A contact must be added");
    }
    if (!this.bidpoolApplication!.participants.length) {
      formIsValid = false;
      this.formErrors.push("Participants must be added");
    }
    if (!this.isPaymentValid()) {
      this.highlightInvalidPaymentFields();
    }

    if (!this.bidpoolForm.valid) {
      formIsValid = false;
      this.bidpoolForm.markAllAsTouched();
      this.formErrors.push("The form has missing fields");
    }

    if (formIsValid) {
      await this.progressToNextWorkflowStep(type);
    }
  }

  protected async progressToNextWorkflowStep(type?: string) {
    await this.bidpoolService.updateWorkflow(this.bidpoolId, { target: "next", type });
    this.bidpoolApplication = await this.bidpoolService.fetchBidpoolApplication(this.bidpoolId);
    this.enterViewMode();
    this.initAccess();
  }

  protected async putBackToDraft() {
    await this.bidpoolService.updateWorkflow(this.bidpoolId, { target: "draft", comment: "" });
    this.bidpoolApplication = await this.bidpoolService.fetchBidpoolApplication(this.bidpoolId);
    this.enterViewMode();
    this.initAccess();
  }

  /**
   * This method subscribes to this bidpool application changes, calls the initForm method on initialisation and patchForm on subsequent changes
   */
  private fetchBidpoolApplication() {
    this.subscriptions.push(
      this.bidpoolService.subscribeBidpoolApplication(this.bidpoolId).subscribe({
        next: (value) => {
          if (!this.bidpoolApplication) {
            this.bidpoolApplication = plainToInstance(BidpoolApplication, value);
            this.initAccess();
            this.initMode();
            this.initForm();
          } else {
            this.bidpoolApplication.updatedAt = value.updatedAt;
            this.patchForm(value);
          }
        },
        error: (error) => {
          logger.debug("Silently catch error", error);
        },
      }),
    );
  }

  private initStickyTopAnchorScrolling() {
    if (!this.viewInitialised && document.getElementById("sticky-top")) {
      this.viewportScroller.setOffset([
        0,
        document.getElementById("sticky-top")!.getBoundingClientRect().height,
      ]);
      this.subscriptions.push(
        this.route.fragment.subscribe((fragment) => {
          if (fragment) {
            this.viewportScroller.scrollToAnchor(fragment);
          } else {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }),
      );
      this.viewInitialised = true;
    }
  }

  /**
   * Method called the first time the form is initialised.
   * Initialises the dynamic form controls, sets the initial values of the form.
   * Initialises subscriptions watching over form changes to trigger the updates API calls
   * By "dynamic", some form controls are dynamic eg: expected benefits, contributions, etc..
   * @param value contains all data from the BidpoolApplication, with all associations
   */
  private initForm() {
    const thisBidpoolApplication = this.bidpoolApplication!;

    // Initialise dateEndorsement, title, brief, description form controls
    for (let i of ["dateEndorsement", "title", "brief", "description"] as const) {
      const control = this.formBuilder.control("", [Validators.required]);
      this.bidpoolForm.setControl(i, control);
      this.subscriptions.push(
        control.valueChanges.pipe(debounceTime(this.debounceInMs)).subscribe({
          next: async (value) => {
            if (value !== null) {
              await this.formSpec[i].update(value);
            }
          },
        }),
      );
    }

    // Initialise strategic priorities and expected benefits in form controls
    for (let i of ["strategicPriorities", "expectedBenefits"] as const) {
      for (let j = 0; j < thisBidpoolApplication[i].length; j++) {
        let control;
        if (i === "expectedBenefits") {
          const isPriorityField = this.bidpoolApplication![i][j].refExpectedBenefit.isPriorityField;
          control = isPriorityField
            ? this.formBuilder.control("", [Validators.required])
            : this.formBuilder.control("");
        } else {
          control = this.formBuilder.control("", [Validators.required]);
        }
        this.bidpoolForm.controls[i].push(control);
        this.subscriptions.push(
          control.valueChanges.pipe(debounceTime(this.debounceInMs)).subscribe({
            next: async (value) => {
              if (value !== null) {
                await this.formSpec[i].update(value, this.bidpoolApplication![i][j].id);
              }
            },
          }),
        );
      }
    }

    // Initialise milestones in form controls
    for (let i = 0; i < thisBidpoolApplication.milestones.length; i++) {
      this.addMilestoneFormControls(thisBidpoolApplication.milestones[i].id);
    }

    // Initialise contributions in form controls
    for (let i = 0; i < thisBidpoolApplication.contributions.length; i++) {
      if (
        thisBidpoolApplication.contributions[i].isOtherOrganisation ||
        thisBidpoolApplication.contributions[i].contributor == OtherOrgsContributorName
      ) {
        this.contributionOtherOrgIndex = i;
      }
      if (thisBidpoolApplication.contributions[i].contributor === BPRequestContributorName) {
        this.contributionBPRequuestIndex = i;
      }
      this.addContributionFormControls(thisBidpoolApplication.contributions[i].id);
    }

    // Initialise form values
    const formValue = {
      dateEndorsement: thisBidpoolApplication.dateEndorsement,
      title: thisBidpoolApplication.title,
      brief: thisBidpoolApplication.brief,
      description: thisBidpoolApplication.description,
      strategicPriorities: thisBidpoolApplication.strategicPriorities.map((e) => e.value),
      expectedBenefits: thisBidpoolApplication.expectedBenefits.map((e) => e.value),
      milestones: thisBidpoolApplication.milestones.map((e) => ({
        date: e.date ? moment(e.date, milestoneDateFormat.parse.dateInput) : null,
        description: e.description,
        paymentPct: e.paymentPct !== undefined ? e.paymentPct : null,
      })),
      contributions: thisBidpoolApplication.contributions.map((e) => ({
        contributor: e.contributor,
        cash: e.cash !== undefined ? this.formSpec.contributions.cash.patchFormValue(e.cash) : null,
        inkind: e.inkind,
      })),
    };
    this.bidpoolForm.patchValue(formValue, { emitEvent: false });
  }

  private initAccess() {
    const thisBidpoolApplication = this.bidpoolApplication!;
    this.canChangeWorkflowStep = this.userService.hasAccess({
      accessName: AccessName.BidpoolApplicationActionButtons,
      bidpoolApplication: thisBidpoolApplication,
    });
    this.canEdit = this.userService.hasAccess({
      accessName: AccessName.EditBidpoolApplication,
      bidpoolApplication: thisBidpoolApplication,
    });
    this.canAccessPaymentInfo = this.userService.hasAccess({
      accessName: AccessName.RWBidpoolPaymentInformation,
      bidpoolApplication: thisBidpoolApplication,
    });
  }

  private initMode() {
    this.subscriptions.push(
      this.route.queryParamMap.subscribe({
        next: (params) => {
          const queryParamMode = params.get("mode");
          switch (queryParamMode) {
            case "view":
              this.mode = "view";
              break;
            case "edit":
              if (this.canEdit) {
                this.mode = "edit";
              } else {
                this.enterViewMode();
              }
              break;
            default:
              this.enterViewMode();
              break;
          }
        },
      }),
    );
  }

  /**
   * Method called when a change notification is received by WS.
   * Updates the value in the form control accordingly, and maintains the bidpoolApplication instance values
   * @param value contains only the updated data
   */
  private patchForm(value: BidpoolApplicationUpdate) {
    // Note: the backend could send the entire BidpoolApplication and we could call bidpoolForm.patchValue. This occurs data loss
    // if a user is editing a field and a change notification is received. So patching each individual field instead
    const thisBidpoolApplication = this.bidpoolApplication!;

    const {
      dateEndorsement,
      title,
      brief,
      description,
      users,
      strategicPriorities,
      expectedBenefits,
      milestones = [],
      contributions = [],
    } = value;

    if (dateEndorsement !== undefined) {
      this.bidpoolForm.controls.dateEndorsement.setValue(dateEndorsement, { emitEvent: false });
      thisBidpoolApplication.dateEndorsement = dateEndorsement;
    }

    if (title !== undefined) {
      this.bidpoolForm.controls.title.setValue(title, { emitEvent: false });
      thisBidpoolApplication.title = title;
    }

    if (brief !== undefined) {
      this.bidpoolForm.controls.brief.setValue(brief, { emitEvent: false });
      thisBidpoolApplication.brief = brief;
    }

    if (description !== undefined) {
      this.bidpoolForm.controls.description.setValue(description, { emitEvent: false });
      thisBidpoolApplication.description = description;
    }

    if (users) {
      for (let i = 0; i < (users || []).length; i++) {
        switch (users[i].action) {
          case "delete": {
            const bidpoolUserIndex = thisBidpoolApplication.users.findIndex(
              (e) => e.id === users[i].id && e.BidpoolUser.type === users[i].BidpoolUser.type,
            );
            thisBidpoolApplication.users.splice(bidpoolUserIndex, 1);
            break;
          }
          case "add":
            thisBidpoolApplication.users.push(plainToInstance(BidpoolUser, users[i]));
            break;
        }
      }
    }

    if (strategicPriorities) {
      for (let i = 0; i < (strategicPriorities || []).length; i++) {
        const indexToReplace = thisBidpoolApplication.strategicPriorities.findIndex(
          (e) => e.id === strategicPriorities[i].id,
        );
        if (indexToReplace > -1) {
          this.strategicPriorities.controls[indexToReplace].patchValue(
            strategicPriorities[i].value,
            {
              emitEvent: false,
            },
          );
          thisBidpoolApplication.strategicPriorities[indexToReplace].value =
            strategicPriorities[i].value;
        }
      }
    }

    if (expectedBenefits) {
      for (let i = 0; i < (expectedBenefits || []).length; i++) {
        const indexToReplace = thisBidpoolApplication.expectedBenefits.findIndex(
          (e) => e.id === expectedBenefits[i].id,
        );
        if (indexToReplace > -1) {
          this.expectedBenefits.controls[indexToReplace].patchValue(expectedBenefits[i].value, {
            emitEvent: false,
          });
          thisBidpoolApplication.expectedBenefits[indexToReplace].value = expectedBenefits[i].value;
        }
      }
    }

    if (milestones) {
      for (let i = 0; i < (milestones || []).length; i++) {
        switch (milestones[i].action) {
          case "delete":
            const deletedMilestoneIndex = thisBidpoolApplication.milestones.findIndex(
              (e) => e.id === milestones[i].id,
            );
            if (deletedMilestoneIndex > -1) {
              thisBidpoolApplication.milestones.splice(deletedMilestoneIndex, 1);
              this.milestones.removeAt(deletedMilestoneIndex, { emitEvent: false });
            }
            break;
          case "add":
            thisBidpoolApplication.milestones.push(
              plainToInstance(BidpoolMilestone, {
                ...milestones[i],
                bidpoolApplicationId: this.bidpoolId,
              }),
            );
            this.addMilestoneFormControls(milestones[i].id);
            break;
          case undefined:
            const indexToReplace = thisBidpoolApplication.milestones.findIndex(
              (e) => e.id === milestones[i].id,
            );
            if (indexToReplace > -1) {
              for (let j in this.formSpec.milestones) {
                if (milestones[i][j] !== undefined) {
                  this.bidpoolForm.controls.milestones.controls[indexToReplace].patchValue(
                    {
                      [j]: this.formSpec.milestones[j].patchFormValue
                        ? this.formSpec.milestones[j].patchFormValue(milestones[i][j])
                        : milestones[i][j],
                    },
                    { emitEvent: false },
                  );
                }
              }
              thisBidpoolApplication.milestones[indexToReplace].patchValue(milestones[i]);
            }
            break;
        }
      }
    }

    if (contributions) {
      for (let i = 0; i < (contributions || []).length; i++) {
        switch (contributions[i].action) {
          case "delete":
            const deletedContributionIndex = thisBidpoolApplication.contributions.findIndex(
              (e) => e.id === contributions[i].id,
            );
            if (deletedContributionIndex > -1) {
              thisBidpoolApplication.contributions.splice(deletedContributionIndex, 1);
              this.contributions.removeAt(deletedContributionIndex, { emitEvent: false });
            }
            break;
          case "add":
            thisBidpoolApplication.contributions.push(
              plainToInstance(BidpoolContribution, {
                ...contributions[i],
                bidpoolApplicationId: this.bidpoolId,
              }),
            );
            this.addContributionFormControls(contributions[i].id);
            break;
          case undefined:
            const indexToReplace = thisBidpoolApplication.contributions.findIndex(
              (e) => e.id === contributions[i].id,
            );
            if (indexToReplace > -1) {
              for (let j in this.formSpec.contributions) {
                if (contributions[i][j] !== undefined) {
                  this.bidpoolForm.controls.contributions.controls[indexToReplace].patchValue(
                    {
                      [j]: this.formSpec.contributions[j].patchFormValue
                        ? this.formSpec.contributions[j].patchFormValue(contributions[i][j])
                        : contributions[i][j],
                    },
                    { emitEvent: false },
                  );
                }
              }
              thisBidpoolApplication.contributions[indexToReplace].patchValue(contributions[i]);
            }
            break;
        }
      }
    }
  }

  private initContactsAutocompleteOptions() {
    this.contactOptions = this.contactAutocompleteControl.valueChanges.pipe(
      startWith(""),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(
        async (name) =>
          (
            await this.userService.fetchUsers(
              [
                RolesEnum.RegionalContributor,
                RolesEnum.Contributor,
                RolesEnum.Reviewer1,
                RolesEnum.Reviewer2,
                RolesEnum.ApproverWG,
                RolesEnum.ApproverPSC,
              ],
              name || "",
            )
          ).rows,
      ),
    );
  }

  private initParticipantsAutocompleteOptions() {
    this.participantOptions = this.participantAutocompleteControl.valueChanges.pipe(
      startWith(""),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(
        async (name) =>
          (
            await this.userService.fetchUsers(
              [
                RolesEnum.Contributor,
                RolesEnum.Reviewer1,
                RolesEnum.Reviewer2,
                RolesEnum.ApproverWG,
                RolesEnum.ApproverPSC,
              ],
              name || "",
            )
          ).rows,
      ),
    );
  }

  private addContributionFormControls(contributionId: number) {
    const group = this.formBuilder.group({});

    for (let j in this.formSpec.contributions) {
      const control = this.formBuilder.control("", [Validators.required]);
      group.addControl(j, control);
      this.subscriptions.push(
        control.valueChanges
          .pipe(
            map((value) => {
              const formattedValue = this.formSpec.contributions[j].formatValueChange
                ? this.formSpec.contributions[j].formatValueChange(value)
                : value;
              if (this.formSpec.contributions[j].patchFormValue) {
                control.patchValue(this.formSpec.contributions[j].patchFormValue(formattedValue), {
                  emitEvent: false,
                });
              }
              return formattedValue;
            }),
          )
          .pipe(debounceTime(this.debounceInMs))
          .subscribe({
            next: async (value) => {
              if (value !== null) {
                await this.formSpec.contributions[j].update(value, contributionId);
              }
            },
          }),
      );
    }
    this.contributions.push(group);
  }

  private addMilestoneFormControls(milestoneId: number) {
    const group = this.formBuilder.group({});
    for (let j in this.formSpec.milestones) {
      const control = this.formBuilder.control("", [Validators.required]);
      group.addControl(j, control);
      this.subscriptions.push(
        control.valueChanges
          .pipe(
            map((value) => {
              const formattedValue = this.formSpec.milestones[j].formatValueChange
                ? this.formSpec.milestones[j].formatValueChange(value)
                : value;
              if (this.formSpec.milestones[j].patchFormValue) {
                control.patchValue(this.formSpec.milestones[j].patchFormValue(formattedValue), {
                  emitEvent: false,
                });
              }
              return formattedValue;
            }),
          )
          .pipe(debounceTime(this.debounceInMs))
          .subscribe({
            next: async (value) => {
              if (value !== null) {
                await this.formSpec.milestones[j].update(value, milestoneId);
              }
            },
          }),
      );
    }
    this.milestones.push(group);
  }

  private async updateBidpool(bidpoolId: number, changes: BidpoolApplicationUpdate) {
    this.saving = true;
    await this.bidpoolService.updateBidpoolApplication(bidpoolId, changes);
    setTimeout(() => {
      this.saving = false;
    }, 1000);
  }

  private formatToNumber(e: string) {
    return Number((e || "").toString().replace(/\D/g, "").replace(/^0+/, ""));
  }

  private formatToPercentageNumber(e: string) {
    return Math.min(
      100,
      Math.max(0, Number((e || "").toString().replace(/\D/g, "").replace(/^0+/, ""))),
    );
  }

  private formatToCurrency(e: number) {
    return e !== null ? this.currencyPipe.transform(e, "USD", "symbol", "1.0-0") : "";
  }

  getDocument(text: string | undefined) {
    if (text) {
      return decodeURIComponent(text);
    }
    return "";
  }

  get strategicPriorities() {
    return this.bidpoolForm.controls.strategicPriorities;
  }

  get expectedBenefits() {
    return this.bidpoolForm.controls.expectedBenefits;
  }

  get milestones() {
    return this.bidpoolForm.get("milestones") as FormArray;
  }

  get contributions() {
    return this.bidpoolForm.get("contributions") as FormArray;
  }

  // Helpers to display Bidpool application in View mode

  public get contactTable() {
    return {
      tableColumns: [
        { columnDef: "col", columnLabel: "Contact information" },
        { columnDef: "info" },
      ],
      tableData: [
        { col: "Name of Regional Alliance", info: this.bidpoolApplication!.regionalAlliance },
        { col: "Contact person", info: this.bidpoolApplication!.contact?.fullName },
        { col: "Phone number", info: this.bidpoolApplication!.contact?.phone },
        { col: "Email", info: this.bidpoolApplication!.contact?.email },
      ],
    };
  }

  public get participantsTable() {
    return {
      tableColumns: [
        { columnDef: "role", columnLabel: "Participants", render: (e: User) => e.roles[0].name },
        { columnDef: "fullName" },
        { columnDef: "email" },
      ],
      tableData: this.bidpoolApplication!.participants,
    };
  }

  public get projectInfoTable() {
    const formattedDateEndorsement = this.bidpoolApplication!.dateEndorsement
      ? moment(this.bidpoolApplication!.dateEndorsement).format("D MMMM YYYY")
      : "";
    return {
      tableColumns: [
        { columnDef: "col", columnLabel: "Project information" },
        { columnDef: "info" },
      ],
      tableData: [
        { col: "Date of endorsement by QWRAP Region", info: formattedDateEndorsement },
        { col: "Title", info: this.bidpoolApplication!.title },
        { col: "Brief overview", info: this.bidpoolApplication!.brief },
        { col: "Description", info: this.bidpoolApplication!.description },
        {
          col: "Date submitted",
          info: this.datePipe.transform(this.bidpoolApplication!.dateSubmitted, "d MMMM y HH: mm"),
        },
        { col: "Total project", info: "$" + this.bidpoolApplication!.amountRequested },
      ],
    };
  }

  public get strategicPrioritiesTable() {
    return {
      tableColumns: [
        { columnDef: "label", columnLabel: "Strategic priorities" },
        { columnDef: "value" },
      ],
      tableData: this.bidpoolApplication!.strategicPriorities,
    };
  }

  public get milestonesTable() {
    return {
      tableColumns: [
        { columnDef: "description", columnLabel: "Description/deliverable" },
        {
          columnDef: "date",
          columnLabel: "Month/year",
          render: (e) =>
            e.date
              ? moment(e.date, milestoneDateFormat.parse.dateInput).format(
                  milestoneDateFormat.display.monthYearLabel,
                )
              : "",
        },
        {
          columnDef: "paymentPct",
          columnLabel: "Payment",
          render: (e) => (e.paymentPct !== null ? `${e.paymentPct}%` : ""),
        },
      ],
      tableData: this.bidpoolApplication!.milestones,
    };
  }

  public get contributionsTable() {
    return {
      tableColumns: [
        {
          columnDef: "contributor",
          columnLabel: "Organisation",
          cssClasses: (e) =>
            e.contributor === "Sub-total" || e.contributor === "TOTAL" ? "text-end fw-bold" : "",
        },
        {
          columnDef: "cash",
          columnLabel: "Cash (excl-GST)",
          render: (e) =>
            this.formatToNumber(e.cash)
              ? this.formatToCurrency(this.formatToNumber(e.cash)) || ""
              : "",
        },
        {
          columnDef: "inkind",
          columnLabel: "In-kind (excl-GST)",
          render: (e) =>
            this.formatToNumber(e.inkind)
              ? this.formatToCurrency(this.formatToNumber(e.inkind)) || ""
              : "",
        },
      ],
      tableData: [
        ...this.bidpoolApplication!.contributions.filter(
          (c) =>
            c.contributor !== OtherOrgsContributorName &&
            c.contributor !== BPRequestContributorName,
        ),
        {
          contributor: "Sub-total",
          cash: this.bidpoolApplication!.contributionsCashTotal,
          inkind: this.bidpoolApplication!.contributionsInkindSubTotal,
        },
        {
          ...this.bidpoolApplication!.contributions.find(
            (c) => c.contributor === OtherOrgsContributorName,
          ),
          contributor: "Other organisation(s)",
        },
        {
          ...this.bidpoolApplication!.contributions.find(
            (c) => c.contributor === BPRequestContributorName,
          ),
          contributor: "Bidpool request",
        },
        {
          contributor: "TOTAL",
          cash: this.bidpoolApplication!.contributionsCashTotal,
          inkind: this.bidpoolApplication!.contributionsInkindTotal,
        },
        {
          contributor: "TOTAL for the entire project",
          cash:
            this.bidpoolApplication!.contributionsCashTotal +
            this.bidpoolApplication!.contributionsInkindTotal,
        },
      ],
    };
  }

  public get expectedBenefitsTable() {
    return {
      tableColumns: [
        { columnDef: "label", columnLabel: "Expected benefits" },
        { columnDef: "value" },
      ],
      tableData: this.bidpoolApplication!.expectedBenefits,
    };
  }

  openDocumentModel(content: TemplateRef<unknown>) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "share-modal",
    });
  }

  persistS3Image(event, type: string) {
    this.isUploading = true;
    let theFile = event.srcElement.files[0];

    const allowedFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
      "application/pdf", // PDF
      "image/jpeg", // JPG
      "image/png", // PNG
      "text/plain", // TXT
      "text/csv", // CSV
    ];
    if (theFile && allowedFileTypes.includes(theFile.type)) {
      if (type == "doc") {
        this.fileUploadService.uploadBidpoolDocument(theFile, (err, data) => {
          let url = data.Location.replace(this.s3Prefix, this.cfDistribution);
          this.documentForm.patchValue({
            documentFile: url,
          });
          this.isUploading = false;
        });
      } else {
        this.fileUploadService.uploadMilestoneDocument(theFile, (err, data) => {
          let url = data.Location.replace(this.s3Prefix, this.cfDistribution);
          this.milesReportForm.patchValue({
            file: url,
          });
          this.isUploading = false;
        });
      }
    } else {
      alert(
        "Invalid file type. Please select a valid file (XLSX, DOCX, PPTX, PDF, JPG, PNG, TXT, CSV).",
      );
      this.isUploading = false;
      event.target.value = "";
    }
  }

  getDownloadLink(fileUrl: string | undefined) {
    if (fileUrl) {
      const key = fileUrl.replace("https://files-qwrap-dev.s3.ap-southeast-2.amazonaws.com/", "");
      this.fileUploadService.getDownloadSignedUrl(key).subscribe(
        (response) => {
          window.open(response.url, "_blank");
        },
        (error) => {
          console.error("Error fetching signed URL", error);
        },
      );
    }
  }

  async uploadDocument() {
    this.documentForm.patchValue({
      bidpoolId: this.bidpoolId,
    });
    if (!this.documentForm.valid) {
      this.toastrService.warning("Invalid Document");
      return;
    }
    try {
      await this.bidPoolDocumentService.create(this.documentForm.value);
      this.fetchBidPoolDocumnets();
      this.documentForm.reset();
      this.close();
    } catch (error) {
      this.documentForm.reset();
      logger.error("Error updating user", error);
    }
  }

  fetchBidPoolDocumnets() {
    const query: IQueryFilter = new IQueryFilter({
      filter: { bidpoolId: this.bidpoolId },
      limit: 10,
    });
    (query.skip = this.documentPaginationIndex * query.limit),
      this.bidPoolDocumentService
        .fetchDocumnets(query)
        .then((res) => {
          this.bidPoolDocuments = res;
        })
        .catch((error) => {
          logger.debug("Silently catch error", error);
        });
  }

  deleteDocument(documentId: number) {
    this.bidPoolDocumentService
      .delete(documentId)
      .then(() => {
        this.fetchBidPoolDocumnets();
      })
      .catch((error) => {
        logger.debug("Silently catch error", error);
      });
  }

  close() {
    this.modalService.dismissAll();
    this.dialog.closeAll();
  }

  openMilestoneModel() {
    const dialogRef = this.dialog.open(this.milestoneReportModel, {
      width: "700px",
    });
    dialogRef.afterClosed().subscribe(() => {
      this.milesReportForm.reset();
      this.currentMileStoneReport = null;
    });
  }

  async addMilestoneReport() {
    this.milesReportForm.patchValue({
      bidpoolApplicationId: this.bidpoolId,
    });
    if (!this.milesReportForm.valid) {
      return;
    }
    try {
      const formData = { ...this.milesReportForm.value };

      if (formData.amount) {
        formData.amount = formData.amount.replace("$", "");
      }

      if (formData.date) {
        formData.date = toLocalDate(formData.date).replace(/[^0-9]/g, "");
      }

      if (this.currentMileStoneReport) {
        await this.milestoneReportingService.update(this.currentMileStoneReport.id, {
          ...formData,
          amount: parseFloat(formData.amount || "0"),
        });
      } else {
        await this.milestoneReportingService.create({
          ...formData,
          amount: parseFloat(formData.amount || "0"),
        });
      }
      this.fetchMilestoneReports();
      this.milesReportForm.reset();
      this.currentMileStoneReport = null;

      this.close();
    } catch (error) {
      this.milesReportForm.reset();
      logger.error("Error updating user", error);
    }
  }

  fetchMilestoneReports() {
    const query: IQueryFilter = new IQueryFilter({
      filter: { bidpoolApplicationId: this.bidpoolId },
      limit: 5,
    });
    (query.skip = this.milestonePaginationIndex * query.limit),
      this.milestoneReportingService
        .fetchMilestoneReport(query)
        .then((res) => {
          this.milesReports = res;
        })
        .catch((error) => {
          logger.debug("Silently catch error", error);
        });
  }

  handlePaginationEvent(event: PageEvent, isDocument = false) {
    if (isDocument) {
      this.documentPaginationIndex = event.pageIndex;
      this.fetchBidPoolDocumnets();
    } else {
      this.milestonePaginationIndex = event.pageIndex;
      this.fetchMilestoneReports();
    }
  }

  deleteMilestoneReport(reportId: number) {
    this.milestoneReportingService
      .delete(reportId)
      .then(() => {
        this.fetchMilestoneReports();
      })
      .catch((error) => {
        logger.debug("Silently catch error", error);
      });
  }

  editMilestone(report: BidpoolMilestoneReport) {
    this.currentMileStoneReport = report;
    this.milesReportForm.patchValue({
      bidpoolApplicationId: report.bidpoolApplicationId,
      name: report.name,
      status: report.status,
      date: report.createdAt,
      amount: report.amount.toString(),
      file: report.file,
    });
    this.openMilestoneModel();
  }

  milestoneDateFormat(date: string | null | undefined) {
    if (!date) return "";
    return moment(date, milestoneDateFormat.parse.yearMonthDayInput).format(
      milestoneDateFormat.display.dayMonthYearLabel,
    );
  }
}
