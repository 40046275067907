import { inject, Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { BidpoolMilestoneReportingApi } from "../api/bidpool-milestone-reporting.api";
import { TAPIListResult } from "../contract/api.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { BidpoolMilestoneReport } from "../model/bidpool/bidpool-milestone-reporting.model";
import { apiCallWrapper, inflateModel } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
export class BidpoolMilestoneReportingService {
  private notifications = inject(ToastrService);
  private documentApi = inject(BidpoolMilestoneReportingApi);

  /**
   * @description This method is used to grab all bidPool Milestone Report.
   * @param query
   * @returns
   */
  public async fetchMilestoneReport(
    query: Partial<IQueryFilter>,
  ): Promise<TAPIListResult<BidpoolMilestoneReport>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.documentApi.list(new IQueryFilter(query)), {
        notificationsService: this.notifications,
        action: "Fetching Bidpool Documents",
      }),
    );

    return inflateModel(BidpoolMilestoneReport, data);
  }

  /**
   * @description This method is used to Create bidPool milestone report.
   * @param changes
   * @returns
   */
  public async create(body: Partial<BidpoolMilestoneReport>): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.create(body), {
        notificationsService: this.notifications,
        action: "Create bidpool milestone report",
      }),
    );

    return;
  }

  /**
   * @description This method is used to Archive any particular doc.
   * @param id
   * @returns
   */
  public async delete(id: number): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.delete(id), {
        notificationsService: this.notifications,
        action: "Delete bidpool Milestone Report",
      }),
    );

    return;
  }

  /**
   * @description This method is used to Update bidPool milestone report.
   * @param id
   * @param changes
   * @returns
   */
  public async update(id: number, body: Partial<BidpoolMilestoneReport>): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.update(id, body), {
        notificationsService: this.notifications,
        action: "Update bidpool milestone report",
      }),
    );

    return;
  }
}
