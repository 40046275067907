import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { finalize, interval, NEVER, Observable, of, switchMap } from "rxjs";
import { webSocket } from "rxjs/webSocket";
import { HasId } from "../contract/common.contract";
import {
  IUpdateRCApplicationWorkflowStepInput,
  IUpdateRCApplicationWorkflowStepOutput,
  RCApplicationUpdate,
} from "../contract/regional-coordinator.contract";
import { IQueryFilter, QueryResult } from "../model/api/query-filter.model";
import { RCApplication } from "../model/regional-coordinator/rc-appication.model";
import { RCFeedback } from "../model/regional-coordinator/rc-feedback.model";
import { JwtService } from "../service/jwt.service";
import { createUrl, createWSUrl, queryToParams, SocketData } from "../util/api.util";
import { GenericApi } from "./generic.api";

// Convenience Type
type T = RCApplication;

@Injectable({
  providedIn: "root",
})
export class RegionalCoordinatorApi extends GenericApi<T> {
  public path = "regional-coordinator-application";
  public httpClient = inject(HttpClient);
  public jwtService = inject(JwtService);

  create = (): Observable<T & HasId> => {
    return this.httpClient.post<T & HasId>(createUrl(this.path), {});
  };

  socket = <T = RCApplication>(id: number | string): Observable<SocketData<T>> => {
    let connectionClosed = false;

    const webSocketSubject = webSocket<SocketData<T>>({
      url: createWSUrl(this.path, "socket", id),
      protocol: this.jwtService.getJWTString(),
    });

    const keepalive = interval(30000).subscribe({
      next: () => {
        if (connectionClosed) {
          keepalive.unsubscribe();
          return;
        }

        webSocketSubject.next({
          event: "keepalive",
          data: "ping",
        });
      },
    });

    return webSocketSubject.asObservable().pipe(
      switchMap((data: SocketData<T>) => {
        if (data.event === "keepalive") {
          if (data.data === "ping") {
            webSocketSubject.next({
              event: "keepalive",
              data: "pong",
            });
          }

          return NEVER;
        }

        return of(data);
      }),
      finalize(() => {
        connectionClosed = true;
      }),
    );
  };

  update = (id: number | string, changes: Partial<RCApplicationUpdate>): Observable<T & HasId> => {
    return this.httpClient.put<T & HasId>(createUrl(this.path, id), changes);
  };

  updateWorkflow = (
    id: number | string,
    options: IUpdateRCApplicationWorkflowStepInput,
  ): Observable<IUpdateRCApplicationWorkflowStepOutput> => {
    return this.httpClient.put<IUpdateRCApplicationWorkflowStepOutput>(
      createUrl(this.path, id, "update-step"),
      options,
    );
  };

  updateFeedback = (changes: {
    feedback: string | null | undefined;
    rcApplicationId: number;
  }): Observable<T & HasId> => {
    return this.httpClient.post<T & HasId>(createUrl(this.path, "feedback"), changes);
  };

  listRCFeedback = (query: IQueryFilter): Observable<QueryResult<RCFeedback & HasId>> => {
    return this.httpClient.get<QueryResult<RCFeedback & HasId>>(createUrl(this.path, "feedback"), {
      params: queryToParams(query),
    });
  };
}
