import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { v4 as uuid } from "uuid";
import { FileUploadApi } from "../api/fileUpload.api";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  private fileUploadApi = inject(FileUploadApi);

  uploadDocumentImage(file: any, callback: (err, data) => void) {
    this.uploadFile(
      {
        file: file,
        bucket: "document",
      },
      callback,
    );
  }

  uploadBidpoolDocument(file: any, callback: (err, data) => void) {
    this.uploadFile(
      {
        file: file,
        bucket: "bidppol-document",
      },
      callback,
    );
  }

  uploadRCDocument(file: any, callback: (err, data) => void) {
    this.uploadFile(
      {
        file: file,
        bucket: "regional-coordinator-document",
      },
      callback,
    );
  }

  uploadMilestoneDocument(file: any, callback: (err, data) => void) {
    this.uploadFile(
      {
        file: file,
        bucket: "milestone-document",
      },
      callback,
    );
  }

  uploadFile(options: { file: any; bucket: string }, callback: (err, data) => void) {
    let key = options.bucket + "/" + options.file.name;
    this.fileUploadApi.getSignedUploadUrl(key).subscribe((signedUrlResult: { url: string }) => {
      this.fileUploadApi.decodeUri(options.file, signedUrlResult.url).subscribe((result) =>
        callback(null, {
          Location: signedUrlResult.url.replace(/\?.*$/, ""),
        }),
      );
    });
  }

  getDownloadSignedUrl(fileKey: string): Observable<{ url: string }> {
    return this.fileUploadApi.getDownloadSignedUrl(fileKey);
  }
}
