<app-authenticated-layout>
  @if (bidpoolApplication && mode === "view") {
    <ng-container *ngTemplateOutlet="viewMode"></ng-container>
  } @else if (bidpoolApplication && mode === "edit") {
    <ng-container *ngTemplateOutlet="editMode"></ng-container>
  }
</app-authenticated-layout>

<ng-template #viewMode>
  @let thisBidpoolApplication = bidpoolApplication!;

  <div class="d-flex flex-column">
    <div
      class="d-flex justify-content-between mb-3"
      [class.approved]="thisBidpoolApplication.workflowStepId == workflowStepEnum.Approved"
    >
      <div class="d-flex align-items-center gap-3">
        <h1>Bidpool Application No. {{ thisBidpoolApplication.name }}</h1>
        <div class="tag-indicator status">
          <span>{{ thisBidpoolApplication.workflowStep.name }}</span>
        </div>
      </div>
      @if (canEdit) {
        <div class="main-contact-add-btn d-flex justify-content-end align-items-center mb-3">
          <button class="btn-regular-blue" (click)="enterEditMode()">
            <mat-icon fontIcon="border_color"></mat-icon>
            Edit application
          </button>
        </div>
      }
    </div>

    @if (
      thisBidpoolApplication.workflowStepId == workflowStepEnum.Approved ||
      thisBidpoolApplication.workflowStepId == workflowStepEnum.Completed
    ) {
      <section class="bidpool-project-info" id="Milestone-report">
        <div class="project-head">
          <div class="main-contact-heading">
            <h2>Project Progress Tracking</h2>
          </div>
        </div>
        <div class="milestones-main">
          <div class="participants_main">
            @if (thisBidpoolApplication.workflowStepId !== workflowStepEnum.Completed) {
              <div class="d-flex align-items-center justify-content-end mb-3">
                <div class="part-btn">
                  <button class="btn-regular-blue" type="button" (click)="openMilestoneModel()">
                    <mat-icon fontIcon="add" />
                    Add New
                  </button>
                </div>
              </div>
            }
            <div class="part-table table-responsive mb-3">
              <table class="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Funding Amount</th>
                    <th>Status</th>
                    <th>Supporting Documents</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (report of milesReports.rows; track report) {
                    <tr>
                      <td>{{ report.name }}</td>
                      <td>{{ milestoneDateFormat(report.date) }}</td>
                      <td>{{ "$" + report.amount }}</td>
                      <td>{{ report.status }}</td>
                      <td>
                        <a
                          href="javascript:void(0)"
                          (click)="getDownloadLink(getDocument(report.file))"
                        >
                          {{ getDocument(report.file).split("/").pop() }}
                        </a>
                      </td>
                      <td>
                        @if (thisBidpoolApplication.workflowStepId !== workflowStepEnum.Completed) {
                          <div
                            class="main-contact-add-btn d-flex justify-content-end align-items-center mb-3"
                          >
                            <button type="button" (click)="editMilestone(report)">
                              <mat-icon fontIcon="edit"></mat-icon>
                            </button>
                            <button type="button" (click)="deleteMilestoneReport(report.id)">
                              <mat-icon fontIcon="delete" />
                            </button>
                          </div>
                        }
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <mat-paginator
              aria-label="Select page"
              [length]="milesReports.count"
              pageSize="5"
              [pageIndex]="milestonePaginationIndex"
              showFirstLastButtons
              hidePageSize
              (page)="handlePaginationEvent($event)"
            />
          </div>
        </div>
      </section>
    }

    <app-table
      class="mb-3"
      [tableColumns]="contactTable.tableColumns"
      [data]="contactTable.tableData"
    />
    <app-table
      class="mb-3"
      [tableColumns]="participantsTable.tableColumns"
      [data]="participantsTable.tableData"
    />
    <app-table
      class="mb-3"
      [tableColumns]="projectInfoTable.tableColumns"
      [data]="projectInfoTable.tableData"
    />
    <app-table
      class="mb-3"
      [tableColumns]="strategicPrioritiesTable.tableColumns"
      [data]="strategicPrioritiesTable.tableData"
    />
    <app-table
      class="mb-3"
      [tableColumns]="expectedBenefitsTable.tableColumns"
      [data]="expectedBenefitsTable.tableData"
    />
    @if (canAccessPaymentInfo) {
      <app-table
        class="mb-3"
        [tableColumns]="milestonesTable.tableColumns"
        [data]="milestonesTable.tableData"
      />
      <app-table
        class="mb-3"
        [tableColumns]="contributionsTable.tableColumns"
        [data]="contributionsTable.tableData"
      />
    }

    <section class="bidpool-project-info" id="supporting-docs">
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>
            Supporting documents
            <p class="mat-typography fs-10 text-muted text-wrap">
              The following file types are supported for upload:
              <strong>XLSX, DOCX, PPTX, PDF, JPG, PNG, TXT, CSV</strong>.
            </p>
          </h2>
        </div>
      </div>
      <div class="milestones-main">
        <div class="participants_main">
          @if (thisBidpoolApplication.workflowStepId !== workflowStepEnum.Completed) {
            <div class="d-flex align-items-center justify-content-end mb-3">
              <div class="part-btn">
                <button
                  class="btn-regular-blue"
                  type="button"
                  (click)="openDocumentModel(documentModel)"
                >
                  <mat-icon fontIcon="upload" />
                  UPLOAD FILE
                </button>
              </div>
            </div>
          }
          <div class="part-table table-responsive mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th>Date/time</th>
                  <th>Uploaded by</th>
                  <th>Description</th>
                  <th>Files uploaded</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (documents of bidPoolDocuments.rows; track documents) {
                  <tr>
                    <td>{{ documents.createdAt | date: "dd MMM yyyy H:mm" }}</td>
                    <td>{{ documents.user.firstName + " " + documents.user.lastName }}</td>
                    <td>{{ documents.description }}</td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        (click)="getDownloadLink(getDocument(documents.documentFile))"
                      >
                        {{ getDocument(documents.documentFile).split("/").pop() }}
                      </a>
                    </td>
                    <td>
                      @if (thisBidpoolApplication.workflowStepId !== workflowStepEnum.Completed) {
                        <button type="button" (click)="deleteDocument(documents.id)">
                          <mat-icon fontIcon="delete" />
                        </button>
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
          <mat-paginator
            aria-label="Select page"
            [length]="bidPoolDocuments.count"
            pageSize="5"
            [pageIndex]="documentPaginationIndex"
            showFirstLastButtons
            hidePageSize
            (page)="handlePaginationEvent($event, true)"
          />
        </div>
      </div>
    </section>

    <app-bidpool-feedback [bidPoolId]="bidpoolId"></app-bidpool-feedback>

    @if (
      thisBidpoolApplication.workflowStepId != workflowStepEnum.Draft &&
      thisBidpoolApplication.workflowStepId != workflowStepEnum.Completed
    ) {
      <div class="form-group">
        <label for="bp-feedback">Feedback</label>
        <div class="d-flex align-items-start gap-3">
          <mat-form-field class="form-control" appearance="outline">
            <textarea
              cdkTextareaAutosize
              id="bp-feedback"
              matInput
              matTooltip="Feedback"
              placeholder="Feedback"
              [formControl]="feedback"
            ></textarea>
          </mat-form-field>
          <div class="main-btn-group d-flex mt-2">
            <button class="btn-regular-blue light" (click)="saveFeedback()">
              {{ saving ? "saving..." : "save" }}
            </button>
          </div>
        </div>
      </div>
    }
  </div>

  @if (canChangeWorkflowStep) {
    <div class="main-btn-group d-flex">
      @switch (thisBidpoolApplication.workflowStepId) {
        @case (workflowStepEnum.R1) {
          <button class="btn-regular-blue light" (click)="putBackToDraft()">
            Put back to draft
          </button>
          <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
            Send to Banker
          </button>
        }
        @case (workflowStepEnum.Contributor) {
          <button class="btn-regular-blue dark" (click)="putBackToDraft()">
            Send to Coordinator
          </button>
        }
        @case (workflowStepEnum.R2) {
          <button class="btn-regular-blue light" (click)="putBackToDraft()">
            Put back to draft
          </button>
          <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
            Send to Working Group
          </button>
        }
        @case (workflowStepEnum.AWG) {
          <button class="btn-regular-blue light" (click)="putBackToDraft()">
            Put back to draft
          </button>
          @if (thisBidpoolApplication.amountRequested >= bidpoolRequestedAmountRequiringAPSC) {
            <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
              Send to Partner Steering Committee
            </button>
          } @else {
            <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
              Approve
            </button>
          }
        }
        @case (workflowStepEnum.APSC) {
          <button class="btn-regular-blue light" (click)="putBackToDraft()">
            Put back to draft
          </button>
          <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
            Approve
          </button>
        }
        @case (workflowStepEnum.Approved) {
          <button class="btn-regular-blue dark" (click)="progressToNextWorkflowStep()">
            Complete
          </button>
        }
        @default {}
      }
    </div>
  }
</ng-template>

<ng-template #editMode>
  @let thisBidpoolApplication = bidpoolApplication!;
  <div class="sticky-top" id="sticky-top">
    <div class="bidpool-top-main">
      <div class="d-flex align-items-center gap-15">
        <div class="bid-app-no">
          <h1>
            <a routerLink="." queryParamsHandling="merge"
              >Bidpool Application No. {{ thisBidpoolApplication.name }}</a
            >
          </h1>
        </div>
        @if (saving) {
          <div class="tag-indicator saving">
            <mat-icon fontIcon="cloud_sync" />
            <span>Saving</span>
          </div>
        } @else {
          <div class="tag-indicator saved">
            <mat-icon fontIcon="cloud_done" />
            <span>Saved</span>
          </div>
        }
        <div>Last saved {{ thisBidpoolApplication.updatedAt | date: "dd MMM yyyy H:mm:ss" }}</div>
      </div>
    </div>

    <div class="under-main-menu mb-3">
      <div class="under-main-items">
        <a routerLink="." queryParamsHandling="merge" fragment="contacts" class="bid-menu-item"
          >Contacts</a
        >
        <a routerLink="." queryParamsHandling="merge" fragment="project-info" class="bid-menu-item"
          >Project information</a
        >
        <a
          routerLink="."
          queryParamsHandling="merge"
          fragment="strategic-priorities"
          class="bid-menu-item"
          >Strategic priorities</a
        >
        <a
          routerLink="."
          queryParamsHandling="merge"
          fragment="expected-benefits"
          class="bid-menu-item"
          >Expected benefits</a
        >
        @if (canAccessPaymentInfo) {
          <a
            routerLink="."
            queryParamsHandling="merge"
            fragment="milestones-contributions"
            class="bid-menu-item"
            >Milestones and contributions</a
          >
        }
        <a
          routerLink="."
          queryParamsHandling="merge"
          fragment="supporting-docs"
          class="bid-menu-item"
          >Supporting documents</a
        >

        @if (thisBidpoolApplication.workflowStepId == workflowStepEnum.Approved) {
          <a
            routerLink="."
            queryParamsHandling="merge"
            fragment="Milestone-report"
            class="bid-menu-item"
            >Milestone reporting</a
          >
        }
      </div>
    </div>
  </div>

  <section class="bidpool-project-info p-3 bg-white">
    <app-create-bidpool-checklist [checklistChecked]="true" />
  </section>

  <form [formGroup]="bidpoolForm">
    <section class="bidpool-project-info" id="contacts">
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>Contacts</h2>
        </div>
      </div>
      <div class="milestones-main">
        <div class="participants_main">
          <div class="d-flex align-items-center justify-content-between">
            <div class="part-head">
              <h2>Main contacts</h2>
              <p class="mat-typography fs-10 text-muted">Regional Coordinator</p>
            </div>
          </div>
          <div class="part-table table-responsive mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th>Contact person</th>
                  <th>Name of regional alliance</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (
                  contact of thisBidpoolApplication.contacts;
                  track contact;
                  let i = $index;
                  let count = $count
                ) {
                  <tr>
                    <td>{{ contact.fullName }}</td>
                    <td>{{ contact.regionalAlliance }}</td>
                    <td>{{ contact.phone }}</td>
                    <td>{{ contact.email }}</td>
                    <td>
                      @if (count > 1) {
                        <button type="button" (click)="deleteRow('contacts', i)">
                          <mat-icon fontIcon="delete" />
                        </button>
                      }
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>

        <div class="participants_main">
          <div class="d-flex align-items-center justify-content-between">
            <div class="part-head">
              <h2>Project participants</h2>
              <p class="mat-typography fs-10 text-muted">
                Select the Chair, Banker, Working Group and other Contributors to this project. Only
                select PSC for bidpool funding over $50,000.
              </p>
            </div>
            <div class="part-btn">
              <mat-form-field class="form-control autocomplete-btn">
                <mat-label>Add new participant</mat-label>
                <input
                  type="text"
                  matTooltip="Search..."
                  placeholder="Search..."
                  aria-label="Add new participant"
                  matInput
                  [formControl]="participantAutocompleteControl"
                  [matAutocomplete]="participantAutocomplete"
                />
                <mat-autocomplete
                  #participantAutocomplete="matAutocomplete"
                  [displayWith]="displayContact"
                  (optionSelected)="addRow({ fieldName: 'participants', event: $event })"
                >
                  @for (option of participantOptions | async; track option) {
                    <mat-option [disabled]="isUserAlreadySelected(option)" [value]="option">{{
                      option.fullName
                    }}</mat-option>
                  }
                  @if (!(participantOptions | async)?.length) {
                    <mat-option disabled>No user found</mat-option>
                  }
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div class="part-table table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email address</th>
                  <th class="text-center">Contributor</th>
                  <th class="text-center">Chair</th>
                  <th class="text-center">Banker</th>
                  <th class="text-center">Working Group</th>
                  <th class="text-center">Partner Steering Committee</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (
                  participant of thisBidpoolApplication.participants;
                  track participant;
                  let i = $index
                ) {
                  <tr>
                    <td>{{ participant.fullName }}</td>
                    <td>{{ participant.email }}</td>
                    <td class="text-center">
                      <mat-checkbox
                        [checked]="participant.hasRole(rolesEnum.Contributor)"
                        disabled
                      ></mat-checkbox>
                    </td>
                    <td class="text-center">
                      <mat-checkbox
                        [checked]="participant.hasRole(rolesEnum.Reviewer1)"
                        disabled
                      ></mat-checkbox>
                    </td>
                    <td class="text-center">
                      <mat-checkbox
                        [checked]="participant.hasRole(rolesEnum.Reviewer2)"
                        disabled
                      ></mat-checkbox>
                    </td>
                    <td class="text-center">
                      <mat-checkbox
                        [checked]="participant.hasRole(rolesEnum.ApproverWG)"
                        disabled
                      ></mat-checkbox>
                    </td>
                    <td class="text-center">
                      <mat-checkbox
                        [checked]="participant.hasRole(rolesEnum.ApproverPSC)"
                        disabled
                      ></mat-checkbox>
                    </td>
                    <td>
                      <button type="button" (click)="deleteRow('participants', i)">
                        <mat-icon fontIcon="delete" />
                      </button>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section class="bidpool-project-info" id="project-info">
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>Project information</h2>
        </div>
      </div>
      <div class="info-main form-field-cst d-flex flex-column">
        <div class="form-group">
          <label for="bp-date-endorsement">Date endorsed by QWRAP Region</label>
          <app-date-picker formControlName="dateEndorsement" />
        </div>
        <div class="form-group">
          <label for="bp-title"
            >Title
            <p class="mat-typography fs-10 text-muted">Project name</p>
          </label>
          <mat-form-field class="form-control" appearance="outline">
            <input id="bp-title" matInput formControlName="title" />
          </mat-form-field>
        </div>
        <div class="form-group">
          <label for="bp-brief"
            >Brief overview
            <p class="mat-typography fs-10 text-muted text-wrap">
              Description and rationale of work to be undertaken and how it relates to the QWRAP
              Vision and Mission statement (including strategic priorities), as well as relevant
              state and regional priorities and work plans.
            </p>
          </label>
          <mat-form-field class="form-control" appearance="outline">
            <textarea cdkTextareaAutosize id="bp-brief" matInput formControlName="brief"></textarea>
          </mat-form-field>
        </div>
        <div class="form-group">
          <label for="bp-description"
            >Roles, resources and responsibilities
            <p class="mat-typography fs-10 text-muted text-wrap">
              Describe who is the project lead/project manager and who is conducting the work
              (external consultant or internal within WQRAP regional group or internal
              coucil/s).&#10;&#10;Describe expected project deliverables (final report,
              presentation, strategic or action plan) and who approves/signs off on project
              deliverables. Detail (or attach) project steering group and terms of reference if
              applicable.&#10;&#10;Describe project milestones and payment schedule in the next
              table.
            </p>
          </label>
          <mat-form-field class="form-control h-big" appearance="outline">
            <textarea
              cdkTextareaAutosize
              id="bp-description"
              matInput
              formControlName="description"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </section>

    <section
      formArrayName="strategicPriorities"
      class="bidpool-project-info"
      id="strategic-priorities"
    >
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>Strategic priorities</h2>
          <p class="mat-typography fs-10 text-muted">
            Please describe how the project aligns with the key strategic priorities listed in the
            <b
              ><a href="../../../../assets/files/QWRAP Vision Mission Statement.pdf" target="_blank"
                >Vision and Mission Statement</a
              ></b
            >
          </p>
        </div>
      </div>
      <div class="start-pro info-main form-field-cst d-flex flex-column">
        @for (
          strategicPriorityControl of strategicPriorities.controls;
          track strategicPriorityControl;
          let i = $index
        ) {
          <div class="form-group">
            <label for="strategicPriority-{{ i }}"
              >{{ thisBidpoolApplication.strategicPriorities[i].label }}
              <p class="mat-typography fs-10 text-muted text-wrap">
                {{ thisBidpoolApplication.strategicPriorities[i].description }}
              </p>
            </label>
            <mat-form-field class="form-control" appearance="outline">
              <textarea
                id="strategicPriority-{{ i }}"
                cdkTextareaAutosize
                matInput
                [formControlName]="i"
              ></textarea>
            </mat-form-field>
          </div>
        }
      </div>
    </section>

    <section formArrayName="expectedBenefits" class="bidpool-project-info" id="expected-benefits">
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>Expected benefits</h2>
        </div>
      </div>
      <div class="benefit-form">
        <div>
          <i
            >Please refer to
            <b
              ><a href="../../../../assets/files/QWRAP Funding Procedure V2.docx" target="_blank"
                >QWRAP Funding Procedure</a
              ></b
            >
            for detailed guidance on completing the following sections. Each project must provide
            evidence of 'regional development'. Further benefits/identified risks and mitigation
            options can be identified as part of the remaining seven criteria. Each project will be
            assessed based on the identified benefits only. Please only detail direct relevance for
            each section as required.</i
          >
        </div>
        @for (
          expectedBenefitControl of expectedBenefits.controls;
          track expectedBenefitControl;
          let i = $index
        ) {
          <div class="expected-benefit ben-form-field">
            <label for="expectedBenefit-{{ i }}">
              <span class="grey-circle">{{ i + 1 }}</span>
              {{ thisBidpoolApplication.expectedBenefits[i].label }}
              <p class="mat-typography fs-10 text-muted">
                {{ thisBidpoolApplication.expectedBenefits[i].description }}
              </p>
            </label>
            <mat-form-field class="form-control" appearance="outline">
              <textarea
                id="expectedBenefit-{{ i }}"
                cdkTextareaAutosize
                matInput
                [formControlName]="i"
              ></textarea>
            </mat-form-field>
          </div>
        }
        <div class="legend d-none">
          <div class="legend-square"></div>
          <span>=</span>
          <span>priority fields</span>
        </div>
        <div class="d-none">
          Unless negotiated otherwise, intellectual property vests in the LGAQ, although parties
          retain unrestricted license to same.
        </div>
      </div>
    </section>

    @if (canAccessPaymentInfo) {
      <section class="bidpool-project-info" id="milestones-contributions">
        <div class="project-head">
          <div class="main-contact-heading">
            <h2>Milestones and contributions</h2>
            <p class="mat-typography fs-10 text-muted">
              Please ensure the total Payment % is equal to 100%
            </p>
          </div>
        </div>
        <div class="milestones-main">
          <div class="main-contact-main">
            <div class="mile-contact-form">
              <div class="part-table table-responsive">
                <table formArrayName="milestones" class="table">
                  <thead>
                    <tr>
                      <th style="width: 7rem"></th>
                      <th>Description/deliverable</th>
                      <th>Month/year</th>
                      <th>Payment (%)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (milestone of milestones.controls; track milestone; let i = $index) {
                      <tr [formGroupName]="i">
                        <td style="width: 7rem">{{ i + 1 }}</td>
                        <td>
                          <mat-form-field class="form-control" appearance="outline">
                            <textarea
                              cdkTextareaAutosize
                              id="bp-feedback"
                              matInput
                              formControlName="description"
                            ></textarea>
                          </mat-form-field>
                        </td>
                        <td>
                          <app-date-picker formControlName="date" />
                        </td>
                        <td>
                          <mat-form-field class="form-control" appearance="outline">
                            <input matInput formControlName="paymentPct" />
                          </mat-form-field>
                        </td>
                        <td>
                          <button type="button" (click)="deleteRow('milestones', i)">
                            <mat-icon fontIcon="delete" />
                          </button>
                        </td>
                      </tr>
                    }
                    <tr>
                      <td colspan="5">
                        <button
                          class="btn-regular-blue"
                          type="button"
                          (click)="addRow({ fieldName: 'milestones' })"
                        >
                          <mat-icon fontIcon="add" />
                          Add New milestone
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <h3 class="mb20">Cash and in-kind contributions</h3>
          <div class="cash-in-table mb-3">
            <div class="part-table table-responsive">
              <table
                formArrayName="contributions"
                class="table table-bordered table-vertical-border"
              >
                <thead>
                  <tr>
                    <th>Name of organisation</th>
                    <th>Cash (excl-GST)</th>
                    <th>In-kind<sup>1</sup> (excl-GST)</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  @for (
                    contribution of contributions.controls;
                    track contribution;
                    let i = $index
                  ) {
                    @if (i !== contributionOtherOrgIndex && i !== contributionBPRequuestIndex) {
                      <tr [formGroupName]="i">
                        <td>
                          <mat-form-field class="form-control" appearance="outline">
                            <input
                              matInput
                              formControlName="contributor"
                              matTooltip="Council name"
                              placeholder="Council name"
                            />
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field class="form-control" appearance="outline">
                            <input matInput formControlName="cash" />
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field class="form-control" appearance="outline">
                            <input matInput formControlName="inkind" />
                          </mat-form-field>
                        </td>
                        <td>
                          <button type="button" (click)="deleteRow('contributions', i)">
                            <mat-icon fontIcon="delete" />
                          </button>
                        </td>
                      </tr>
                    }
                  }
                  <tr>
                    <td colspan="4">
                      <button
                        class="btn-regular-blue"
                        type="button"
                        (click)="addRow({ fieldName: 'contributions' })"
                      >
                        <mat-icon fontIcon="add" />
                        Add contribution
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-end"><b>Sub-total</b></td>
                    <td>
                      {{
                        thisBidpoolApplication.contributionsCashTotal
                          | currency: "USD" : "symbol" : "1.0-0"
                      }}
                    </td>
                    <td>
                      {{
                        thisBidpoolApplication.contributionsInkindSubTotal
                          | currency: "USD" : "symbol" : "1.0-0"
                      }}
                    </td>
                    <td></td>
                  </tr>
                  <tr [formGroupName]="contributionOtherOrgIndex">
                    <td>
                      <div class="form-group d-flex align-items-center justify-content-start gap-3">
                        <label for="other" style="max-width: 20rem">
                          Other organisation(s)
                          <p class="mat-typography fs-10 text-muted text-wrap">
                            If multiple external organisations contribute to the project, please
                            attach a list in the supporting doument section showing each
                            organisation's cash and/or in-kind contributions. Enter the total amount
                            in this table.
                          </p>
                        </label>
                        <mat-form-field class="form-control" appearance="outline">
                          <input matInput formControlName="contributor" />
                        </mat-form-field>
                      </div>
                    </td>
                    <td style="vertical-align: middle">
                      <mat-form-field class="form-control" appearance="outline">
                        <input matInput formControlName="cash" />
                      </mat-form-field>
                    </td>
                    <td style="vertical-align: middle">
                      <mat-form-field class="form-control" appearance="outline">
                        <input matInput formControlName="inkind" />
                      </mat-form-field>
                    </td>
                    <td></td>
                  </tr>
                  <tr [formGroupName]="contributionBPRequuestIndex">
                    <td>
                      <b>Bidpool request<sup>2</sup></b>
                    </td>
                    <td>
                      <mat-form-field class="form-control" appearance="outline">
                        <input matInput formControlName="cash" />
                      </mat-form-field>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="text-end"><b>TOTAL</b></td>
                    <td>
                      {{
                        thisBidpoolApplication.contributionsCashTotal
                          | currency: "USD" : "symbol" : "1.0-0"
                      }}
                    </td>
                    <td>
                      {{
                        thisBidpoolApplication.contributionsInkindTotal
                          | currency: "USD" : "symbol" : "1.0-0"
                      }}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td class="text-end"><b>TOTAL for the entire project</b></td>
                    <td>
                      {{
                        thisBidpoolApplication.contributionsCashTotal +
                          thisBidpoolApplication.contributionsInkindTotal
                          | currency: "USD" : "symbol" : "1.0-0"
                      }}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p>
            <sup>1</sup>In-kind contributions (e.g. travel, administration, hosting, staff salaries
            and oversight may be estimated using a 1.5 multiplier of estimated salaries as follows:
            <b>in-kind = (no. staff in each category) x (no. days) x (salary/261) x (1.5). </b
            ><br />
            i. Category 1- {{ 70000 | currency: "USD" : "symbol" : "1.0-0" }}, Category 2-
            {{ 100000 | currency: "USD" : "symbol" : "1.0-0" }}, Category 3-
            {{ 140000 | currency: "USD" : "symbol" : "1.0-0" }}<br />
            Example: If ten Category 2 committee members spent four meeting days in meetings and
            four days in associated activities, the in-kind contribution is: 10 (people) {{ "@" }}
            {{ 100000 | currency: "USD" : "symbol" : "1.0-0" }} (Cat. 2) x 8 (days) / 261 (workdays)
            x 1.5 (overheads multiplier) = {{ 45977 | currency: "USD" : "symbol" : "1.0-0" }}.
          </p>
          <p>
            <sup>2</sup>Bidpool requests greater than
            {{ 50000 | currency: "USD" : "symbol" : "1.0-0" }} will be referred to the Partner
            Steering Committee and will be subject to greater post-project reporting requirements.
          </p>
        </div>
      </section>
    }

    <section class="bidpool-project-info" id="supporting-docs">
      <div class="project-head">
        <div class="main-contact-heading">
          <h2>
            Supporting documents
            <p class="mat-typography fs-10 text-muted text-wrap">
              The following file types are supported for upload:
              <strong>XLSX, DOCX, PPTX, PDF, JPG, PNG, TXT, CSV</strong>.
            </p>
          </h2>
        </div>
      </div>
      <div class="milestones-main">
        <div class="participants_main">
          <div class="d-flex align-items-center justify-content-end mb-3">
            <div class="part-btn">
              <button
                class="btn-regular-blue"
                type="button"
                (click)="openDocumentModel(documentModel)"
              >
                <mat-icon fontIcon="upload" />
                UPLOAD FILE
              </button>
            </div>
          </div>
          <div class="part-table table-responsive mb-3">
            <table class="table">
              <thead>
                <tr>
                  <th>Date/time</th>
                  <th>Uploaded by</th>
                  <th>Description</th>
                  <th>Files uploaded</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (documents of bidPoolDocuments.rows; track documents) {
                  <tr>
                    <td>{{ documents.createdAt | date: "dd MMM yyyy H:mm" }}</td>
                    <td>{{ documents.user.firstName + " " + documents.user.lastName }}</td>
                    <td>{{ documents.description }}</td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        (click)="getDownloadLink(getDocument(documents.documentFile))"
                      >
                        {{ getDocument(documents.documentFile).split("/").pop() }}
                      </a>
                    </td>
                    <td>
                      <button type="button" (click)="deleteDocument(documents.id)">
                        <mat-icon fontIcon="delete" />
                      </button>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>

          <mat-paginator
            aria-label="Select page"
            [length]="bidPoolDocuments.count"
            pageSize="5"
            [pageIndex]="documentPaginationIndex"
            showFirstLastButtons
            hidePageSize
            (page)="handlePaginationEvent($event, true)"
          />
        </div>
      </div>
    </section>

    <section class="bidpool-project-info">
      <app-bidpool-feedback [bidPoolId]="bidpoolId"></app-bidpool-feedback>
    </section>

    @if (formErrors.length) {
      @for (error of formErrors; track error) {
        <div class="fs-4 text-danger">*{{ error }}</div>
      }
    }

    <div class="main-btn-group d-flex">
      <a
        class="btn-regular-blue dark"
        routerLink="."
        [queryParams]="{ mode: 'view' }"
        target="_blank"
        type="button"
        >Preview</a
      >
      <button class="btn-regular-blue light" type="button" (click)="onSubmit('contributor')">
        Send to contributors
      </button>
      <button class="btn-regular-blue dark" type="button" (click)="onSubmit('chair')">
        Send to Chair
      </button>
    </div>
  </form>
</ng-template>

<ng-template #documentModel let-modal>
  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Upload Document</h1>
    <button
      mat-icon-button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      (click)="close()"
    ></button>
  </div>
  <form [formGroup]="documentForm">
    <div class="modal-body newinputs newformmodal">
      <mat-form-field appearance="fill" class="">
        <mat-label>Document Description</mat-label>
        <input matInput type="text" id="description" formControlName="description" />
      </mat-form-field>

      <mat-form-field appearance="fill" class="">
        <mat-label>File</mat-label>
        <input
          matInput
          type="file"
          id="documnetFile"
          accept=".xlsx, .docx, .pptx, .pdf, .jpg, .jpeg, .png, .txt, .csv"
          (change)="persistS3Image($event, 'doc')"
        />
        <div *ngIf="isUploading">Uploading...</div>
      </mat-form-field>
    </div>
  </form>

  <div class="modal-footer">
    <button class="secondbtn" (click)="close()">Cancel</button>
    <button class="createbtn" [disabled]="!documentForm.valid" (click)="uploadDocument()">
      Save
    </button>
  </div>
</ng-template>

<ng-template #mileModel let-modal>
  <div class="d-flex justify-content-end px-3 mt-3 modal-header">
    <button
      mat-icon-button
      type="button"
      class="btn-close btn-closemodal p-0"
      mat-dialog-close
    ></button>
  </div>
  <div class="modal-body newinputs newformmodal p-3 pt-0">
    <div class="row">
      <mat-dialog-content>
        <form [formGroup]="milesReportForm" class="row">
          <div class="col-lg-6">
            <mat-form-field appearance="fill" class="">
              <mat-label>Name:</mat-label>
              <mat-select formControlName="name" [errorStateMatcher]="matcher">
                <mat-option value="project_initiation"> Project Initiation </mat-option>
                <mat-option value="project_progress"> Project Progress </mat-option>
                <mat-option value="project_finalisation"> Project Finalisation </mat-option>
                <mat-option value="project_closeout"> Project Close Out </mat-option>
              </mat-select>
              @if (milesReportForm.get("name")?.hasError("required")) {
                <mat-error>Name is required</mat-error>
              }
            </mat-form-field>
          </div>

          <div class="col-lg-6 datenew">
            <div class="form-group">
              <label for="bp-date-endorsement">Date:</label>
              <app-date-picker formControlName="date" />
              @if (milesReportForm.get("date")?.hasError("required")) {
                <mat-error>Date is required</mat-error>
              }
            </div>
          </div>

          <div class="col-lg-6">
            <mat-form-field appearance="fill" class="">
              <mat-label>Funding amount:</mat-label>
              <input
                #amount
                matInput
                type="text"
                id="ammount"
                formControlName="amount"
                [errorStateMatcher]="matcher"
              />
              @if (milesReportForm.get("amount")?.hasError("required")) {
                <mat-error>Amount is required</mat-error>
              }
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <mat-form-field appearance="fill" class="">
              <mat-label>Status:</mat-label>
              <mat-select formControlName="status" [errorStateMatcher]="matcher">
                <mat-option value="in-progress"> In Progress </mat-option>
                <mat-option value="ready_for_approval"> Ready for Approval </mat-option>
                <mat-option value="approved"> Approved </mat-option>
                <mat-option value="completed"> Completed </mat-option>
              </mat-select>
              @if (milesReportForm.get("status")?.hasError("required")) {
                <mat-error>Name is required</mat-error>
              }
            </mat-form-field>
          </div>

          <div class="col-lg-6">
            <button class="btn-regular-blue dark" type="button" (click)="fileInput.click()">
              <mat-icon fontIcon="upload" />
              UPLOAD FILE
            </button>
            <input
              matInput
              #fileInput
              type="file"
              class="d-none"
              id="documnetFile"
              (change)="persistS3Image($event, 'milestone')"
            />
            <div *ngIf="isUploading">Uploading...</div>
            <div class="text-danger" *ngIf="milesReportForm.get('file')?.invalid">
              *File is missing
            </div>

            @if (milesReportForm.get("file")?.value) {
              <div>
                <a
                  href="javascript:void(0)"
                  (click)="getDownloadLink(getDocument(milesReportForm.get('file')?.value))"
                >
                  {{ getDocument(milesReportForm.get("file")?.value)?.split("/")?.pop() ?? "" }}
                </a>
              </div>
            }
          </div>
          <div class="col-lg-6">
            <div class="d-flex gap-3">
              <button class="secondbtn" mat-dialog-close>Cancel</button>
              <button class="createbtn" (click)="addMilestoneReport()">Save</button>
            </div>
          </div>
        </form>
      </mat-dialog-content>
    </div>
  </div>
</ng-template>
