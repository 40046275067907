<mat-form-field appearance="outline">
  <input
    matInput
    [matDatepicker]="datePicker"
    [value]="date"
    [min]="minDate"
    (dateChange)="onDateChange($event)"
  />
  <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker startView="multi-year"></mat-datepicker>
</mat-form-field>
