import { User } from "../user/user.model";

export class RCDocument {
  id: number;
  description: string;
  documentFile: string;
  type: string;
  uploadedBy: string;
  rcApplicationId: number;
  createdAt: string;
  user: User;
}
