import { BaseModel } from "../base.model";

export type EventCreateOrUpdate = Partial<Calender>;

export class Calender extends BaseModel {
  id: string;
  title: string;
  date: Date | string;
  time: Date | string;
  location: string;
  description: string | null;
}
