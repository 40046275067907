import { CommonModule, DatePipe } from "@angular/common";
import {
  Component,
  inject,
  TemplateRef,
  ViewChild,
  OnInit,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { fromEvent, map, debounceTime, distinctUntilChanged } from "rxjs";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { TableColumn, TableComponent } from "src/app/component/table/table.component";
import { RolesEnum } from "src/app/contract/user.contract";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { SupportingDocument } from "src/app/model/document.model";
import { DocumentService } from "src/app/service/document.service";
import { FileUploadService } from "src/app/service/fileUpload.service";
import { UserService } from "src/app/service/user.service";
import { logger } from "src/app/util/logger.util";

@Component({
  selector: "app-documents",
  standalone: true,
  imports: [
    AuthenticatedLayoutComponent,
    ReactiveFormsModule,
    CommonModule,
    TableComponent,
    MatInputModule,
    MatFormFieldModule,
    MatIcon,
  ],
  providers: [DatePipe],
  templateUrl: "./documents.component.html",
})
export class DocumentsComponent implements AfterViewInit {
  protected documentService = inject(DocumentService);
  protected userService = inject(UserService);
  protected modalService = inject(NgbModal);
  private formBuilder = inject(FormBuilder);
  private fileUploadService = inject(FileUploadService);
  protected datePipe = inject(DatePipe);
  protected toastrService = inject(ToastrService);

  protected s3Prefix = "https://s3-ap-southeast-2.amazonaws.com/files-qwrap-dev/";
  protected cfDistribution = "https://files-qwrap-dev/";

  @ViewChild(TableComponent) tableComponent!: TableComponent<null>;
  @ViewChild("searchInput") searchInput!: ElementRef;
  private debounceInMs = 1000;

  @ViewChild("documentModel", { read: TemplateRef })
  private documentModel: TemplateRef<unknown>;

  public isUploading: boolean;
  public currentDocument: SupportingDocument | undefined;
  public query: IQueryFilter = new IQueryFilter({ limit: 10 });
  isAdmin?: boolean;

  constructor() {
    this.isAdmin = this.userService.currentUser?.roles.some((role) => role.id === RolesEnum.Admin);
  }

  protected documnetTableColumns: TableColumn<SupportingDocument>[] = [
    {
      columnDef: "SN.",
      columnLabel: "SN.",
      render: (element: SupportingDocument) => element.id,
    },
    {
      columnDef: "name",
      columnLabel: "Name",
      render: (element: SupportingDocument) => element.documentName,
    },
    {
      columnDef: "type",
      columnLabel: "Type",
      render: (element: SupportingDocument) => element.documentType,
    },
    {
      columnDef: "file",
      columnLabel: "File",
      render: (element: SupportingDocument) => decodeURIComponent(element.documentFile),
    },
    {
      columnDef: "uploadedBy",
      columnLabel: "UploadedBy",
      render: (element: SupportingDocument) => element.uploader,
    },
    {
      columnDef: "date",
      columnLabel: "Date",
      render: (element: SupportingDocument) =>
        this.datePipe.transform(element.createdAt, "dd MMM yyyy H:mm"),
    },
    {
      columnDef: "action",
      columnLabel: "Action",
      render: () => "",
      actions: (element: SupportingDocument) => [
        {
          type: "button",
          label: "",
          icon: "edit",
          action: () => this.editDocument(element.id),
        },
        {
          type: "button",
          label: "",
          icon: "delete",
          action: () => this.deleteDocument(element.id),
        },
      ],
    },
  ];

  public documentForm = this.formBuilder.group({
    documentName: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    documentType: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
    documentFile: new FormControl("", { nonNullable: true, validators: [Validators.required] }),
  });

  protected documentListFetchDataMethod = (query: Partial<IQueryFilter>) =>
    this.documentService.fetchDocumnets(query);

  openDocumnetModel() {
    const dialog = this.modalService.open(this.documentModel, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "share-modal",
    });
    dialog.result.then(
      () => {
        this.currentDocument = undefined;
        this.documentForm.reset();
      },
      (dismissReason) => {
        this.currentDocument = undefined;
        this.documentForm.reset();
      },
    );
  }

  ngAfterViewInit() {
    this.setupSearchDebounce();
  }

  persistS3Image(event) {
    this.isUploading = true;
    const theFile = event.target.files[0];
    const allowedFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
      "application/vnd.openxmlformats-officedocument.presentationml.presentation", // PPTX
      "application/pdf", // PDF
      "image/jpeg", // JPG
      "image/png", // PNG
      "text/plain", // TXT
      "text/csv", // CSV
    ];

    if (theFile && allowedFileTypes.includes(theFile.type)) {
      this.fileUploadService.uploadDocumentImage(theFile, (err, data) => {
        if (err) {
          console.error("Upload failed:", err);
          this.isUploading = false;
          return;
        }
        const url = data.Location.replace(this.s3Prefix, this.cfDistribution);
        this.documentForm.patchValue({
          documentFile: url,
        });
        console.log(url, theFile);
        this.isUploading = false;
      });
    } else {
      alert(
        "Invalid file type. Please select a valid file (XLSX, DOCX, PPTX, PDF, JPG, PNG, TXT, CSV).",
      );
      this.isUploading = false;
      event.target.value = "";
    }
  }

  async onSubmit() {
    if (!this.documentForm.valid) {
      this.toastrService.warning("Invalid Document");
      return;
    }
    try {
      if (!this.currentDocument) {
        await this.documentService.create(this.documentForm.value);
      } else {
        await this.documentService.update(this.currentDocument.id, this.documentForm.value);
        this.currentDocument = undefined;
      }
      this.modalService.dismissAll();
      this.tableComponent.refreshTableData();
    } catch (error) {
      this.documentForm.reset();
      logger.error("Error updating user", error);
    }

    this.modalService.dismissAll();
  }

  async deleteDocument(id: number) {
    try {
      await this.documentService.delete(id);
      this.tableComponent.refreshTableData();
    } catch (error) {
      logger.error("Error deleting document", error);
    }
  }

  async editDocument(id: number) {
    this.documentService.get(id).then((res) => {
      this.currentDocument = res;
      this.documentForm.patchValue({
        documentName: res.documentName,
        documentFile: res.documentFile,
        documentType: res.documentType,
      });
      this.openDocumnetModel();
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  /**
   * @description Sets up debounce for the search input field
   */
  setupSearchDebounce() {
    fromEvent(this.searchInput.nativeElement, "input")
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(this.debounceInMs),
        distinctUntilChanged(),
      )
      .subscribe((searchTerm: string) => {
        this.onSearchClick(searchTerm);
      });
  }

  /**
   * Handles the search functionality when the search input is updated.
   * This method retrieves the value from the search input, updates the query filter
   * with the search term if it's not empty, and then refreshes the table data based
   * on the updated query.
   */
  onSearchClick(searchTerm: string) {
    if (searchTerm) {
      this.query.filter.search = searchTerm;
    } else {
      delete this.query.filter.search;
    }
    this.tableComponent.query = this.query;
    this.tableComponent.refreshTableData();
  }
}
