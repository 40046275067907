import { User } from "../model/user/user.model";
import { BlankAPISuccess } from "./common.contract";

export enum HeaderTokenEnum {
  NoToken = "NoToken",
  Bearer = "Bearer",
}

export enum AuthStorageKey {
  jwt = "jwt",
  jwt_refresh = "jwt_refresh",
  jwt_token_type = "jwt_token_type",
}

export enum TokenScopes {
  "resetPassword" = "resetPassword",
}

export interface IJWTPayload {
  accessToken: string;
  refreshToken: string;
  tokenType: "Bearer";
}

export interface IJWTPayloadCode {
  status: boolean;
}

export interface IJWTPayloadDecoded {
  user: {
    id: string;
    email: string;
  };
  iat: number;
  exp: number;
}

export interface IAuthPayload {
  email: string;
  password: string;
  rememberMe: boolean;
  code: number | undefined;
}

export type IForgotPayload = {
  email: string;
};

export type IForgotResponse = BlankAPISuccess;

export type IResetPayload = {
  resetToken: string;
  password: string;
};

export type IResetResponse = BlankAPISuccess | User;

export type IValidateTokenPayload = {
  token: string;
  scope: string;
};

export type IValidateTokenResponse = BlankAPISuccess;

export interface auth {
  email?: string;
  password?: string;
  userId?: string;
}

export interface ITwoFactorPayload {
  userId: string;
  phoneNumber?: string;
  code?: number;
}
