import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, inject } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { CreateBidpoolButtonComponent } from "src/app/component/bidpool/create-bidpool-button/create-bidpool-button.component";
import { TableColumn, TableComponent } from "src/app/component/table/table.component";
import { WorkflowStepsEnum } from "src/app/contract/workflowStep.contract";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { BidpoolActivityLogs } from "src/app/model/bidpool/bidpool-action-logs.model";
import { BidpoolApplication } from "src/app/model/bidpool/bidpool-application.model";
import { BidpoolService } from "src/app/service/bidpool.service";

@Component({
  selector: "app-bidpool",
  standalone: true,
  imports: [
    AuthenticatedLayoutComponent,
    CreateBidpoolButtonComponent,
    MatTabsModule,
    TableComponent,
    CurrencyPipe,
  ],
  providers: [CurrencyPipe, DatePipe],
  templateUrl: "./bidpool.component.html",
})
export class BidpoolComponent {
  private router = inject(Router);
  private currencyPipe = inject(CurrencyPipe);
  protected bidpoolService = inject(BidpoolService);
  protected datePipe = inject(DatePipe);

  protected commonTableColumns: TableColumn<BidpoolApplication>[] = [
    {
      columnDef: "name",
      columnLabel: "Application name",
    },
    {
      columnDef: "contact",
      columnLabel: "Contact person",
      render: (element: BidpoolApplication) => element.contact?.fullName || "",
    },
    {
      columnDef: "regionalAlliance",
      columnLabel: "Regional alliance",
    },
    {
      columnDef: "workflowStep",
      columnLabel: "Status",
      render: (element: BidpoolApplication) => element.workflowStep.name,
    },
  ];

  protected inProgressTableColumns = [
    ...this.commonTableColumns,
    {
      columnDef: "amountRequested",
      columnLabel: "Total project",
      render: (element: BidpoolApplication) =>
        this.currencyPipe.transform(element.amountRequested, "USD", "symbol", "1.0-0") || "",
    },
  ];

  protected completedTableColumns = [
    ...this.commonTableColumns,
    {
      columnDef: "amountApproved",
      columnLabel: "Amount approved",
      render: (element: BidpoolApplication) =>
        this.currencyPipe.transform(element.amountApproved, "USD", "symbol", "1.0-0") || "",
    },
  ];

  protected actionLogTableColumns: TableColumn<BidpoolActivityLogs>[] = [
    {
      columnDef: "user",
      columnLabel: "Full Name",
      render: (element: BidpoolActivityLogs) => element.user?.fullName || "",
    },
    {
      columnDef: "roles",
      columnLabel: "Role",
      render: (element: BidpoolActivityLogs) => element.user?.roles[0].name || "",
    },
    {
      columnDef: "action",
      columnLabel: "Action",
      render: (element: BidpoolActivityLogs) =>
        element.auditAction.name + " " + element.objectType.name || "",
    },
    {
      columnDef: "date",
      columnLabel: "Date",
      render: (element: BidpoolActivityLogs) =>
        this.datePipe.transform(element.createdAt, "d MMMM y"),
    },
    {
      columnDef: "time",
      columnLabel: "Time",
      render: (element: BidpoolActivityLogs) =>
        this.datePipe.transform(element.createdAt, "HH: mm"),
    },
  ];

  protected inProgressFetchDataMethod = (query: Partial<IQueryFilter>) => {
    query.filter = { workflowStepId: [1, 2, 3, 4, 5, 6, 8] };
    return this.bidpoolService.fetchBidpoolApplications(query);
  };

  protected completedFetchDataMethod = (query: Partial<IQueryFilter>) => {
    query.filter = { workflowStepId: WorkflowStepsEnum.Completed };
    return this.bidpoolService.fetchBidpoolApplications(query);
  };

  protected handleBidpoolRowClick = (d: BidpoolApplication) => {
    if (d.workflowStepId === WorkflowStepsEnum.Draft) {
      this.router.navigate(["/bidpool", d.id], { queryParams: { mode: "edit" } });
    } else {
      this.router.navigate(["/bidpool", d.id], { queryParams: { mode: "view" } });
    }
  };

  protected activityLogsFetchDataMethod = (query: Partial<IQueryFilter>) =>
    this.bidpoolService.fetchActivityLogs(new IQueryFilter(query));
}
