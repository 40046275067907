import { Type } from "class-transformer";
import { BaseModel } from "../base.model";
import { RefAlliance } from "../ref/ref-alliance.model";
import { WorkflowStep } from "../workflow/workflow-step.model";
import { RCChairContact } from "./rc-chair-contact.model";
import { RCComingYear } from "./rc-coming-year.model";
import { RCCoordinatorPosition } from "./rc-coordinator-position.model";
import { RCKpi } from "./rc-kpi.model";
import { RCPreviousYear } from "./rc-previous-year.model";
import { RegionalCoordinatorUser } from "./rc-user.model";
import { RCWorkplan } from "./rc-workplan.model";

export class RCApplication extends BaseModel {
  id: number;
  name: string;
  allianceId: number;
  @Type(() => RefAlliance)
  alliance: RefAlliance | null;
  introduction: string;
  strategicObject: string;
  roleOfCoordinator: string;
  regionalMaturity: string;
  workflowStepId: number;

  @Type(() => WorkflowStep)
  workflowStep: WorkflowStep;
  amountRequested: number;
  @Type(() => RegionalCoordinatorUser)
  users: RegionalCoordinatorUser[];
  @Type(() => RCChairContact)
  chairContacts: RCChairContact[] = [];
  @Type(() => RCCoordinatorPosition)
  coordinatorPosition: RCCoordinatorPosition;
  @Type(() => RCKpi)
  rcKpis: RCKpi[] = [];
  @Type(() => RCPreviousYear)
  rcProjectPreviousYear: RCPreviousYear[] = [];
  @Type(() => RCComingYear)
  rcProjectComingYear: RCComingYear[] = [];
  @Type(() => RCWorkplan)
  rcAnnualWorkPlan: RCWorkplan[];

  public get participants(): RegionalCoordinatorUser[] {
    return (this.users || [])
      .filter((c) => c.type === "participant")
      .sort((a, b) => a.roles[0].id - b.roles[0].id);
  }

  /**
   * @description Main contact fullName
   */
  public get contact(): RegionalCoordinatorUser | null {
    return this.contacts[0] || null;
  }

  public get contacts(): RegionalCoordinatorUser[] {
    return (this.users || []).filter((c) => c.type === "contact");
  }

  /**
   * @description BidpoolApplication regional alliance, equivalent to Main contact regional alliance
   */
  public get regionalAlliance(): string {
    return this.alliance ? this.alliance.short : "";
  }
}
