import { User } from "../user/user.model";

export class RegionalCoordinatorUser extends User {
  RCUser: {
    type: "creator" | "contact" | "participant";
  };

  get type() {
    return this.RCUser.type;
  }
}
