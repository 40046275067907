import { Type } from "class-transformer";
import { PermissionsEnum, RolesEnum } from "../../contract/user.contract";
import { BaseModel } from "../base.model";
import { RefAlliance } from "../ref/ref-alliance.model";
import { RefOrganization } from "../ref/ref-organization.model";
import { UserRole } from "./user-role.model";

export type UserCreateOrUpdate = Partial<User> & {
  role?: {
    id: number;
  }[];
};

export class User extends BaseModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isActive: boolean;

  // Optional in Admin Users
  organisationName: string | null;

  // Optional in Adin Users
  allianceId?: string[] | null;
  @Type(() => RefAlliance)
  alliance?: RefAlliance[];

  @Type(() => UserRole)
  roles: UserRole[];

  /**
   * @description Given 1 or more permissionIds, checks all the users roles to see if they have all the permissions.
   * @param permissionIds
   * @returns true\false
   */
  public hasAllPermissions(permissionIds: PermissionsEnum[]): boolean {
    return permissionIds.every((permissionId) => {
      return this.roles.some((role) => {
        return role.hasPermission(permissionId);
      });
    });
  }

  public hasRole(roleId: RolesEnum): boolean {
    return this.roles.some((e) => e.id === roleId);
  }

  public hasSomeRoles(roles: RolesEnum[]): boolean {
    return this.roles.some((e) => roles.includes(e.id));
  }

  /**
   * @description Checks if the user has the admin role
   */
  public get isAdmin(): boolean {
    return this.roles.some((role) => {
      return role.isAdmin();
    });
  }

  /**
   * @description Concats firstName + lastName
   */
  public get fullName(): string {
    return `${!!this.firstName ? this.firstName + " " : ""}${!!this.lastName ? this.lastName : ""}`;
  }

  public get regionalAlliance(): string {
    return this.alliance?.length ? this.alliance[0].short : "";
  }
}
