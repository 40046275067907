import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PublicFooterComponent } from "src/app/component/public-footer/public-footer.component";
import { PublicHeaderComponent } from "src/app/component/public-header/public-header.component";
import { IJWTPayload } from "src/app/contract/auth.contract";
import { AuthService } from "src/app/service/auth.service";
import { JwtService } from "src/app/service/jwt.service";
import { LoadingService } from "src/app/service/loading.service";
import { UserService } from "src/app/service/user.service";
import { logger } from "src/app/util/logger.util";
import { emailValidator, sixDigitCodeValidator } from "src/app/util/validator.util";

const className = "TwoFactorComponent";

@Component({
  selector: "app-two-factor-auth",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PublicHeaderComponent,
    PublicFooterComponent,
    MatTooltipModule
  ],
  templateUrl: "./two-factor-auth.component.html",
})
export class TwoFactorAuthComponent implements OnInit {
  private loadingService = inject(LoadingService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private userService = inject(UserService);
  private jwtService = inject(JwtService);
  private fb = inject(FormBuilder);
  private toastrService = inject(ToastrService);
  private route = inject(ActivatedRoute);

  public loginForm: FormGroup;
  obj: { email?: string; password?: string; userId?: string };

  /**
   * @description This  function initializes the login form with validators, retrieves authentication values, and navigates to the appropriate page based on the user's authentication status. If the user is authenticated, it redirects to the default landing page; otherwise, it navigates to the login page.
   * @returns
   */

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: [null],
      password: [null],
      code: [
        "",
        {
          nonNullable: true,
          validators: [Validators.required, sixDigitCodeValidator],
        },
      ],
      userId: [null],
    });

    this.obj = this.authService.getAuthValues();

    if (this.authService.isAuthenticated()) {
      this.router.navigateByUrl(this.userService.defaultLandingPage);
      return;
    }
    if (this.obj == undefined) {
      this.router.navigate(["/login"]);
      return;
    }
  }

  /**
   * @description function sets the login form values, validates the form, and either calls the two-factor authentication or regular authentication API based on the user ID. If authentication is successful, it saves the JWT payload, sets the user, and navigates to the default landing page; otherwise, it redirects to the login page and resets the form.
   * @returns
   */

  onSubmit() {
    const signature = className + ".onSubmit: ";

    this.loginForm.controls.email.setValue(this.obj.email);
    this.loginForm.controls.password.setValue(this.obj.password);
    this.loginForm.controls.userId.setValue(this.obj.userId);

    if (this.loginForm.invalid) {
      this.toastrService.warning("Please enter your credentials");
      return;
    }

    const callApi = this.obj.userId
      ? this.authService.twoFactor(this.loginForm.value)
      : this.authService.authenticate(this.loginForm.value);
    this.loadingService.blockWithLoadingOverlayRx(callApi).subscribe({
      next: async (data: IJWTPayload) => {
        if (!this.jwtService.saveJWTData(data) || !this.authService.isAuthenticated()) {
          this.jwtService.removeJWTData();

          throw "Error Saving JWT Payload";
        }

        const userId = this.jwtService.currentJwtPayload$.value?.user.id;
        if (!userId) throw new Error("User ID not found in JWT Payload");

        await this.userService.setUser(userId);
        this.toastrService.success("Login Successful");
        logger.silly(signature + `Navigating to Url[${this.userService.defaultLandingPage}]`);
        this.router.navigateByUrl(this.userService.defaultLandingPage);
      },
      error: (error) => {
        this.router.navigate(["/login"]);
        this.loginForm.reset();
        const errorMsg = error?.error?.detail?.message || 'Unknown Error';
        this.toastrService.error(errorMsg);
        throw error;
      },
    });
  }

  /**
   * @description This function provides a convenient way to access the controls of the loginForm in a more readable manner.
   */
  get formControls() {
    return this.loginForm.controls;
  }
}
