import { Type } from "class-transformer";
import { RefStrategicPriority } from "../ref/ref-strategic-priority.model";

const stratagicPriorityDescription = [
  {
    label: "Enable regional scale delivery of sustainable services",
    description:
      "e.g. service sustainability framework, gap analysis, 3-year work plans based on gap analysis outcomes",
  },
  {
    label: "Build capacity & capability",
    description:
      "e.g. regional skills development initiatives, plan for emerging issues and embedding a culture of continuous improvement",
  },
  {
    label: "Drive regional partnerships services & initiatives",
    description:
      "e.g. transfer of project knowledge, learnings and experience between regions, identify and support regional champions and drive efficiencies across regions",
  },
  {
    label: "Promote QWRAP",
    description:
      "e.g. communications plans, consistent messaging, raising awareness with executives and elected representatives , promoting sustainability",
  },
];
export class BidpoolStrategicPriority {
  id: number;
  bidpoolApplicationId: number;
  refStrategicPriorityId: number;
  @Type(() => RefStrategicPriority)
  refStrategicPriority: RefStrategicPriority;
  value: string;

  get label() {
    return this.refStrategicPriority.name;
  }

  get description() {
    const stratagicDescription = stratagicPriorityDescription.find(
      (data) => data.label == this.refStrategicPriority.name,
    );
    if (stratagicDescription) {
      return stratagicDescription.description;
    }
    return "";
  }
}
