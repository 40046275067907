import { inject, Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { AllianceApi } from "../api/alliance.api";
import { TAPIListResult } from "../contract/api.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { RefAlliance } from "../model/ref/ref-alliance.model";
import { apiCallWrapper, inflateModel } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
export class AllianceService {
  private notifications = inject(ToastrService);
  private allianceApi = inject(AllianceApi);

  /**
   * @description This method is used to grab all the Alliance.
   * @param query
   * @returns
   */
  public async fetchAlliance(query: Partial<IQueryFilter>): Promise<TAPIListResult<RefAlliance>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.allianceApi.list(new IQueryFilter(query)), {
        notificationsService: this.notifications,
        action: "Fetching all Alliance",
      }),
    );

    return inflateModel(RefAlliance, data);
  }
}
