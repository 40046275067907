import { Injectable, inject } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { AuthApi } from "../api/auth.api";
import {
  auth,
  IAuthPayload,
  IForgotPayload,
  IJWTPayload,
  IResetPayload,
  ITwoFactorPayload,
  TokenScopes,
} from "../contract/auth.contract";
import { PermissionsEnum } from "../contract/user.contract";
import { apiCallWrapper } from "../util/api.util";
import { logger } from "../util/logger.util";
import { JwtService } from "./jwt.service";
import { UserService } from "./user.service";

const className = "AuthService";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private authApi = inject(AuthApi);
  private jwtService = inject(JwtService);
  private userService = inject(UserService);
  public notifications = inject(ToastrService);
  private obj: { email?: string; password?: string; userId?: string };

  /**
   * @description Refreshes the authentication token by making a request to the server using the provided JWT strings.
   * @param {string} jwtString - The access token JWT string.
   * @param {string} jwtRefreshString - The refresh token JWT string.
   * @returns {Observable<IJWTPayload>} - An observable that emits the refreshed JWT payload upon successful token refresh.
   * @public
   * @example
   * ```
   * const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Access token JWT string
   * const refreshToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...'; // Refresh token JWT string
   *
   * auth.refreshToken(accessToken, refreshToken)
   *   .subscribe(jwtPayload => {
   *     console.log(jwtPayload); // The refreshed JWT payload
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public readonly refreshToken = (
    jwtString: string,
    jwtRefreshString: string,
  ): Observable<IJWTPayload> => {
    return apiCallWrapper(
      this.authApi.refresh({
        accessToken: jwtString,
        refreshToken: jwtRefreshString,
      }),
      {
        notificationsService: this.notifications,
        action: "Refresh access token",
        failTitle: "",
        failMessage: "You have been disconnected",
      },
    );
  };

  /**
   * @description Resets the user's password by making a request to the server using the provided reset payload.
   * @param {IResetPayload} opts - The payload object for the password reset request.
   * @returns {Observable<any>} - An observable that emits the response from the password reset request.
   * @public
   * @example
   * ```
   * const resetPayload = {
   *   email: 'john@example.com',
   *   newPassword: 'newPassword123'
   * };
   *
   * auth.reset(resetPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the password reset request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public reset = (opts: IResetPayload) => {
    return apiCallWrapper(this.authApi.reset(opts), {
      notificationsService: this.notifications,
      action: "Reset password",
      showSuccessNotification: true,
      successTitle: "",
      successMessage: "You have successfully updated your password",
    });
  };

  /**
   * @description Resets the user's password by making a request to the server using the provided reset payload.
   * @param {IResetPayload} opts - The payload object for the password reset request.
   * @returns {Observable<any>} - An observable that emits the response from the password reset request.
   * @public
   * @example
   * ```
   * const setPayload = {
   *   resetToken: 'QWERTDFGBNM',
   *   password: 'newPassword123'
   * };
   *
   * auth.set(setPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the password reset request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public setPassword = (opts: IResetPayload) => {
    return apiCallWrapper(this.authApi.set(opts), {
      notificationsService: this.notifications,
      action: "Set password",
      showSuccessNotification: true,
      successTitle: "",
      successMessage: "You have successfully set your password",
    });
  };

  /**
   * @description Authenticates the user by making a request to the server using the provided authentication payload.
   * @param {IAuthPayload} opts - The payload object for the authentication request.
   * @returns {Observable<any>} - An observable that emits the response from the authentication request.
   * @public
   * @example
   * ```
   * const authPayload = {
   *   username: 'john@example.com',
   *   password: 'password123'
   * };
   *
   * auth.authenticate(authPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the authentication request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public authenticate = (opts: IAuthPayload) => {
    return this.authApi.authenticate(opts);
  };

  /**
   * @description Sends a forgot password request by making a request to the server using the provided forgot payload.
   * @param {IForgotPayload} opts - The payload object for the forgot password request.
   * @returns {Observable<any>} - An observable that emits the response from the forgot password request.
   * @public
   * @example
   * ```
   * const forgotPayload = {
   *   email: 'john@example.com'
   * };
   *
   * auth.forgot(forgotPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the forgot password request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public forgot = (opts: IForgotPayload) => {
    return apiCallWrapper(this.authApi.forgot(opts), {
      notificationsService: this.notifications,
      action: "Request reset password link",
    });
  };

  /**
   * @description Checks if the user is authenticated by evaluating the current JWT payload.
   * @returns {boolean} - A boolean value indicating whether the user is authenticated or not.
   * @public
   * @example
   * ```
   * const authenticated = auth.isAuthenticated();
   * console.log(authenticated); // true or false
   * ```
   */
  public isAuthenticated = () => this.jwtService.currentJwtPayload$.getValue() !== null;

  /**
   * @description Logs the user out by removing the JWT data from the storage service.
   * @example
   * ```
   * auth.logout();
   */
  public logout = () => {
    this.jwtService.removeJWTData();
    this.userService.clearUser();
  };

  public hasAccess = (permissionIds: PermissionsEnum[]): boolean => {
    const signature = className + ".hasAccess: ";
    const user = this.userService.currentUser;

    if (!user) {
      logger.warn(signature + `CurrentUser not found`);
      return false;
    }

    return user.hasAllPermissions(permissionIds);
  };

  public validateToken = (token: string, scope: TokenScopes) => {
    return apiCallWrapper(this.authApi.validateToken({ token, scope }), {
      notificationsService: this.notifications,
      action: `Request to validate ${scope} token`,
      failTitle:
        scope === TokenScopes.resetPassword ? "Invalid reset password link" : "Invalid token",
    });
  };

  /**
   * @description Sets the authentication values.
   * @param {auth} data - The authentication data to set.
   * @example
   * ```
   * authService.setAuthValues({ email: 'user@example.com', password: 'password123', userId: '12345' });
   * ```
   */
  setAuthValues(data: auth) {
    this.obj = data;
  }

  /**
   * @description Gets the authentication values.
   * @returns {Object} An object containing the authentication values.
   * @returns {string} [email] - The email of the user.
   * @returns {string} [password] - The password of the user.
   * @returns {string} [userId] - The user ID.
   * @example
   * ```
   * const authValues = authService.getAuthValues();
   * console.log(authValues.email); // Output: 'user@example.com'
   * ```
   */
  getAuthValues(): { email?: string; password?: string; userId?: string } {
    return this.obj;
  }

  /**
   * @description Authenticates the user by making a request to the server using the provided 2fa payload.
   * @param {IAuthPayload} opts - The payload object for the 2fa request.
   * @returns {Observable<any>} - An observable that emits the response from the 2fa request.
   * @public
   * @example
   * ```
   * const ITwoFactorPayload = {
   *   userId: '1',
   *   phoneNumber: 'password123',
   *   code: '1234'
   * };
   *
   * auth.twoFactor(ITwoFactorPayload)
   *   .subscribe(response => {
   *     console.log(response); // The response from the authentication request
   *   }, error => {
   *     console.error(error); // Handle error
   *   });
   * ```
   */
  public twoFactor = (opts: ITwoFactorPayload) => {
    return apiCallWrapper(this.authApi.twoFactor(opts), {
      notificationsService: this.notifications,
      action: "obtaning new keys",
      showSuccessNotification: true,
      successTitle: "obtaning new keys",
    });
  };
}
