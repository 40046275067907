<app-public-header></app-public-header>

<div class="welcome-wrap">
  <h3 class="mb-3">{{ isSetPassword ? "Set" : "Reset" }} password</h3>
  <form #form="ngForm" [formGroup]="resetPasswordForm" novalidate (submit)="onSubmit()">
    <div class="mb-3">
      <input
        class="form-control"
        [ngClass]="{
          'is-invalid':
            (form.submitted || formControls.password.touched) && formControls.password.invalid,
          'is-valid': formControls.password.valid,
        }"
        id="password"
        type="password"
        matTooltip="Enter New Password"
        placeholder="Enter New Password"
        formControlName="password"
        aria-label="Enter password"
      />
      <div
        *ngIf="
          formControls.password.invalid &&
          ((formControls.password.dirty && formControls.password.touched) || form.submitted)
        "
        class="invalid-feedback"
      >
        Password must be at least 8 characters long and contain at least one uppercase letter and
        one symbol
      </div>
    </div>
    <div class="mb-3">
      <input
        class="form-control"
        [ngClass]="{
          'is-invalid':
            (form.submitted || formControls.confirmPassword.touched) &&
            formControls.confirmPassword.invalid,
          'is-valid': formControls.confirmPassword.valid,
        }"
        id="confirmPassword"
        type="password"
        matTooltip="Confirm New Password"
        placeholder="Confirm New Password"
        formControlName="confirmPassword"
        aria-label="Confirm password"
      />
      <div
        *ngIf="
          formControls.confirmPassword.invalid &&
          ((formControls.confirmPassword.dirty && formControls.confirmPassword.touched) ||
            form.submitted)
        "
        class="invalid-feedback"
      >
        The passwords don't match
      </div>
    </div>
    <div class="mb-3">
      <button type="submit" class="max-w-full btn-primary-new w-100">Submit</button>
    </div>
  </form>
</div>
<app-public-footer></app-public-footer>
