import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, inject } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { CreateRcButtonComponent } from "src/app/component/rc-application/create-rc-button/create-rc-button.component";
import { TableColumn, TableComponent } from "src/app/component/table/table.component";
import { RCWorkflowStepsEnum } from "src/app/contract/workflowStep.contract";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { RCApplication } from "src/app/model/regional-coordinator/rc-appication.model";
import { RegionalCoordinatorService } from "src/app/service/regional-coordinator.service";

@Component({
  selector: "app-regional-coordinator",
  standalone: true,
  imports: [
    AuthenticatedLayoutComponent,
    CreateRcButtonComponent,
    MatTabsModule,
    TableComponent,
    CurrencyPipe,
    DatePipe,
  ],
  providers: [CurrencyPipe, DatePipe],
  templateUrl: "./regional-coordinator.component.html",
})
export class RegionalCoordinatorComponent {
  private router = inject(Router);
  private currencyPipe = inject(CurrencyPipe);
  protected rcService = inject(RegionalCoordinatorService);
  protected datePipe = inject(DatePipe);

  protected commonTableColumns: TableColumn<RCApplication>[] = [
    {
      columnDef: "name",
      columnLabel: "Application name",
    },
    {
      columnDef: "contact",
      columnLabel: "Contact person",
      render: (element: RCApplication) => element.contact?.fullName || "",
    },
    {
      columnDef: "regionalAlliance",
      columnLabel: "Regional alliance",
    },
    {
      columnDef: "workflowStep",
      columnLabel: "Status",
      render: (element: RCApplication) => element.workflowStep.name,
    },
  ];

  protected inProgressTableColumns = [
    ...this.commonTableColumns,
    {
      columnDef: "amountRequested",
      columnLabel: "Amount requested",
      render: (element: RCApplication) =>
        this.currencyPipe.transform(element.amountRequested, "USD", "symbol", "1.0-0") || "",
    },
  ];

  protected inProgressFetchDataMethod = (query: Partial<IQueryFilter>) => {
    query.filter = { workflowStepId: [9, 10, 11, 12, 13] };
    return this.rcService.fetchRCApplications(query);
  };

  protected completedFetchDataMethod = (query: Partial<IQueryFilter>) => {
    query.filter = { workflowStepId: RCWorkflowStepsEnum.Completed };
    return this.rcService.fetchRCApplications(query);
  };

  protected completedTableColumns = [
    ...this.commonTableColumns,
    {
      columnDef: "amountApproved",
      columnLabel: "Amount approved",
      render: (element: RCApplication) =>
        this.currencyPipe.transform(element.amountRequested, "USD", "symbol", "1.0-0") || "",
    },
  ];

  protected handleRCRowClick = (d: RCApplication) => {
    if (d.workflowStepId === RCWorkflowStepsEnum.Draft) {
      this.router.navigate(["/regional-coordinator", d.id], { queryParams: { mode: "edit" } });
    } else {
      this.router.navigate(["/regional-coordinator", d.id], { queryParams: { mode: "view" } });
    }
  };
}
