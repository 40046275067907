import { Type } from "class-transformer";
import { BaseModel } from "./base.model";
import { User } from "./user/user.model";

export type TDocumentCreate = Partial<SupportingDocument>;

export class SupportingDocument extends BaseModel {
  id: number;
  uploadedBy: number;

  @Type(() => User)
  user: User;

  documentName: string;
  documentType: string;
  documentFile: string;
  createdAt: string;
  public get uploader(): string {
    return this.user ? this.user.firstName + " " + this.user.lastName : "";
  }
}
