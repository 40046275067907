export class RCCoordinatorPosition {
  id: number;
  rcApplicationId: number;
  name?: string;
  phone?: string;
  email?: string;
  startDate?: string;
  positionDescription?: boolean;

  public patchValue(value: Partial<RCCoordinatorPosition>) {
    const { name, phone, email, startDate, positionDescription } = value;

    if (name !== undefined) this.name = name;
    if (phone !== undefined) this.phone = phone;
    if (email !== undefined) this.email = email;
    if (startDate !== undefined) this.startDate = startDate;
    if (positionDescription !== undefined) this.positionDescription = positionDescription;
  }
}
