import { CommonModule } from "@angular/common";
import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { MatCheckboxChange, MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { TAPIListResult } from "src/app/contract/api.contract";
import { UserRole } from "src/app/model/user/user-role.model";
import { RoleService } from "src/app/service/role.service";

@Component({
  selector: "app-user-role",
  standalone: true,
  imports: [CommonModule, MatInputModule, MatCheckboxModule],
  templateUrl: "./user-role.component.html",
  styleUrls: ["./user-role.component.scss"],
})
export class UserRoleComponent implements OnInit {
  protected userRoleService = inject(RoleService);
  public rolesListing: TAPIListResult<UserRole> = { count: 0, rows: [] };
  public selectedRoles: Set<number> = new Set();

  @Input() existRole: { id: number; name: string }[] = [];
  @Output() rolesChanged = new EventEmitter<{ role: UserRole; checked: boolean }>();

  ngOnInit(): void {
    this.fetchUserRole();
  }

  async fetchUserRole() {
    await this.userRoleService.fetchUserRole({
      limit: 50
    }).then((res) => {
      this.rolesListing = res;

      // Mark roles as selected if they exist in existRole
      this.existRole.forEach((role) => {
        this.selectedRoles.add(role.id);
      });
    });
  }

  toggleRoleSelection(role: UserRole, event: MatCheckboxChange) {
    const target = event.checked;
    this.rolesChanged.emit({ role: role, checked: target });

    if (target) {
      this.selectedRoles.add(role.id);
    } else {
      this.selectedRoles.delete(role.id);
    }
  }

  getSelectedRolesCount(): number {
    return this.selectedRoles.size;
  }
}
