import { CurrencyPipe } from "@angular/common";
import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { CreateBidpoolButtonComponent } from "src/app/component/bidpool/create-bidpool-button/create-bidpool-button.component";
import { CreateRcButtonComponent } from "src/app/component/rc-application/create-rc-button/create-rc-button.component";
import { TableColumn, TableComponent } from "src/app/component/table/table.component";
import { RCWorkflowStepsEnum, WorkflowStepsEnum } from "src/app/contract/workflowStep.contract";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { BidpoolApplication } from "src/app/model/bidpool/bidpool-application.model";
import { RCApplication } from "src/app/model/regional-coordinator/rc-appication.model";
import { BidpoolService } from "src/app/service/bidpool.service";
import { RegionalCoordinatorService } from "src/app/service/regional-coordinator.service";

@Component({
  selector: "app-home",
  standalone: true,
  imports: [
    AuthenticatedLayoutComponent,
    CreateBidpoolButtonComponent,
    TableComponent,
    CreateRcButtonComponent,
  ],
  providers: [CurrencyPipe],
  templateUrl: "./home.component.html",
})
export class HomeComponent {
  private router = inject(Router);
  private currencyPipe = inject(CurrencyPipe);
  protected bidpoolService = inject(BidpoolService);
  protected rcService = inject(RegionalCoordinatorService);

  // Table
  protected bidpoolApplicationsTableColumns: TableColumn<BidpoolApplication>[] = [
    {
      columnDef: "name",
      columnLabel: "Application name",
    },
    {
      columnDef: "contact",
      columnLabel: "Contact person",
      render: (element: BidpoolApplication) => element.contact?.fullName || "",
    },
    {
      columnDef: "regionalAlliance",
      columnLabel: "Regional alliance",
    },
    {
      columnDef: "workflowStep",
      columnLabel: "Status",
      render: (element: BidpoolApplication) => element.workflowStep.name,
    },
    {
      columnDef: "amountRequested",
      columnLabel: "Total project",
      render: (element: BidpoolApplication) =>
        this.currencyPipe.transform(element.amountRequested, "USD", "symbol", "1.0-0") || "",
    },
  ];

  protected rcApplicationsTableColumns: TableColumn<RCApplication>[] = [
    {
      columnDef: "name",
      columnLabel: "Application name",
    },
    {
      columnDef: "contact",
      columnLabel: "Contact person",
      render: (element: RCApplication) => element.contact?.fullName || "",
    },
    {
      columnDef: "regionalAlliance",
      columnLabel: "Regional alliance",
    },
    {
      columnDef: "workflowStep",
      columnLabel: "Status",
      render: (element: RCApplication) => element.workflowStep.name,
    },
    {
      columnDef: "amountRequested",
      columnLabel: "Amount requested",
      render: (element: RCApplication) =>
        this.currencyPipe.transform(element.amountRequested, "USD", "symbol", "1.0-0") || "",
    },
  ];

  protected bidpoolApplicationsFetchDataMethod = (query: Partial<IQueryFilter>) =>
    this.bidpoolService.fetchBidpoolApplications(query);

  protected rcApplicationsFetchDataMethod = (query: Partial<IQueryFilter>) =>
    this.rcService.fetchRCApplications(query);

  protected handleBidpoolRowClick = (d: BidpoolApplication) => {
    if (d.workflowStepId === WorkflowStepsEnum.Draft) {
      this.router.navigate(["/bidpool", d.id], { queryParams: { mode: "edit" } });
    } else {
      this.router.navigate(["/bidpool", d.id], { queryParams: { mode: "view" } });
    }
  };

  protected handleRCRowClick = (d: RCApplication) => {
    if (d.workflowStepId === RCWorkflowStepsEnum.Draft) {
      this.router.navigate(["/regional-coordinator", d.id], { queryParams: { mode: "edit" } });
    } else {
      this.router.navigate(["/regional-coordinator", d.id], { queryParams: { mode: "view" } });
    }
  };
}
