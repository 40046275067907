import { inject, Injectable } from "@angular/core";
import { plainToInstance } from "class-transformer";
import { ToastrService } from "ngx-toastr";
import { lastValueFrom } from "rxjs";
import { DocumentApi } from "../api/document.api";
import { TAPIListResult } from "../contract/api.contract";
import { IQueryFilter } from "../model/api/query-filter.model";
import { SupportingDocument, TDocumentCreate } from "../model/document.model";
import { apiCallWrapper, inflateModel } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  private notifications = inject(ToastrService);
  private documentApi = inject(DocumentApi);

  /**
   * @description This method is used to grab all supporting documents.
   * @param query
   * @returns
   */
  public async fetchDocumnets(
    query: Partial<IQueryFilter>,
  ): Promise<TAPIListResult<SupportingDocument>> {
    const data = await lastValueFrom(
      apiCallWrapper(this.documentApi.list(new IQueryFilter(query)), {
        notificationsService: this.notifications,
        action: "Fetching Documents",
      }),
    );

    return inflateModel(SupportingDocument, data);
  }

  /**
   * @description This method is used to Create any document.
   * @param body
   * @returns
   */
  public async create(body: Partial<TDocumentCreate>): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.create(body), {
        notificationsService: this.notifications,
        action: "Create document",
      }),
    );

    return;
  }

  /**
   * @description This method is used to Archive any particular doc.
   * @param id
   * @returns
   */
  public async delete(id: number): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.delete(id), {
        notificationsService: this.notifications,
        action: "Delete Document",
      }),
    );

    return;
  }

  /**
   * @description This method is used to  any Get prticular doc.
   * @param id
   * @returns
   */
  public async get(id: number): Promise<SupportingDocument> {
    const data = await lastValueFrom(
      apiCallWrapper(this.documentApi.get(id), {
        notificationsService: this.notifications,
        action: "Fetch Document",
      }),
    );

    return plainToInstance(SupportingDocument, data);
  }

  /**
   * @description This method is used to Create any document.
   * @param id
   * @param body
   * @returns
   */
  public async update(id: number, body: Partial<TDocumentCreate>): Promise<void> {
    await lastValueFrom(
      apiCallWrapper(this.documentApi.update(id, body), {
        notificationsService: this.notifications,
        action: "Update document",
      }),
    );

    return;
  }
}
