import { FormGroup } from '@angular/forms';
import { logger } from './logger.util';

/**
 * @description Logs errors for each control in the provided FormGroup recursively.
 *
 * @param {FormGroup} formGroup - The FormGroup to log errors for.
 * @returns {void}
 */
export function logFormErrors(formGroup: FormGroup): void {
  Object.keys(formGroup.controls).forEach(key => {
    const control = formGroup.get(key);
    if (control instanceof FormGroup) {
      logFormErrors(control); // Recursively check nested form groups
    } else {
      if (control && control.errors !== null) {
        logger.warn(`Control: ${key}, Errors: ${JSON.stringify(control.errors)}`);
      }
    }
  });
}
