export const API_ASSOCIATIONS = {
  bidpoolApplications: {
    order: "desc",
    sortBy: "updatedAt",
    include: [{ name: "workflowStep" }],
  },

  rcApplications: {
    order: "desc",
    sortBy: "updatedAt",
    include: [{ name: "workflowStep" }],
  },
};
