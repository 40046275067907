import { CommonModule, DatePipe } from "@angular/common";
import { AfterViewInit, Component, ElementRef, inject, OnInit, ViewChild } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, fromEvent, map } from "rxjs";
import { AuthenticatedLayoutComponent } from "src/app/component/authenticated-layout/authenticated-layout.component";
import { TableColumn, TableComponent } from "src/app/component/table/table.component";
import { TAPIListResult } from "src/app/contract/api.contract";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { RefAlliance } from "src/app/model/ref/ref-alliance.model";
import { UserRole } from "src/app/model/user/user-role.model";
import { User } from "src/app/model/user/user.model";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "app-user-list",
  standalone: true,
  imports: [
    AuthenticatedLayoutComponent,
    MatTableModule,
    MatPaginatorModule,
    TableComponent,
    CommonModule,
    MatIcon,
    MatTooltipModule,
  ],
  providers: [DatePipe],
  templateUrl: "./user-list.component.html",
})
export class UserListComponent implements OnInit, AfterViewInit {
  protected userService = inject(UserService);
  protected router = inject(Router);
  protected datePipe = inject(DatePipe);
  public query: IQueryFilter = new IQueryFilter({ limit: 10 });
  @ViewChild(TableComponent) tableComponent!: TableComponent<null>;
  @ViewChild("searchInput") searchInput!: ElementRef;

  userListing: TAPIListResult<User> = { count: 0, rows: [] };
  private debounceInMs = 1000;

  protected userListingTableColumns: TableColumn<User>[] = [
    {
      columnDef: "SN.",
      columnLabel: "SN.",
      render: (element: User) => element.id,
    },
    {
      columnDef: "name",
      columnLabel: "Name",
      render: (element: User) => element.firstName,
    },
    {
      columnDef: "roles",
      columnLabel: "Roles",
      render: (element: User) =>
        (element.roles || []).map((role: UserRole) => role.name).join(", "),
    },
    {
      columnDef: "email",
      columnLabel: "E-mail",
      render: (element: User) => element.email,
    },
    {
      columnDef: "phone number",
      columnLabel: "Phone",
      render: (element: User) => element.phone,
    },
    {
      columnDef: "organisationName",
      columnLabel: "Organisation",
    },
    {
      columnDef: "alliance",
      columnLabel: "Alliance",
      render: (element: User) =>
        (element.alliance || []).map((data: RefAlliance) => data.short).join(", "),
    },
    {
      columnDef: "createdAt",
      columnLabel: "Created",
      render: (element: User) => this.datePipe.transform(element.createdAt, "d MMMM y"),
    },
    {
      columnDef: "action",
      columnLabel: "Action",
      render: () => "",
      actions: (element: User) => [
        {
          type: "button",
          label: "",
          icon: "edit",
          action: () => this.editUser(element.id),
        },
      ],
    },
  ];

  protected userListFetchDataMethod = (query: Partial<IQueryFilter>) =>
    this.userService.list(query);

  private async _fetchApplications(query: Partial<IQueryFilter>) {
    this.userListing = await this.userService.list(query);
  }

  ngOnInit(): void {
    this._fetchApplications(this.query);
  }

  ngAfterViewInit() {
    this.setupSearchDebounce();
  }

  editUser(userId: any) {
    this.router.navigate(["/user", userId]);
  }

  async toggleActiveStatus(element: User, checked: boolean) {
    if (element.id) {
      element.isActive = checked;
      await this.userService.update(element.id, { isActive: checked });
    }
  }

  onTableAction({ action, element }: { action: (element: User) => void; element: User }) {
    action(element);
  }

  /**
   * @description Sets up debounce for the search input field
   */
  setupSearchDebounce() {
    fromEvent(this.searchInput.nativeElement, "input")
      .pipe(
        map((event: any) => event.target.value),
        debounceTime(this.debounceInMs),
        distinctUntilChanged(),
      )
      .subscribe((searchTerm: string) => {
        this.onSearchClick(searchTerm);
      });
  }

  /**
   * Handles the search functionality when the search input is updated.
   * This method retrieves the value from the search input, updates the query filter
   * with the search term if it's not empty, and then refreshes the table data based
   * on the updated query.
   */
  onSearchClick(searchTerm: string) {
    if (searchTerm) {
      this.query.filter.search = searchTerm;
    } else {
      delete this.query.filter.search;
    }
    this.tableComponent.query = this.query;
    this.tableComponent.refreshTableData();
  }
}
