import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { HasId } from "../contract/common.contract";
import { IQueryFilter, QueryResult } from "../model/api/query-filter.model";
import { Calender, EventCreateOrUpdate } from "../model/calender/calender.model";
import { createUrl, queryToParams } from "../util/api.util";
import { GenericApi } from "./generic.api";

// Convenience Type
type T = Calender;
type I = Calender;

@Injectable({
  providedIn: "root",
})
export class CalenderApi extends GenericApi<T> {
  public path = "calendar";
  public httpClient = inject(HttpClient);

  list = (query: IQueryFilter): Observable<QueryResult<T & HasId>> => {
    return this.httpClient.get<QueryResult<T & HasId>>(createUrl(this.path), {
      params: queryToParams(query),
    });
  };

  create = (data: EventCreateOrUpdate): Observable<T & HasId> => {
    return this.httpClient.post<T & HasId>(createUrl(this.path, "create"), data);
  };
}
