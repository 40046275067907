import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { Router } from "@angular/router";
import { provideServiceWorker } from "@angular/service-worker";
import * as Sentry from "@sentry/angular";
import * as AOS from "aos";
import { provideToastr } from "ngx-toastr";
import "reflect-metadata";
import { AppComponent } from "./app/app.component";
import { routes } from "./app/app.routes";
import { JwtInterceptor } from "./app/interceptor/jwt.interceptor";
import { ModalService } from "./app/service/modal.service";
import { logger } from "./app/util/logger.util";
import { environment } from "./environments/environment";

logger.info(`Starting Application. BuildId[${environment.buildId}]`);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideServiceWorker("ngsw-worker.js", {
      enabled: environment.enableServiceWorker,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    provideToastr({ positionClass: "toaster-fixed" }),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService, ModalService],
      multi: true,
    },
  ],
})
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    if (window["ngRef"]) {
      window["ngRef"].destroy();
    }
    window["ngRef"] = ref;

    // Otherwise, log the boot error
  })
  .catch((err) => console.error(err));

AOS.init();
