import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../service/auth.service";
import { JwtService } from "../service/jwt.service";
import { UserService } from "../service/user.service";
import { logger } from "../util/logger.util";

const className = "AuthGuard";

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const jwtService = inject(JwtService);
  const userService = inject(UserService);
  const router = inject(Router);

  const signature = className + ".canActivate: ";
  const fallbackUrlTree = router.createUrlTree(["/login"]);

  if (userService.currentUser) {
    // currentUser data is loaded
    logger.silly(signature + `Found known User[${userService.currentUser.id}]`);
    return true;
  } else if (authService.isAuthenticated()) {
    // there is a valid JWT but we need to load the currentUser data
    const userId = jwtService.currentJwtPayload$.value?.user.id;
    if (!userId) {
      return fallbackUrlTree;
    }

    return new Promise(async (resolve) => {
      logger.silly(signature + `Loading User[${userId}]`);
      const result = await userService.setUser(userId);
      resolve(result || fallbackUrlTree);
    });
  } else {
    return fallbackUrlTree;
  }
};
