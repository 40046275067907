import { User } from "../user/user.model";

export class BidpoolDocument {
  id: number;
  description: string;
  documentFile: string;
  uploadedBy: string;
  bidpoolId: number;
  createdAt: string;
  user: User;
}
