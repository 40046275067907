import { CommonModule } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PublicFooterComponent } from "src/app/component/public-footer/public-footer.component";
import { PublicHeaderComponent } from "src/app/component/public-header/public-header.component";
import { IJWTPayload } from "src/app/contract/auth.contract";
import { AuthService } from "src/app/service/auth.service";
import { LoadingService } from "src/app/service/loading.service";
import { UserService } from "src/app/service/user.service";
import { logger } from "src/app/util/logger.util";

const className = "VerifyPhoneComponent";

@Component({
  selector: "app-varify-phone",
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PublicHeaderComponent,
    PublicFooterComponent,
    MatTooltipModule
  ],
  templateUrl: "./varify-phone.component.html",
})
export class VarifyPhoneComponent implements OnInit {
  private loadingService = inject(LoadingService);
  private fb = inject(FormBuilder);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private authService = inject(AuthService);
  private userService = inject(UserService);
  private toastrService = inject(ToastrService);

  public form: FormGroup;
  public userId: string | undefined;

  /**
   * @description  function sets up the form, redirects authenticated users to the default page, and loads user data into the form if a userId is provided in the query parameters.
   * @returns
   */

  ngOnInit() {
    this.form = this.fb.group({
      phone: ["", Validators.required],
      userId: [null],
    });

    if (this.authService.isAuthenticated()) {
      this.router.navigateByUrl(this.userService.defaultLandingPage);
      return;
    }

    this.userId = this.route.snapshot.queryParams.userId;

    if (this.userId) {
      this.userService.fetchSingleUser(this.userId).then((user) => {
        this.form.patchValue({ phone: user.phone });
      });
    }
  }

  /**
   * @description  function updates the form with the user ID, validates the form, and performs a two-factor authentication request. Upon success, it navigates to the verification page; if an error occurs, it resets the form and logs the error.
   * @returns
   */

  onSubmit() {
    const signature = className + ".onSubmit: ";

    this.form.controls.userId.setValue(this.userId);
    this.authService.setAuthValues({ userId: this.userId });

    if (this.form.invalid) {
      this.toastrService.warning("Please enter your phone");
      return;
    }

    this.authService.setAuthValues(this.form.value);

    this.loadingService
      .blockWithLoadingOverlayRx(this.authService.twoFactor(this.form.value))
      .subscribe({
        next: async () => {
          logger.silly(signature + `Navigating to Url['varify-thats-you']]`);
          this.router.navigate(["/verify-thats-you"]);
        },
        error: (error) => {
          this.form.reset();
          throw error;
        },
      });
  }

  /**
   * @description This function provides a convenient way to access the controls of the form in a more readable manner.
   */
  get formControls() {
    return this.form.controls;
  }
}
