<app-authenticated-layout>
  <div class="maintbcon">
    <h2>Documents library</h2>
    <div class="maintbs">
      <div class="mb-3 d-flex align-items-center justify-content-between">
        <button
          *ngIf="isAdmin"
          class="btn-regular-blue"
          type="button"
          (click)="openDocumnetModel()"
        >
          <mat-icon fontIcon="upload" />
          UPLOAD Document
        </button>
        <div class="refresh-wrap d-flex justify-content-xs-center justify-content-end mt-2">
          <div class="text-side">
            <input type="text" placeholder="Search..." #searchInput />
          </div>
          <div class="refresh-btn">
            <button type="button" class="d-flex text-white pe-none">
              <img src="../../../assets/images/searchicon.svg" alt="" />
            </button>
          </div>
        </div>
      </div>

      <app-table
        [tableColumns]="documnetTableColumns"
        [fetchDataMethod]="documentListFetchDataMethod"
        [usePagination]="true"
      />
    </div>
  </div>
</app-authenticated-layout>

<ng-template #documentModel let-modal>
  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">Upload Document</h1>
    <button mat-icon-button type="button" class="btn-close" (click)="close()"></button>
  </div>
  <form [formGroup]="documentForm">
    <div class="modal-body newinputs newformmodal">
      <mat-form-field appearance="fill" class="">
        <mat-label>Document Name</mat-label>
        <input matInput type="text" id="name" formControlName="documentName" />
      </mat-form-field>

      <div class="form-group">
        <label class="form-label" for="">Document Type</label>
        <select class="form-select" formControlName="documentType" required>
          <option value="">Select Document Type</option>
          <option value="Annual report">Annual report</option>
          <option value="Meetings">Meetings</option>
          <option value="Projects and bidpools applications">
            Projects and bidpools applications
          </option>
          <option value="Annual meeting">Annual meeting</option>
          <option value="Close out report">Close out report</option>
          <option value="Photos">Photos</option>
          <option value="Superceeded">Superceeded</option>
          <option value="Coordinator folder">Coordinator folder</option>
        </select>
      </div>

      <mat-form-field appearance="fill" class="mt-3">
        <mat-label
          >File
          <p class="mat-typography fs-10 text-muted text-wrap">
            XLSX, DOCX, PPTX,PDF,JPG,PNG,TXT,CSV
          </p>
        </mat-label>
        <input
          matInput
          type="file"
          id="docFile"
          (change)="persistS3Image($event)"
          accept=".xlsx, .docx, .pptx, .pdf, .jpg, .jpeg, .png, .txt, .csv"
        />
      </mat-form-field>
      <div *ngIf="isUploading">Uploading...</div>
    </div>
  </form>

  <div class="modal-footer">
    <button class="secondbtn" (click)="close()">Cancel</button>
    <button class="createbtn" (click)="onSubmit()" [disabled]="!documentForm.valid">
      {{ currentDocument ? "Update" : "Save" }}
    </button>
  </div>
</ng-template>
