import { Overlay, OverlayConfig } from "@angular/cdk/overlay";
import { CdkPortal, PortalModule } from "@angular/cdk/portal";
import { Component, ViewChild, ViewEncapsulation, inject } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { LoadingService } from "./service/loading.service";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterModule, PortalModule, MatProgressSpinnerModule],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  @ViewChild(CdkPortal) portal!: CdkPortal;

  private loadingService = inject(LoadingService);
  private overlay = inject(Overlay);
  private iconRegistry = inject(MatIconRegistry);

  constructor() {
    this.initLoadingOverlay();
    this.initIconRegistry();
  }

  private initLoadingOverlay() {
    const config = new OverlayConfig({
      width: "100%",
      height: "100%",
      panelClass: "global-loading",
    });
    const overlayRef = this.overlay.create(config);

    this.loadingService.isLoadingChange.subscribe({
      next: (isLoading) => {
        if (isLoading) {
          overlayRef.attach(this.portal);
        } else {
          overlayRef.detach();
        }
      },
    });
  }

  private initIconRegistry() {
    const defaultFontSetClasses = this.iconRegistry.getDefaultFontSetClass();
    const outlinedFontSetClasses = defaultFontSetClasses
      .filter((fontSetClass) => fontSetClass !== "material-icons")
      .concat(["material-symbols-outlined"]);
    this.iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
  }
}
