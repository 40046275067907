import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { RolesEnum } from "../contract/user.contract";
import { UserService } from "../service/user.service";
import { logger } from "../util/logger.util";

const className = "RoleGuard";

export const roleGuard: CanActivateFn = (route, state) => {
  const signature = className + ".canActivate: ";

  const toastService = inject(ToastrService);
  const router = inject(Router);
  const userService = inject(UserService);

  const urlTree = router.createUrlTree([userService.defaultLandingPage]);
  const roles = route.data["roles"] as RolesEnum[] | undefined;

  if (!roles || !roles.length) {
    logger.silly(signature + `Route has no role requirement`);
    return true;
  }

  if (!userService.currentUser) {
    return urlTree;
  }

  if (userService.currentUser.hasSomeRoles(roles)) {
    return true;
  }

  logger.silly(signature + `User lacks any Role[${roles.join(",")}]`);
  toastService.error("You don't have access to this page");
  return urlTree;
};
