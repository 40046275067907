export const OtherOrgsContributorName = "_OTHER_ORG_";
export const BPRequestContributorName = "_BP_REQUEST_";

export class BidpoolContribution {
  id: number;
  bidpoolApplicationId: number;
  contributor?: string;
  cash?: number;
  inkind?: number;
  isOtherOrganisation?: boolean;

  public patchValue(value: Partial<BidpoolContribution>) {
    const { contributor, cash, inkind } = value;

    if (contributor !== undefined) this.contributor = contributor;
    if (cash !== undefined) this.cash = cash;
    if (inkind !== undefined) this.inkind = inkind;
  }
}
