import { Component, inject, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LoadingService } from "src/app/service/loading.service";
import { RegionalCoordinatorService } from "src/app/service/regional-coordinator.service";
import { AccessName, UserService } from "src/app/service/user.service";

@Component({
  selector: "app-create-rc-button",
  standalone: true,
  imports: [MatIconModule],
  templateUrl: "./create-rc-button.component.html",
})
export class CreateRcButtonComponent implements OnInit, OnDestroy {
  private loadingService = inject(LoadingService);
  private router = inject(Router);
  private userService = inject(UserService);
  private regionalCoordinatorService = inject(RegionalCoordinatorService);
  private subscriptions: Subscription[] = [];

  protected hasAccess = false;
  protected isChecklistCompleted = false;

  ngOnInit(): void {
    this.hasAccess = this.userService.hasAccess({
      accessName: AccessName.CreateRegionalCoordinatorApplication,
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((e) => e.unsubscribe());
    }
  }

  public async createRCApplication() {
    await this.loadingService.blockWithLoadingOverlayPromise(async () => {
      const createdRCApplication = await this.regionalCoordinatorService.createRCApplication();
      this.router.navigate(["/regional-coordinator", createdRCApplication.id], {
        queryParams: { mode: "edit" },
      });
    });
  }
}
