import { Injectable, inject } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject } from "rxjs";
import { IJWTPayload, IJWTPayloadDecoded } from "../contract/auth.contract";
import { logger } from "../util/logger.util";
import { StorageServiceKey } from "./storage.service";

const className = "JwtService";

@Injectable({
  providedIn: "root",
})
export class JwtService {
  public storageService = inject(CookieService);

  // currentJwtPayload should reflect the value currently stored in cookies and be used as a proxy for the current known JWT payload
  public currentJwtPayload$: BehaviorSubject<IJWTPayloadDecoded | null> =
    new BehaviorSubject<IJWTPayloadDecoded | null>(null);

  constructor() {
    const jwt = this.decodeJWT();
    if (jwt) {
      this.currentJwtPayload$.next(jwt);
    }
  }

  public saveJWTData(jwtPayload: IJWTPayload): boolean {
    const signature = className + ".saveJWTData: ";
    /**
     * Verify jwt before saving
     */
    const payload = this.decodeJWT(jwtPayload.accessToken);

    if (!payload) {
      logger.silly(signature + "Jwt was not valid and was rejected");
      return false;
    }

    const payloadVerified = this.verifyJWT(payload);

    if (!payloadVerified) {
      logger.silly(signature + "Jwt did not pass verification and was rejected");
      return false;
    }

    this.storageService.set(
      StorageServiceKey.jwt,
      jwtPayload.accessToken,
      30,
      undefined,
      undefined,
      true,
      "Strict",
    );
    this.storageService.set(
      StorageServiceKey.jwt_refresh,
      jwtPayload.refreshToken,
      30,
      undefined,
      undefined,
      true,
      "Strict",
    );
    this.storageService.set(
      StorageServiceKey.jwt_token_type,
      jwtPayload.tokenType,
      30,
      undefined,
      undefined,
      true,
      "Strict",
    );
    this.currentJwtPayload$.next(payload);

    return true;
  }

  public getJWTString(): string {
    return this.storageService.get(StorageServiceKey.jwt) || "";
  }

  public getJWTRefreshString(): string {
    return this.storageService.get(StorageServiceKey.jwt_refresh) || "";
  }

  public getJWTTypeString(): string {
    return this.storageService.get(StorageServiceKey.jwt_token_type) || "";
  }

  private decodeJWT(token?: string): IJWTPayloadDecoded | null {
    const signature = className + ".decodeJWT: ";
    const jwt = token || this.storageService.get(StorageServiceKey.jwt);
    let payload: IJWTPayloadDecoded;

    if (!jwt) {
      logger.silly(signature + "JWT is null");
      return null;
    }

    try {
      logger.silly(signature + `Decoding JWT[${jwt}]`);
      payload = jwtDecode(jwt);
    } catch (e) {
      logger.silly(signature + "Decoding Error");
      console.log(e);
      return null;
    }

    if (!payload) {
      logger.silly(signature + "Payload is null");
      return null;
    }

    return payload;
  }

  public verifyJWT(token?: string | IJWTPayloadDecoded): boolean {
    const signature = className + ".verifyJWT: ";
    logger.silly(signature + `Verifying using token From[${token ? "args" : "decode"}]`);
    const payload = token
      ? (typeof token === "string" && this.decodeJWT(token)) || (token as IJWTPayloadDecoded)
      : this.decodeJWT();

    if (!payload) {
      logger.silly(signature + "Payload is null");
      return false;
    }

    if (payload.exp < Math.round(new Date().getTime() / 1_000)) {
      logger.silly(signature + "Payload is expired");
      return false;
    }

    return true;
  }

  public removeJWTData() {
    this.storageService.delete(StorageServiceKey.jwt);
    this.storageService.delete(StorageServiceKey.jwt_refresh);
    this.storageService.delete(StorageServiceKey.jwt_token_type);
    this.currentJwtPayload$.next(null);
  }
}
