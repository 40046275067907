import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { HasId } from "../contract/common.contract";
import { UserPhoneUpdate } from "../contract/user.contract";
import { IQueryFilter, QueryResult } from "../model/api/query-filter.model";
import { User } from "../model/user/user.model";
import { passWordUpdate } from "../service/user.service";
import { createUrl, getPublicRoutesHeaders, queryToParams } from "../util/api.util";
import { GenericApi } from "./generic.api";

// Convenience Type
type T = User;
type I = User;

@Injectable({
  providedIn: "root",
})
export class UserApi extends GenericApi<T> {
  public path = "user";
  public httpClient = inject(HttpClient);

  get = (id: number | string): Observable<T & HasId> => {
    return this.httpClient.get<T & HasId>(createUrl(this.path, id));
  };

  list = (query: IQueryFilter): Observable<QueryResult<T & HasId>> => {
    return this.httpClient.get<QueryResult<T & HasId>>(createUrl(this.path), {
      params: queryToParams(query),
    });
  };

  create = (model: Partial<T>): Observable<T & HasId> => {
    return this.httpClient.post<T & HasId>(createUrl(this.path), model);
  };

  update = (id: number | string, changes: Partial<User>): Observable<T & HasId> => {
    return this.httpClient.put<T & HasId>(createUrl(this.path, id), changes);
  };

  updatePassword = (changes: Partial<passWordUpdate>): Observable<T & HasId> => {
    return this.httpClient.post<T & HasId>(createUrl(this.path, "update-password"), changes);
  };

  updatePhoneNumber = (opts: UserPhoneUpdate): Observable<UserPhoneUpdate> => {
    return this.httpClient.post<UserPhoneUpdate>(createUrl(this.path, "update-phone"), opts);
  };
}
