import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { createUrl } from "../util/api.util";

@Injectable({
  providedIn: "root",
})
export class FileUploadApi {
  public path = "file";

  public httpClient = inject(HttpClient);

  public readonly getSignedUploadUrl = (key: string): Observable<{ url: string }> => {
    return this.httpClient.post<any>(createUrl(this.path, "signedUploadUrl"), {
      key,
    });
  };

  public readonly decodeUri = (file: any, url: string): Observable<any> => {
    const headers = new HttpHeaders({ "Content-Type": file.type + ";charset=UTF-8" });

    return this.httpClient.put(decodeURI(decodeURI(url)), file, {
      headers: headers,
      reportProgress: true,
    });
  };

  getDownloadSignedUrl(fileKey: string): Observable<{ url: string }> {
    return this.httpClient.post<any>(createUrl(this.path, "signedDownloadUrl"), {
      key: fileKey,
    });
  }
}
