import { Component, inject, input, OnDestroy, OnInit, output } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { Subscription } from "rxjs";

@Component({
  selector: "app-create-bidpool-checklist",
  standalone: true,
  imports: [ReactiveFormsModule, MatCheckboxModule, MatInputModule],
  templateUrl: "./create-bidpool-checklist.component.html",
})
export class CreateBidpoolChecklistComponent implements OnInit, OnDestroy {
  private formBuilder = inject(FormBuilder);
  private subscriptions: Subscription[] = [];

  checklistChecked = input<boolean>(false);
  checklistChange = output<boolean>();

  protected checklistForm: FormGroup;

  ngOnInit(): void {
    this.checklistForm = this.formBuilder.group({
      precond_1: this.formBuilder.control(this.checklistChecked(), [Validators.requiredTrue]),
      precond_2: this.formBuilder.control(this.checklistChecked(), [Validators.requiredTrue]),
      precond_3: this.formBuilder.control(this.checklistChecked(), [Validators.requiredTrue]),
      precond_4: this.formBuilder.control(this.checklistChecked(), [Validators.requiredTrue]),
      precond_5: this.formBuilder.control(this.checklistChecked(), [Validators.requiredTrue]),
      precond_6: this.formBuilder.control(this.checklistChecked(), [Validators.requiredTrue]),
    });
    if (this.checklistChecked()) {
      this.checklistForm.disable({ emitEvent: false });
    }

    this.subscriptions.push(
      this.checklistForm.valueChanges.subscribe({
        next: () => {
          if (this.checklistForm.valid) {
            this.checklistChange.emit(true);
          } else {
            this.checklistChange.emit(false);
          }
        },
      }),
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach((e) => e.unsubscribe());
    }
  }
}
