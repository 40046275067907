<div class="logpg">
  <app-public-header></app-public-header>

  <div class="welcome-wrap">
    <h3>Welcome!</h3>
    <p class="mb-3">Use your credentials to access</p>
    <form #form="ngForm" [formGroup]="loginForm" novalidate (submit)="onSubmit()">
      <div class="mb-3">
        <input
          class="form-control"
          [ngClass]="{
            'is-invalid':
              (form.submitted || formControls.email.touched) && formControls.email.invalid,
            'is-valid': formControls.email.valid,
          }"
          id="email"
          type="email"
          matTooltip="Enter your email"
          placeholder="Enter your email"
          formControlName="email"
          aria-label="Enter email"
        />
        <div
          *ngIf="
            formControls.email.invalid &&
            ((formControls.email.dirty && formControls.email.touched) || form.submitted)
          "
          class="invalid-feedback"
        >
          Invalid email address
        </div>
      </div>
      <div class="mb-3">
        <input
          class="form-control"
          [ngClass]="{
            'is-invalid':
              (form.submitted || formControls.password.touched) && formControls.password.invalid,
            'is-valid': formControls.password.valid,
          }"
          id="password"
          type="password"
          matTooltip="Enter your password"
          placeholder="Enter your password"
          formControlName="password"
          aria-label="Enter your password"
        />
        <div
          *ngIf="
            formControls.password.invalid &&
            ((formControls.password.dirty && formControls.password.touched) || form.submitted)
          "
          class="invalid-feedback"
        >
          Password is required
        </div>
      </div>
      <div class="mb-3 d-flex align-items-center gap-2">
        <input class="w-3 h-3" type="checkbox" formControlName="rememberMe" />Remember me for 10
        days
      </div>
      <div class="mb-3">
        <button type="submit" class="max-w-full btn-primary-new w-100">Login</button>
      </div>
      <div class="text-center">
        <span
          >Forgot your password?<a [routerLink]="'/forgot-password'" class="text-grn">
            Reset here</a
          ></span
        >
      </div>
    </form>
  </div>

  <app-public-footer></app-public-footer>
</div>
