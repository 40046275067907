<app-authenticated-layout>
  <div
    class="main-contact-header application-header d-md-flex justify-content-between align-items-center mb-3"
  >
    <h1>Bidpool applications</h1>
    <app-create-bidpool-button />
  </div>
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="In progress">
      <app-table
        tableTitle="Applications in Progress"
        [tableColumns]="inProgressTableColumns"
        [fetchDataMethod]="inProgressFetchDataMethod"
        [rowClickMethod]="handleBidpoolRowClick"
        [usePagination]="true"
      />
    </mat-tab>
    <mat-tab label="Completed">
      <app-table
        tableTitle="Completed Applications"
        [tableColumns]="completedTableColumns"
        [fetchDataMethod]="completedFetchDataMethod"
        [rowClickMethod]="handleBidpoolRowClick"
        [usePagination]="true"
      />
    </mat-tab>
    <mat-tab label="Activity log">
      <app-table
        tableTitle="Activity logs"
        [tableColumns]="actionLogTableColumns"
        [fetchDataMethod]="activityLogsFetchDataMethod"
        [usePagination]="true"
      />
    </mat-tab>
  </mat-tab-group>
</app-authenticated-layout>
