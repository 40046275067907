<div id="dash-wrap">
  <div class="sideBar">
    <nav>
      <ul class="nav flex-column text-center">
        @for (navItem of userNavItems; track navItem) {
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="navItem.route"
              routerLinkActive="active"
              [routerLinkActiveOptions]="navItem.routerLinkActiveOptions"
            >
              <mat-icon [fontIcon]="navItem.iconName"></mat-icon>
              <span>{{ navItem.label }}</span>
            </a>
          </li>
        }
      </ul>
    </nav>
  </div>
  <div class="content-wrap">
    <div class="nav-phn">
      <mat-icon
        class="phn-toggle"
        fontIcon="menu"
        data-bs-toggle="offcanvas"
        data-bs-target="#staticBackdrop"
        aria-controls="staticBackdrop"
        alt="toggle"
      />
      <div
        class="offcanvas offcanvas-start"
        data-bs-backdrop="static"
        tabindex="-1"
        id="staticBackdrop"
        aria-labelledby="staticBackdropLabel"
      >
        <div class="offcanvas-header align-self-end">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="nav flex-column text-center">
            @for (navItem of userNavItems; track navItem) {
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="navItem.route"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="navItem.routerLinkActiveOptions"
                  data-bs-dismiss="offcanvas"
                >
                  <mat-icon [fontIcon]="navItem.iconName"></mat-icon>
                  <span>{{ navItem.label }}</span>
                </a>
              </li>
            }
          </ul>
        </div>
      </div>
    </div>
    <header class="d-flex justify-content-between align-items-center">
      <div class="phn-screen" data-aos="fade-down">
        <a routerLink="/"
          ><img class="qwrap-logo" src="/assets/images/QWRAP-logo.svg" alt="QWRAP"
        /></a>
      </div>
      <div class="">
        <div
          class="welcome-top d-flex justify-content-xs-center justify-content-end align-items-center"
        >
          <div class="welcome-name">
            <p>
              <span>Welcome</span>
              @if (userService.currentUser) {
                &nbsp;<a [routerLink]="['/profile']">{{ userService.currentUser.fullName }}</a>
              }
            </p>
          </div>
          <div class="logout ms-3">
            <a routerLink="/" (click)="logout()">Log out</a>
          </div>
        </div>
        <div class="refresh-wrap d-flex justify-content-xs-center justify-content-end mt-2">
          <div class="text-side">
            <input type="text" matTooltip="Search..." placeholder="Search..." />
          </div>
          <div class="refresh-btn">
            <button type="button" class="d-flex text-white">
              <img src="../../../assets/images/searchicon.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </header>
    <div class="page-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
