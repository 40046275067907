import { Routes } from "@angular/router";
import { RolesEnum } from "./contract/user.contract";
import { authGuard } from "./guard/auth.guard";
import { roleGuard } from "./guard/role.guard";
import { tokenGuard } from "./guard/token.guard";
import { ForgotPasswordComponent } from "./view/auth/forgot-password/forgot-password.component";
import { LoginComponent } from "./view/auth/login/login.component";
import { ResetPasswordComponent } from "./view/auth/reset-password/reset-password.component";
import { TwoFactorAuthComponent } from "./view/auth/two-factor-auth/two-factor-auth.component";
import { VarifyPhoneComponent } from "./view/auth/varify-phone/varify-phone.component";
import { BidpoolComponent } from "./view/bidpool/bidpool-home/bidpool.component";
import { BidpoolSingleComponent } from "./view/bidpool/bidpool-single/bidpool-single.component";
import { CalendarComponent } from "./view/calendar/calendar.component";
import { DocumentsComponent } from "./view/documents/documents.component";
import { HomeComponent } from "./view/home/home.component";
import { ProfileComponent } from "./view/profile/profile.component";
import { RcSingleComponent } from "./view/regional-coordinator/regional-coordinator-single/rc-single.component";
import { RegionalCoordinatorComponent } from "./view/regional-coordinator/regional-coordintor-home/regional-coordinator.component";
import { UserEditComponent } from "./view/user/user-edit/user-edit.component";
import { UserListComponent } from "./view/user/user-list/user-list.component";

export const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "verify-thats-you",
    component: TwoFactorAuthComponent,
  },
  {
    path: "verify-phone",
    component: VarifyPhoneComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: { tokenScope: "resetPassword", fallbackRoute: "/login", type: "reset-password" },
    canActivate: [tokenGuard],
  },
  {
    path: "set-password",
    component: ResetPasswordComponent,
    data: { tokenScope: "resetPassword", fallbackRoute: "/login", type: "set-password" },
    canActivate: [tokenGuard],
  },
  {
    path: "",
    canActivate: [authGuard],
    children: [
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "bidpool",
        children: [
          {
            path: "",
            component: BidpoolComponent,
          },
          {
            path: ":bidpoolId",
            component: BidpoolSingleComponent,
          },
        ],
      },
      {
        path: "regional-coordinator",
        children: [
          {
            path: "",
            component: RegionalCoordinatorComponent,
          },
          {
            path: ":rcId",
            component: RcSingleComponent,
          },
        ],
      },
      {
        path: "documents",
        component: DocumentsComponent,
      },
      {
        path: "calendar",
        component: CalendarComponent,
      },
      {
        path: "profile",
        component: ProfileComponent,
      },
      {
        path: "user",
        canActivate: [roleGuard],
        data: {
          roles: [RolesEnum.Admin],
        },
        children: [
          {
            path: "",
            component: UserListComponent,
          },
          {
            path: "create",
            component: UserEditComponent,
          },
          {
            path: ":id",
            component: UserEditComponent,
          },
        ],
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/",
  },
];
