import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HasId } from "../contract/common.contract";
import { IQueryFilter, QueryResult } from "../model/api/query-filter.model";
import { UserRole } from "../model/user/user-role.model";
import { createUrl, queryToParams } from "../util/api.util";

// Convenience Type
type T = UserRole;
type I = UserRole;

@Injectable({
  providedIn: "root",
})
export class RoleApi {
  path = "role";
  public httpClient = inject(HttpClient);

  list = (query: IQueryFilter): Observable<QueryResult<T & HasId>> => {
    return this.httpClient.get<QueryResult<T & HasId>>(createUrl(this.path), {
      params: queryToParams(query),
    });
  };
}
