import { BidpoolApplication } from "../model/bidpool/bidpool-application.model";
import { BidpoolContribution } from "../model/bidpool/bidpool-contribution.model";
import { BidpoolExpectedBenefit } from "../model/bidpool/bidpool-expected-benefit.model";
import { BidpoolMilestone } from "../model/bidpool/bidpool-milestone.model";
import { BidpoolStrategicPriority } from "../model/bidpool/bidpool-strategic-priority.model";
import { BidpoolUser } from "../model/bidpool/bidpool-user.model";

export type BidpoolApplicationUpdate = Partial<
  Omit<
    BidpoolApplication,
    "users" | "strategicPriorities" | "expectedBenefits" | "milestones" | "contributions"
  >
> & {
  users?: (BidpoolUser & {
    action?: "add" | "delete";
  })[];
  strategicPriorities?: {
    id: BidpoolStrategicPriority["id"];
    value: BidpoolStrategicPriority["value"];
  }[];
  expectedBenefits?: {
    id: BidpoolExpectedBenefit["id"];
    value: BidpoolExpectedBenefit["value"];
  }[];
  milestones?: (Partial<Omit<BidpoolMilestone, "id">> & {
    id: number;
    action?: "add" | "delete";
  })[];
  contributions?: (Partial<Omit<BidpoolContribution, "id">> & {
    id: number;
    action?: "add" | "delete";
  })[];
};

export type IUpdateBidpoolApplicationWorkflowStepInput =
  | { target: "draft"; comment: string }
  | { target: "next"; type: string | undefined };
export type IUpdateBidpoolApplicationWorkflowStepOutput = { newWorkflowStepId: number };

export const BidpoolRequestedAmountRequiringAPSC = 50_000;
