import { DatePipe } from "@angular/common";
import { Component, inject, Input, ViewChild } from "@angular/core";
import { TableColumn, TableComponent } from "src/app/component/table/table.component";
import { IQueryFilter } from "src/app/model/api/query-filter.model";
import { RCFeedback } from "src/app/model/regional-coordinator/rc-feedback.model";
import { RegionalCoordinatorService } from "src/app/service/regional-coordinator.service";

@Component({
  selector: "app-regional-coordinator-feedback",
  standalone: true,
  imports: [TableComponent],
  providers: [DatePipe],
  templateUrl: "./regional-coordinator-feedback.component.html",
})
export class RegionalCoordinatorFeedbackComponent {
  @Input() rcId: number;
  @ViewChild(TableComponent) tableComponent!: TableComponent<null>;
  protected datePipe = inject(DatePipe);

  constructor(private readonly rcService: RegionalCoordinatorService) {}

  protected feedbackTableColumns: TableColumn<RCFeedback>[] = [
    {
      columnDef: "feedback",
      columnLabel: "Feedback",
    },
    {
      columnDef: "user",
      render: (element: RCFeedback) => element.user?.fullName || "",
    },
    {
      columnDef: "createdAt",
      render: (e: RCFeedback) => this.datePipe.transform(e.createdAt, "d MMMM y"),
    },
    {
      columnDef: "time",
      render: (e: RCFeedback) => this.datePipe.transform(e.createdAt, "HH: mm"),
    },
  ];

  protected feedbackFetchDataMethod = (query: Partial<IQueryFilter>) => {
    query.order = "desc";
    query.filter = { rcApplicationId: this.rcId };
    return this.rcService.fetchRcFeedbacks(new IQueryFilter(query));
  };

  public refreshTableData(): void {
    this.tableComponent.refreshTableData();
  }
}
