@if (hasAccess) {
  <div class="main-contact-add-btn d-flex justify-content-end">
    <button class="btn-regular-blue" (click)="openModal()">
      <mat-icon fontIcon="add"></mat-icon>
      Add new application
    </button>
  </div>
}

<ng-template #createBidpoolApplicationModal>
  <h2 mat-dialog-title>Checklist</h2>
  <mat-dialog-content class="mat-typography">
    <p>
      Please check all these boxes to confirm that your QWRAP region has considered all aspects of
      the project delivery before completing this form.
    </p>
    <app-create-bidpool-checklist (checklistChange)="updateIsChecklistCompleted($event)" />
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-dialog-close>Cancel</button>
    <button [disabled]="!isChecklistCompleted" [mat-dialog-close]="true">Submit</button>
  </mat-dialog-actions>
</ng-template>
